@import "../../../../../../../theme/css-variables/master-variables.css";

.equation-group-list {
    padding-top: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 10px;
    flex-grow: 1;
    font-size: 13px;

    & .equation-group-row {
        width: 100%;

        & .equation-group-name-select {
            line-height: 30px;
            min-height: 30px;
            display: flex;
            flex-flow: row nowrap;

            & .equation-group-name {
                min-width: 60%;
                flex-grow: 1;
                padding-left: 6px;
                font-weight: 500;
                cursor: pointer;
            }
            & .equation-group-select {
                min-width: 30%;
                text-align: right;
                padding-right: 15px;
            }
            & .equation-group-arrow-icon {
                height: 12px;
                width: 20px;
            }
        }
        & .equation-group-description-attributes {
            display: flex;
            flex-flow: column nowrap;
            font-size: 12px;
            margin-left: 30px;
            width: 80%;

            & .nutrient-text {
                padding-bottom: 4px;
            }

            & .grid-attribute {
                min-height: 16px;
                margin-left: 15px;
                text-align: left;
                width: 80%;
                padding-top: 4px;
            }
        }
    }
}

.rec-equation-application-summary {
    min-height: 35px;
    & .change-equation-group {
        display: flex;
        flex-flow: row nowrap;
        padding-left: 10px;
        padding-top: 10px;

        & .change-equation-group-nolink {
            margin-right: 10px;
        }
    }
}

.rec-equation-application-form {
    padding: 5px;

    & .is-included-checkbox {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        & .checkbox-div: {
            width: initial;
        }

        & .checkbox-container {
            margin-right: 10px;
        }
    }

    & .equation-application-bucket-header {
        height: 35px;
        line-height: 35px;
        margin-bottom: 5px;

        & .param-bucket-title {
            display: flex;

            & .param-events {
                font-size: 12px;
                flex-grow: 1.5;
                text-align: right;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: 5em;
                margin-right: 1em;
            }
        }
    }

    & .nutrient-product-display {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        & .nutrient-product {
            display: flex;
            flex-flow: column nowrap;
            padding-bottom: 10px;

            & .product-info-section {
                display: flex;
                flex-flow: row nowrap;

                & .product-surface-radio {
                    padding-top: 8px;
                }
                & .nutrient-name {
                    line-height: 35px;
                    min-height: 35px;
                    font-size: 15px;
                    font-weight: 700;
                    flex-grow: 1;
                    margin-left: 0;
                }
                & .nutrient-name.no-surface {
                    margin-left: 15px;
                }
                & .nutrient-rate {
                    margin-top: 11px;
                    flex-grow: 4;
                }
                & .add-default-nolink {
                    width: 30%;
                    text-align: right;
                    margin-right: 20px;
                    font-size: 12px;
                    line-height: 45px;
                }
            }
            & .product-blend-section {
                display: flex;
                flex-flow: row nowrap;
                & .product-blend {
                    width: 55%;
                    margin-left: 15px;
                    flex-grow: 1;
                }
                & .product-rec-value {
                    flex-grow: 1;
                    text-align: right;
                    margin-top: 10px;
                    margin-right: 10px;
                    min-height: 25px;
                }
            }
            & .product-blend-section {
                & .product-mix-name {
                    font-weight: bold;
                }
                & .product-mix-products {
                    margin-left: 12px;
                    margin-top: 5px;
                }
            }
            & .product-blend-section.no-blend-name {
                & .product-mix-products {
                    margin-top: 12px;
                }
            }
            & .export-checkbox {
                margin-top: 3px;

                & .checkbox-div {
                    justify-content: flex-end;
                }
            }

            & .missing-equation-attribute-warning-message {
                border-radius: 5px;
                width: 95%;
                padding: 0.5em;
                font-weight: bold;
            }
        }
    }

    & .input-row {
        & .checkbox-div {
            margin-top: 15px;
            margin-left: 3px;
            min-width: 49%;
        }
        & .select-form-input-container {
            min-width: 50%;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .equation-group-list {
            background-color: var(--base-palette-4-$(val));
        }
        & .equation-application-bucket-header {
            background-color: var(--base-palette-2-$(val));
            border-bottom: 2px solid var(--black-white-toggle-$(val));
        }
        & .nutrient-product {
            border-bottom: 1px dashed var(--white-black-toggle-$(val));

            & .missing-equation-attribute-warning-message {
                background-color: var(--warning-$(val));
            }
            & .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: var(--error-$(val));
            }
        }
    }
}
@each $val in var(--sky-array) {
    .$(val) .equation-group-list {
        background-color: var(--base-palette-6-$(val));
    }
}
