.z-to-n-header.url-header {
    & .form-section-header,
    & .form-section-children {
        margin-bottom: 5px;
    }
}
.url-item .form-input:only-child {
    margin: 0;
    width: 100%;
}
