.equipment-setup-cont {
    & .owner-operator-cont {
        display: flex;
        justify-content: space-around;
    }

    & .assigned-to-cont {
        margin-top: 15px;
        font-size: 13px;
    }
    & .accessories-group {
        display: flex;
        padding-bottom: 10px;
    }

    & .selected-owner-content {
        display: flex;
        flex-direction: column;
        padding-left: 15px;
        padding-top: 15px;

        & .italic-text {
            margin-top: 5px;
            font-family: "open_sansitalic";
            font-size: 13px;
            font-weight: 300;
        }
    }

    & .column-3-gutter {
        & > * {
            width: 116.5px;
        }
        & .select-form-input-container {
            width: 116.5px;
        }
    }

    & .column-3-gutter-dialog-box {
        display: flex;

        & .date-form-input-container {
            margin-bottom: 10px;
        }

        & .date-form-input.form-input.text-form-input {
            width: 121px;
            margin-right: 8px;
            margin-bottom: 8px;
        }

        & .form-input.text-form-input {
            width: 121px;
        }

        & .select-form-input-container {
            width: 121px;
        }
    }

    & .form-sub-section {
        & .purchase-history-date-input {
            margin-left: 20px;

            & .input-label-container {
                width: 180px;
                display: flex;
            }
        }
    }

    & .equipment-notes .form-input.textarea-form-input {
        height: 75px;
        margin-top: 0;
        flex-grow: 1;
        width: auto;

        & .input-container {
            min-height: 85%;
        }
    }

    & .accessories-dialog {
        & .dialog-box-body {
            width: 430px;

            & .accessories-group {
                display: flex;
                justify-content: space-between;

                & .select-form-input-container:only-child {
                    width: 192px;
                }
            }
        }
    }
}

.sliding-panel-header .button-container .default-button.cancel-button.equipment-cancel {
    margin-right: 3px;
}

.with-api-result {
    & .dialog-container.maintenance-event-dialog .dialog-box-body {
        width: 400px;

        & .description-cont .form-input.textarea-form-input.maintenance-event-textarea {
            margin-top: 10px;
            width: 97%;
            height: 60px;
        }
    }

    & .dialog-container.bin-tank-dialog .dialog-box-body {
        width: 400px;
    }

    & .dialog-container.fueling-event-dialog .dialog-box-body {
        & .description-cont .form-input.textarea-form-input.fueling-event-textarea {
            width: 97.5%;
            height: 60px;
        }

        & .column-3-gutter-dialog-box {
            & .date-form-input-container {
                margin-bottom: 0px;
            }
        }
    }

    & .dialog-container.usage-reading-dialog .dialog-box-body {
        & .column-3-gutter-dialog-box {
            & .form-input.textarea-form-input.usage-reading-textarea {
                margin-top: 8px;
                height: 60px;
            }

            & .date-form-input-container {
                margin-bottom: 0px;
            }
        }

        & .accessories-group {
            padding-bottom: 0px;
        }
    }
}
