.surface-layer-context-menu-batch-dialog-box {
    & .list {
        display: block;
        list-style-type: disc;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 20px;
        margin-right: 0;
    }

    & .body {
        margin-bottom: 1em;
        white-space: normal;
    }

    & .list-item:not(:last-child) {
        margin-bottom: 0.5em;
    }
}
