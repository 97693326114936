.draggable-list-cont {
    list-style: none;
    margin: auto;
    padding: 0;
    height: 250px;
    width: 350px;
    overflow: auto;

    & .draggable-list-row {
        padding: 5px;
        list-style-position: inside;
        font-size: 15px;
        display: flex;
        justify-content: space-between;

        & .default-svg-icon {
            height: 15px;
            width: 15px;
            padding: 0 5px;
            color: red;
        }

        & .draggable-text {
            flex-grow: 2;
            padding-left: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        & .svg-icon {
            flew-grow: 1;
        }

        & .draggable-icon,
        & .draggable-pencil-icon,
        & .draggable-trashcan-icon {
        }

        &.placeholder {
            height: 20px;
            width: 80%;
            border-radius: 4px;
            border: 2px dashed red;
        }

        &:before {
            color: rgb(225, 210, 90);
        }
    }
}
