@import "../../../theme/css-variables/master-variables.css";

.entity-container {
    display: flex;
    height: 21px;
    width: 521px;
    padding: 5px 0px 0px 5px;

    & span {
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    & .checkbox-div {
        width: 30px;
        margin-top: 0px;
        margin-right: 5px;
        margin-bottom: 0px;
    }
}

.location-migration-main-container {
    margin: auto;
    margin-top: 10px;
    width: 1240px;

    & .migrate-options-checkboxes {
        display: flex;
        flex: auto;
        margin-top: 16px;
        width: 50%;
    }

    & .auto-search-options {
        top: 37px;
        min-width: 196px;
        margin-left: 10px;
    }

    & .radio-button-group {
        display: flex;
        flex: auto;
        margin-top: 16px;
    }

    & .migrate-options-cont {
        margin-top: 8px;
        width: 100%;
    }

    & .migrate-button {
        margin-left: 130px;
    }

    & .migrate-radio {
        width: 250px;
    }

    & .form-section-child-stretch {
        width: 285px;
    }

    & .select-location-section {
        padding: 16px;

        & .select-location-label {
            margin-left: 10px;
        }

        & .entity-search {
            width: 216px;
            margin-top: 32px;
        }

        & .tree-view-container {
            margin-left: 10px;
            margin-top: 24px;
        }
    }

    & .tree-view-container {
        margin-top: 15px;

        & .tree-node-container {
            & .label,
            & .expand-icon {
                font-size: 13px;
                line-height: 13px;
                padding-top: 4px;
            }
        }
    }

    & .migrate-options-section {
        width: 900px;

        & .form-section {
            width: 100%;
        }

        & .migrate-options-textarea-cont {
            height: 100%;

            & .form-section-children {
                height: 100%;
            }

            & .migrate-options-textarea {
                height: 100%;

                & .form-input.textarea-form-input {
                    height: 100%;
                }
            }
        }
    }

    & .submit-options {
        width: 100%;
    }

    & .migrate-options-label {
        padding: 16px 16px 16px 0px;
    }

    & .select-company-container {
        width: 100%;
        margin-bottom: 30px;

        & .select-form-input-container {
            margin-top: 15px;
            width: 75%;
        }
    }

    & .use-only-cont {
        padding-top: 50px;
    }

    & .location-entity-container {
        & .tree-view-container {
            margin-top: 15px;

            & .tree-node-container {
                & .label,
                & .expand-icon {
                    font-size: 13px;
                    line-height: 13px;
                    padding-top: 4px;
                }
            }
        }
    }

    & .batch-zapper-entities {
        margin-top: 15px;
        margin-left: 30px;

        & span {
            font-family: "open_sansregular";
            font-size: 13px;
            font-weight: 300;
        }
    }

    & .select-form-sub-header {
        font-weight: bold;
        cursor: default;
        height: 50%;
        display: flex;
        align-items: center;
        padding-top: 5px;
        margin-left: 5px;
    }

    & .batch-zapper-attribute.select-form-input-container {
        width: 100%;
    }
}
.batch-zapper-main-container .slide-content-container {
    display: block;
}

@each $val in var(--theme-array) {
    .$(val) .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid var(--base-palette-4-$(val));
    }
}
