@import "../../theme/css-variables/master-variables.css";

.logo-row {
    & .nav-user-container {
        & .user-menu-container.nav-container {
            font-family: "open_sansregular";
            font-size: 13px;
            font-weight: 300;
            display: flex;
            flex-direction: row;
            margin-top: 35px;
            text-align: right;
            white-space: nowrap;

            & .top-navigation {
                display: inline-block;

                & .menu-items-drop-down.menu-position-bottom {
                    margin-top: 6px;
                }

                & .notification-value-count {
                    background-color: #e26816;
                    color: #ffffff;
                    margin-right: 5px;
                    padding: 1px 7px;
                }
            }

            & .top-navigation:last-child {
                border: none !important;
                padding-right: 2px;

                & .menu-items {
                    padding-right: 0;

                    & .menu-items-arrow {
                        margin-right: 0;
                    }
                }

                &.notifications .nav-menu.menu-items {
                    padding-right: 1px;
                }
            }

            & .top-navigation .link-item-text {
                padding: 0px 10px;
            }

            & .notification-link {
                padding-left: 10px;
                padding-right: 5px;
            }

            & .notification-value-count {
                padding: 2px 5px;
                font-family: "open_sansregular";
                font-size: 13px;
                font-weight: 300;
                border-radius: 5px 5px 5px 5px;
                height: 28%;
                line-height: 15px;
                margin-top: 15px;
                margin-left: auto;
            }
        }
    }
}
@each $val in var(--theme-array) {
    .$(val) {
        & .logo-row .nav-user-container .user-menu-container.nav-container .top-navigation {
            border-right: 1px solid var(--base-palette-3-$(val));
            color: var(--clickable-$(val));
        }

        & .disabled {
            cursor: default;
            & .menu-items-text,
            & .menu-items-arrow .svg-container {
                cursor: default;
                color: var(--base-palette-2-$(val)) !important;
                & svg {
                    fill: var(--base-palette-2-$(val)) !important;
                }
            }
        }
    }
}
