.with-new-api-result {
    &.dialog-container {
        &.dialog-box-body {
            max-height: 450px;
            max-width: 600px;
            height: 450px;
            width: 600px;
            user-select: auto;

            &.dialog-box-children {
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 100%;
                box-sizing: border-box;
            }
        }

        &.dialog-box-title {
            padding: 0;
            margin: 0;
        }
    }
}

.detail-block:not(:last-child) {
    margin-bottom: 2em;
}

.detail-item-list {
    display: block;
    list-style-type: disc;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
}

.detail-item:not(:last-child) {
    margin-bottom: 0.5em;
}
