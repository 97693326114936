.err-dialog {
    cursor: default;
    box-sizing: content-box;
    white-space: normal;

    & .dialog-box-body {
        user-select: text !important;
        width: 600px;
        position: relative;
        min-height: 60px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-content: stretch;
        align-items: stretch;

        & .message {
            flex: 1 1 auto;
            align-self: auto;
        }

        & .metadata {
            font-size: smaller;
            min-height: auto;
            flex: auto;
            margin-top: 1em;
            align-self: auto;
        }
    }
}
