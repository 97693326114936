@import "../../../theme/css-variables/master-variables.css";

.template-manager-cont {
    margin: 15px auto;
}
.slide-content-container .content-table-container.template-manager-cont .slide-content-container {
    display: flex;
}
.template-manager-grid {
    display: flex;
    flex-direction: column;
    min-width: 1048px;
    margin-left: 30px;

    & .select-form-input-container {
        margin: 0px;
        width: 175px;
        height: 25px;

        & .select-form-input.form-input.text-form-input {
            margin: 0px;
            height: 25px;

            & .input-label-container {
                min-height: 0px;
                line-height: 0px;
            }
        }

        & .select-form-input-options {
            margin: 0px;
        }
    }
}

.template-manager-grid-cell {
    width: 133px;
    height: 27px;
    line-height: 27px;
    overflow: hidden;
    padding-left: 5px;
    font-size: 13px;
    flex-grow: 1;
}

.template-manager-tabs {
    display: flex;
    flex-direction: column;

    & .tab-label {
        height: 40px;
        font-size: 18px;
        line-height: 45px;
        max-width: 194px;
    }

    & .tabs-labels {
        flex-basis: 60%;
        padding-left: 30px;
        max-height: 50px;
        background-clip: content-box;
    }

    & .tab-pane {
        display: flex;
        flex: 1;
    }
}

.grid-header {
    display: flex;
    justify-content: space-around;
    min-height: 21px;
    margin-top: 10px;
    margin-bottom: 5px;

    & .checkbox-div {
        width: auto;
    }
}

.grid-header-radio-group {
    display: flex;
    & .radio-div {
        height: 25px;
        width: 115px;
        font-size: 13px;
    }
}

.template-manager-grid-container {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;

    & .template-manager-grid-table {
        display: flex;
        flex-grow: 1;
        overflow-y: visible;
    }
}

.import-attribute-cont {
    display: flex;
    padding-bottom: 3px;
    & .select-form-input.form-input.text-form-input {
        border: none;
        padding: 0px;
    }
    & .counter-cont {
        width: 25px;
        text-align: right;
        line-height: 25px;
        padding-right: 3px;
    }

    & div.text-form-input .input-container input {
        font-size: 13px;
    }
}

.file-metadata-cont {
    width: 200px;
    display: flex;
    flex-direction: column;

    & .free-text {
        width: 100%;
        margin-left: 10px;
        margin-top: 10px;
        font-family: "open_sansregular";
        font-size: 13px;
        font-weight: 300;
        line-height: 1.5;

        & .checkbox-div {
            width: 100%;
        }
    }

    & button {
        margin: 10px;
        width: 105px;
    }

    & .form-input.text-form-input.delimiter-container {
        width: 88px;
    }

    & .form-input.text-form-input {
        margin: 10px 10px 0px 10px;
        width: 181px;
    }

    & .select-form-input-container {
        width: 100%;
    }

    & .select-form-input-options {
        margin-left: 10px;
    }

    & .vendor-grid-cont {
        margin-top: 10px;
        width: 80%;

        & .plain-data-table.cell-stretch {
            width: 216px;
        }
    }

    & .file-type-select {
        & .select-form-input.form-input.text-form-input {
            margin-top: 0px;
            margin-bottom: 10px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .template-manager-cont {
        & .template-manager-grid {
            & .is-header-row {
                background-color: var(--base-palette-5-$(val));
            }
            & .grid-row {
                display: flex;
                border-bottom: 1px solid var(--shadow-$(val));
            }
            & .import-attribute-cont {
                border-bottom: 1px solid var(--shadow-$(val));
            }
        }

        & .template-manager-tabs {
            & .tabs-labels {
                background-color: var(--dark-stable-$(val));
            }
        }
    }
}
