.zero-to-infinite-cont {
    width: 100%;
    display: flex;
    flex-direction: column;

    & a {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    & .child-row {
        display: flex;
        flex-direction: column;
        & a {
            text-align: right;
        }
    }

    & .add-link {
        font-family: "open_sansregular";
        font-size: 13px;
    }

    & .delete-link-container {
        padding-bottom: 10px;
        text-align: right;
    }
    & .delete-link {
        font-family: "open_sansregular";
        font-size: 13px;
        padding: 3px 0px;
    }
}

.z-to-n-header {
    & .form-section-header & .form-section-children {
        margin-bottom: 5px;
    }
}
