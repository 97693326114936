.event-module,
.layer-module,
.rec-module {
    & .accordion-item {
        user-select: none;
        cursor: pointer;
        box-sizing: border-box;
        white-space: nowrap;
    }
}
