@import "../../../../../../../theme/css-variables/master-variables.css";

.add-customer-link {
    line-height: 40px;
    height: 40px;
    min-height: 40px;
    font-size: 10pt;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    & a {
        vertical-align: top;
    }

    & .default-svg-icon {
        height: 15px;
        width: 15px;
        float: left;
        margin: 12px 12px 0 16px;
        background-repeat: no-repeat;
        cursor: pointer;
    }
}

@each $val in var(--theme-array) {
    .$(val) .add-customer-link {
        background-color: var(--base-palette-4-$(val)) !important;
        border-top: 1px solid var(--base-palette-3-$(val));
        border-left: 1px solid transparent;

        & a:focus,
        & a:active,
        & a:hover {
            color: var(--clickable-$(val));
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) .add-customer-link {
        & a {
            font-weight: bold;

            &:focus,
            &:active,
            &:hover {
                color: var(--clickable-$(val));
            }
        }

        & .default-svg-icon {
            color: var(--clickable-$(val));
        }
    }
}
