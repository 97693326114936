.nutrient-radio-group {
    display: flex;
    width: 50%;
    flex-direction: column;
}

.with-api-result .dialog-container {
    & .document-dialog-body {
        min-height: 190px;

        & .item-container {
            width: 100%;
        }

        & .form-input {
            display: block;
            margin: 10px 0;
            width: 93%;
        }

        & .form-input.textarea-form-input {
            margin: 5px 0 10px 0;
            outline: none;
        }
    }
}
