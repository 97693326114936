@import "../../../theme/css-variables/master-variables.css";

div.textarea-form-input {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    height: inherit;

    & textarea {
        resize: none;
        height: inherit;
        min-height: 7px;
        text-overflow: ellipsis;
        overflow: hidden;
        border: none;
        overflow: auto;
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        width: 100%;
        height: 100%;
    }

    & div.input-label-container {
        line-height: 7px;
        font-size: 10px;
        min-height: 10px;
        text-indent: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-width: 10%;
        max-width: 100%;
    }
    & .red-star {
        font-size: 15px;
        line-height: 10px;
        padding-right: 5px;
    }

    & div.input-container {
        display: flex;
        height: calc(100% - 14px);
    }
}

@each $val in var(--theme-array) {
    .$(val) .textarea-form-input .red-star {
        color: var(--error-$(val));
    }
}
