@import "../../../../../../../theme/css-variables/master-variables.css";
.information-detail-container {
    padding-top: 16px;
    flex: 1;
    width: 84%;

    overflow: auto;

    & .information-detail {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;

        & .detail-n-list {
            width: 100%;
            margin-top: 15px;
            height: 40px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            & .trashcan-icon-container {
                cursor: pointer;
                width: 28px;
                height: 28px;

                & .trashcan-icon {
                    width: 27px;
                    height: 27px;
                }
            }

            & .data-container {
                display: flex;
                flex-flow: column nowrap;
                padding-left: 10px;

                & .first-row {
                    font-weight: 700;
                }

                & .second-row {
                    font-size: 12px;
                    line-height: 17px;
                }
            }
        }

        & .form-input {
            margin: 4px 8px 4px 0px;
        }

        & .select-form-options {
            margin-top: -8px;
            float: left;
        }

        & .multi-select-container {
            width: 53%;
            margin-top: 5px;
            margin-bottom: 7px;

            & .form-input {
                padding-bottom: 0px;
                position: relative;
                top: -2px;
            }
        }
        & .menu-open {
            & .dropdown-menu {
                position: absolute;
                margin-top: -7px;
            }
        }

        & .detail-header {
            width: 100%;
            padding-left: 5px;
            padding-top: 10px;
        }

        & .detail-bucket {
            & .bucket-content {
                display: flex;
                flex-flow: row wrap;
                align-items: center;
            }
        }

        & .checkbox-div {
            margin-top: 2px;
            margin-right: 0;
            max-width: 46%;
            font-size: 13px;
        }

        & .high-risk-land {
            margin-top: 14px;
            margin-bottom: 4px;
            margin-left: 0px;
            width: 100%;
        }

        & .text-form-input:not(.date-form-input) {
            width: 43%;
            max-width: 43%;
        }

        & .select-form-input-container {
            width: 48%;
            max-width: 48%;

            & .select-form-input {
                width: 90%;
                max-width: 90%;
            }
        }

        & .insurance-per-crop {
            display: flex;
            flex-flow: row wrap;

            & .select-form-input-container {
                width: 32%;
                max-width: 32%;

                & .select-form-input {
                    width: 85%;
                    max-width: 85%;
                }
            }
        }

        & .date-form-input-container {
            width: 48%;
            max-width: 48%;

            & .date-form-input input {
                width: 75%;
            }
        }

        & .textarea-form-input {
            max-width: 91%;
            height: 75px;
        }

        & .general-info-bucket {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-between;

            & .text-form-input:not(.date-form-input) {
                width: 50%;
                max-width: 50%;
            }

            & .select-form-input-container {
                width: 55%;
                max-width: 55%;

                & .select-form-input {
                    width: 91%;
                    max-width: 91%;
                }
            }
            & .select-form-input-container {
                width: 55%;
                max-width: 55%;

                & .select-form-input {
                    width: 91%;
                    max-width: 91%;
                }
            }
            & .select-form-input-container {
                margin-bottom: 3px;
                margin-top: 3px;
            }
            & .select-form-input,
            & .select-form-input-options {
                margin: 0;
            }

            & .checkbox-div {
                width: 40%;
                max-width: 40%;
            }
        }

        & .listAddNoLink {
            margin-top: 5px;
            margin-right: 100px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .information-detail-container {
        background-color: var(--base-palette-4-$(val));

        & .label-error {
            color: var(--error-$(val));
        }

        & .information-detail {
            & .bucket-arrow-icon {
                fill: var(--white-black-toggle-$(val));
            }
            & .detail-n-list {
                & .trashcan-icon {
                    fill: var(--white-black-toggle-$(val));
                }
            }
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) .information-detail-container {
        background-color: var(--base-palette-6-$(val));
    }
}
