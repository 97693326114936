.analysis-layer-ec-data-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    width: 100%;
}

.analysis-layer-ec-data-input {
    width: auto;
    display: inline-block;
}
