/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url("./opensans-regular.eot");
    src: url("./opensans-regular.eot?#iefix") format("embedded-opentype"),
        url("./opensans-regular.woff2") format("woff2"),
        url("./opensans-regular.woff") format("woff"),
        url("./opensans-regular.ttf") format("truetype"),
        url("./opensans-regular.svg#open_sansregular") format("svg");
    font-weight: normal;
    font-style: normal;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url("./opensans-italic.eot");
    src: url("./opensans-italic.eot?#iefix") format("embedded-opentype"),
        url("./opensans-italic.woff2") format("woff2"), url("./opensans-italic.woff") format("woff"),
        url("./opensans-italic.ttf") format("truetype"),
        url("./opensans-italic.svg#open_sansitalic") format("svg");
    font-weight: normal;
    font-style: normal;
}
