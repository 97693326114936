.module-search {
    display: flex;
    flex-grow: 1;
    max-width: 341px;

    & .search-input,
    & .text-form-input {
        flex-grow: 1;
    }

    & .text-form-input {
        margin: 8px 10px;
        margin-right: 0;
    }

    & .search-loading-container {
        position: relative;
        min-width: 50px;
    }
}
