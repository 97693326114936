@import "../../../../../../../theme/css-variables/master-variables.css";

.scouting-template-dialog {
    flex-flow: column nowrap;
    left: 35%;
    max-width: 60em;
    min-height: 47em;
    min-width: 60em;
    position: fixed !important;
    width: 60em;

    & .dialog-box-body {
        margin-top: 0em;
    }

    & .section-container {
        margin-bottom: 0;
    }

    & .scouting-template-content {
        max-height: 70vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    & .scouting-template-top-bar {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;

        & .reset-container {
            margin-right: 1em;
            width: 50%;
            text-align: right;
            line-height: 3em;
        }

        & .allow-photo-container {
            margin-top: 1.2em;
            margin-left: 0.2em;
        }
    }

    & .bucket {
        display: flex;
        flex-flow: column nowrap;

        & .bucket-header {
            height: 35px;
            line-height: 35px;
            margin-bottom: 5px;
            display: flex;
        }

        & .bucket-content {
            max-width: 100%;
        }

        & .main-bucket-content {
            width: 100%;
        }

        & .photos-checkbox {
            padding-left: 2em;
            padding-top: 0.5em;
            margin-top: 1.2em;
        }

        & .template-input-row {
            display: flex;

            & .template-input-item {
                display: flex;
                flex-flow: row nowrap;
                margin-right: 1em;
                max-width: 32%;

                & .checkbox-div {
                    width: 2em;
                    padding-top: 1.5em;
                }

                & .template-input-numeric,
                & .template-input-text {
                    width: 160px;
                    padding-right: 11px;
                }

                & .select-form-input-container {
                    max-width: 184px;
                }
            }

            & .no-toggle {
                margin-left: 2em;
                max-width: 28%;
            }
        }
        & .child-row {
            padding-top: 0.5em;
            padding-bottom: 0.5em;
        }
        & .delete-link-container {
            padding-right: 1em;
            padding-bottom: 0;
        }

        & .add-link-container {
            padding-top: 0.5em;
        }
        & .scouting-template-observations {
            display: flex;
            flex-flow: column nowrap;

            & .template-observation-header {
                font-weight: bold;
                padding-bottom: 1em;
            }
            & .no-toggle {
                margin-left: 2em;
            }
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .scouting-template-dialog {
        & .bucket-header {
            background-color: var(--base-palette-4-$(val));
            color: var(--white-$(val));
        }
        & .child-row {
            border-top: 2px solid var(--base-palette-2-$(val));
            border-bottom: 2px solid var(--base-palette-2-$(val));
        }
    }
}
