.report-report-books-container {
    max-height: 100%;
}

.report-book-error-info-container {
    display: flex;
    flex-direction: column;

    & > :not(:first-child) {
        padding-top: 10px;
        border-top: 1px solid white;
    }

    & .error-info-container {
        display: flex;
        flex-direction: column;

        & .report-book-company-name {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 10px;
        }

        & .report-type-error-info-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;

            & .report-type-name {
                font-weight: bold;
                margin-bottom: 5px;
            }

            & .error-code-field-config-info-container {
                display: flex;
                flex-direction: column;

                & :not(:last-child) {
                    margin-bottom: 2.5px;
                }

                & .error-code {
                    margin-bottom: 5px;
                }
            }
        }
    }
}
