@import "../../../theme/css-variables/master-variables.css";

@keyframes fadein {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.loader-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: column;
    z-index: 10000;
    animation: fadein 0.5s;
    cursor: default;

    & > div {
        display: flex;
        flex: 1;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}

@each $val in var(--theme-array) {
    .$(val) .loader-container > div {
        background-color: var(--shadow-rgb5-$(val));
    }
}
