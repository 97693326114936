@import "../../../theme/css-variables/master-variables.css";

.action-panel {
    & .panel-navigation.disabled {
        & .panel-nav-tab:not(.active-nav),
        & .collapse-button {
            cursor: default;
            opacity: 0.6;
        }
    }
    & .panel-navigation {
        min-height: 50px;
        user-select: none;
        cursor: pointer;

        & .module-navigation {
            height: 44px;
            display: flex;
        }

        & .disabled {
            cursor: default;
        }

        & .panel-nav-tab {
            height: 44px;
            min-width: 18.5%;
            float: left;
            font-size: 11px;
            text-align: center;
            border: none;
            margin-top: 2px;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;
            cursor: pointer;

            & .text-and-count {
                display: flex;
                flex-direction: row;
            }

            & .tab-text {
                line-height: 16px;
                min-width: 38px;
            }

            & .tab-count {
                position: relative;
                left: 5px;
                margin-top: -5px;
                margin-bottom: 3px;
                padding: 3px 3px 0 3px;
                min-width: 7px;
                max-width: 30px;
                font-size: 11px;
                border-radius: 5px;
            }

            & .svg-container {
                margin-top: 3px;
            }
        }

        & .collapse-button {
            height: 37px;
            width: 32px;
            float: right;
            padding-top: 10px;
            text-align: center;
            cursor: pointer;
            z-index: 10;
        }
        & .collapse-button-collapsed {
            position: absolute;
            left: 5px;
        }
        & .collapse-button-expanded {
            position: initial;
        }
    }
    & .panel-navigation.tab-collapse {
        & .panel-nav-tab {
            display: none;
        }
    }
    & .disabled {
        cursor: default;
    }
}

@each $val in var(--theme-array) {
    .$(val) .action-panel {
        & .panel-navigation {
            background-color: var(--base-palette-4-$(val));

            & .panel-nav-tab {
                color: var(--white-black-toggle-$(val));

                & .tab-count {
                    background-color: var(--attention-$(val));
                    color: var(--white-$(val));
                }
            }
        }

        & .active-nav {
            color: var(--highlight-$(val));
            background-color: var(--base-palette-1-$(val)) !important;
            border-bottom: 4px solid var(--highlight-$(val));
        }

        & .panel-nav-tab:hover,
        & .collapse-button:hover {
            border-bottom: 4px solid var(--shadow-$(val));
            background-color: var(--shadow-$(val));
        }

        & .disabled {
            color: var(--base-palette-2-$(val));
        }

        & .active-nav {
            & .panel-nav-icon.active-icon {
                fill: var(--highlight-$(val));
            }

            & .tab-text {
                color: var(--highlight-$(val));
            }
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) .action-panel {
        & .panel-navigation {
            & .panel-nav-tab,
            & .collapse-button {
                & .svg-container svg {
                    fill: var(--white-$(val));
                }

                & .text-and-count .tab-text {
                    color: var(--white-$(val));
                }

                &.active-nav {
                    & .svg-container svg.panel-nav-icon.active-icon {
                        fill: var(--highlight-$(val));
                    }

                    & .text-and-count .tab-text {
                        color: var(--highlight-$(val));
                    }
                }
            }

            &:not(.disabled) .panel-nav-tab:hover,
            & .collapse-button:hover {
                border-bottom: 4px solid var(--shadow-$(val));
                background-color: var(--shadow-$(val));
                color: var(--black-$(val));

                & .svg-container svg {
                    fill: var(--black-$(val));
                }

                & .text-and-count .tab-text {
                    color: var(--black-$(val));
                }

                &.active-nav {
                    & .panel-nav-icon.active-icon {
                        fill: var(--highlight-$(val));
                    }

                    & .tab-text {
                        color: var(--highlight-$(val));
                    }
                }
            }
        }
    }
}
