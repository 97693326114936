.ec-data-event-container {
    & .ec-data-layer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 15px;
        padding-left: 10px;
        padding-right: 10px;
        width: 95%;

        & .ec-data-layer-controls {
            width: 100%;
            display: flex;

            & .ec-data-radio-btn-grp {
                flex-grow: 1;
                line-height: 40px;

                & .radio-button-container {
                    margin-top: 9px;
                }

                & .radio-label {
                    height: 40px;
                }

                & .radio-label-text-span {
                    font-size: 14px;
                }
            }
        }

        & .ec-data-layer-class-breaks {
            width: 75%;

            & .break-row {
                display: flex;
                margin-left: 30px;
                font-size: 14px;
                padding-top: 10px;
                line-height: 20px;
            }

            & .symbolBox {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }
    }
}
