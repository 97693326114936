.notifications-err-dialog {
    height: 400px;
    width: 606px;

    & .dialog-box-body {
        position: relative;
        user-select: text;

        & > div {
            position: absolute;
            top: 0;
            right: 10px;
            bottom: 0;
            left: 10px;
        }

        & .err-header {
            font-size: 14px;
            margin-bottom: 25px;
        }

        & .retry-link {
            margin-top: 20px;
        }

        & .error-detail-bucket {
            & .bucket-header {
                font-size: 13px;
            }
            & .bucket-content {
                white-space: pre;
            }
        }

        & .refresh-body {
            display: flex;
            flex-direction: column;
            height: 100%;

            & .refresh-btn-container {
                flex-grow: 1;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
            }
        }
    }
}
