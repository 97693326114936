.action-panel .event-module .event-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & .module-loader {
        width: 446px;
        height: 100%;
    }

    & .lock-icon {
        height: 35px;
        width: 45px;
        padding-left: 200px;
        padding-top: 50px;
    }

    & .lock-icon-text {
        padding-top: 20px;
        text-align: center;
    }
}
