@import "../../../../theme/css-variables/master-variables.css";

.event-module,
.rec-module,
.layer-module {
    & .rec-event-accordion-item {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 5px;

        & .spacer {
            min-width: 5px;
            max-width: 5px;
            margin: 5px 10px 5px 13px;
        }

        & .rec-event-info {
            flex-grow: 1;
            min-width: 35px;
            padding: 2px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            & .rec-event-name,
            & .rec-event-summary {
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 18px;
                white-space: nowrap;
            }
        }

        & .rec-event-status {
            margin-left: 10px;
            padding-bottom: 4px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            max-width: 30%;

            & .status {
                width: 100%;
                height: 15px;
                font-size: 12px;
                text-align: right;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.event-id {
                    font-size: 13px;
                    height: 16px;
                }
            }
        }

        & .context-menu-container {
            padding-left: 5px;
            height: calc(100% - 5px);

            & .context-menu {
                width: 15px;
                height: 100%;
                margin: 0;
                padding-right: 1px;
                padding-top: 5px;

                &:not(.expanded) .menu-dot {
                    visibility: hidden;
                }
            }
        }

        &:hover {
            & .context-menu-container .context-menu {
                & .menu-dot {
                    visibility: visible;
                }
            }
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .event-module,
    .$(val) .layer-module,
    .$(val) .rec-module {
        background-color: var(--base-palette-1-$(val));
        color: var(--white-black-toggle-$(val));
        & .rec-event-accordion-item {
            background-color: var(--base-palette-4-$(val));
        }

        & .rec-event-accordion-item,
        & .field-accordion-item {
            border-bottom: 1px solid var(--base-palette-1-$(val));
            color: var(--white-black-toggle-$(val));
            &.selected {
                background-color: var(--base-palette-5-$(val));
                & .spacer {
                    background-color: var(--highlight-$(val));
                }
            }

            & .spacer {
                background-color: var(--base-palette-3-$(val));
            }

            &:hover {
                &:not(.selected) {
                    background-color: var(--shadow-$(val));
                }

                & .context-menu-container .context-menu {
                    & .menu-dot {
                        color: var(--highlight-$(val));
                    }
                }
            }
        }
    }
}
@each $val in var(--sky-array) {
    .$(val) .event-module,
    .$(val) .layer-module,
    .$(val) .rec-module {
        & .accordion-item .rec-event-accordion-item {
            background-color: var(--base-palette-3-$(val));

            &.selected {
                background-color: var(--base-palette-5-$(val));
                color: var(--white-$(val));
            }
        }
    }
}
