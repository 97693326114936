@import "../../../theme/css-variables/master-variables.css";

.active-inactive-header-container {
    display: flex;
    justify-content: flex-end;
    min-height: 18px;
    font-size: 13px;
    font-weight: 300;
    padding-top: 20px;
    align-items: center;
}
.filter-header-container {
    & .org-level-header-cont .active-inactive-container {
        width: initial;
    }

    & .active-inactive-header-container {
        padding-left: 5px;
        width: initial;

        &.move-location-container {
            margin-top: 20px;
            padding: 0 5px 0 0;
        }
    }
}

.hierarchy-filter-container {
    width: 620px;
    margin-top: 10px;
    & .form-sub-section .select-form-input-container:only-child {
        width: 250px;
    }
}

.employee-info-end-date.date-form-input.form-input.text-form-input {
    margin-left: 7px;
}

.user-container {
    & .select-form-input-options {
        & .select-form-input-option {
            padding: 0px 5px;
            display: flex;
            flex-flow: column wrap;

            & .post-primary-label {
                flex-grow: 0;
                flex-basis: 50%;
                align-self: flex-start;
                width: 50%;
                margin-top: 1px;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            & .second-line {
                padding-left: 0px;
                width: 50%;
            }

            & .first-line {
                padding-left: 0px;
                width: 50%;

                & .pre-match {
                    margin-left: 0px;
                }
            }
        }
    }
    & .form-input {
        width: 300px;
        margin: 0px;
    }
}

.person-search-no-option {
    height: 100%;
    text-align: left;
    padding-top: 15px;
}

.person-name {
    width: 50%;
    float: left;
    margin-bottom: 5px;
}
.person-jobtitle {
    width: 50%;
    float: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
}

.person-state {
    font-weight: 300;
    font-size: 10px;
    font-style: italic;
}

.salesperson-textbox.form-input {
    width: 250px;
}

.form-sub-section {
    & .person-filter {
        width: 100%;
        & .search-icon {
            width: 13px;
            height: 13px;
            padding-top: 4px;
        }
    }
    & .person-filter.customer-name-section.select-form-input-container {
        width: 100%;
    }
    & .user-supervisor-search {
        width: 185px;
    }
    & .auto-search-list-container.additional-agvance-customer {
        width: 100%;
    }
}
.auto-search-customer-affiliation {
    padding: 10px 0px;
    & .select-form-input.form-input.text-form-input {
        margin: 0px;
    }
}
.search-person-options {
    padding-left: 18px;

    & .person-info {
        display: flex;
        & .person-jobtitle {
            width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        & .person-name {
            width: 50%;
        }

        & .person-state {
            font-weight: 300;
            font-size: 10px;
        }
    }
}

.user-role-matrix {
    margin-top: 30px;
    width: 1240px;
    margin: 30px auto 0;
    font-family: "open_sansregular";
    font-size: 13px;
    display: flex;

    & .matrix-grid {
        flex: 1;
    }

    & .column-header-label {
        width: 115px;
        height: 90px;
        vertical-align: bottom;
        line-height: 10;
        font-weight: bold;
    }

    & .column-header {
        overflow: visible;
        height: 90px;
        transform: rotate(-65deg);
        width: 35px;
        vertical-align: bottom;
        line-height: 15;
        font-weight: bold;
    }

    & .grid-body {
        overflow: auto;
        margin-bottom: 30px;

        & .column-sub-header {
            height: 20px;
            font-weight: bold;
            display: flex;
            align-items: center;
        }

        & .row-data {
            width: 32px;
            height: 20px;
            line-height: 1.7;
            text-align: center;
        }

        & .row-no-data {
            width: 32px;
            height: 20px;
            text-align: center;
            line-height: 1.7;
        }

        & .column-label {
            width: 150px;
            height: 20px;
            line-height: 1.7;
            padding-left: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            float: left;
        }
    }
}

.customer-affiliation-table {
    & .plain-table-body .data-table-cell {
        width: 25%;
    }

    & .data-table-header .data-header-cell {
        width: 25%;
    }
}

.additional-agvance-customer {
    & .data-header-cell.agvance-grower-name {
        max-width: 200px;
        flex-grow: 2;
    }
    & .data-table-cell.agvance-grower-name {
        max-width: 140px;
        flex-grow: 2;
    }
}

.agvance-integration {
    & .form-section-header {
        margin-bottom: 0px;
    }
    & .form-sub-section {
        margin: 10px 0px 0px 0px;
    }
}

.person-affliliation {
    & .data-header-cell.person-name {
        max-width: 110px;
        flex-grow: 2;
        margin-bottom: 0px;
    }

    & .data-table-cell.person-city {
        max-width: 70px;
    }

    & .data-table-cell.person-name {
        margin-bottom: 0px;
    }
}

.additional-agvance-customer-grid,
.form-section-child-stretch {
    & .data-table-row .data-header-cell.agvance-name {
        max-width: 180px;
    }
    & .data-table-row .agvance-name {
        flex-grow: 2;
        max-width: 135px;
    }
    & .data-table-row .agvance-location {
        max-width: 145px;
        padding-left: 0px;
    }
    & .data-table-row .agvance-checkbox {
        width: 50px;
        padding-left: 0px;
        padding-top: 2px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .filter-header-container .active-inactive-header-container.move-location-container {
            border-right: 1px solid var(--base-palette-3-$(val));
        }

        & .user-role-matrix {
            & .column-sub-header {
                background-color: var(--shadow-$(val));
            }

            & .grid-row {
                border: 1px solid var(--shadow-$(val));
            }

            & .column-label {
                border-right: 1px solid var(--shadow-$(val));
                border-left: 1px solid var(--shadow-$(val));
            }

            & .row-data {
                border-right: 1px solid var(--shadow-$(val));
                border-left: 1px solid var(--shadow-$(val));
            }

            & .row-no-data {
                border-right: 1px solid var(--shadow-$(val));
                border-left: 1px solid var(--shadow-$(val));
            }

            & .grid-body {
                border-bottom: 2px solid var(--shadow-$(val));
                border-top: 2px solid var(--shadow-$(val));
            }
        }

        & .person-filter .search-icon {
            fill: var(--black-$(val));
        }
    }
}
