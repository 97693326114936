.setup-season-filter {
    & .season-filter-grid {
        & .data-table-header {
            height: 20px;
            padding-right: 16px;
        }

        & .data-header-cell {
            max-width: none;
            width: 10%;
            line-height: normal !important;

            & .data-table-cell {
                text-transform: capitalize;
            }
        }

        & .data-table-cell {
            max-width: none;
            &.target-type {
                padding-left: 19px;
                width: calc(40% - 19px);
            }

            &.filter-type {
                padding-left: 12px;
                width: calc(40% - 12px);
            }
        }

        & .plain-table-body {
            margin-bottom: 0;
        }

        & .target-type {
            width: 40%;
        }

        & .filter-type {
            width: 40%;
        }
    }

    & .add-link-container {
        font-size: 13px;
        margin-top: 6px;

        &.no-table {
            margin-top: 10px;
        }
    }
}
