.event-filter-form {
    display: flex;
    flex-direction: column;
    & .expand-icon {
        display: none;
    }

    & .bucket-header {
        font-size: 13px;
    }

    & .label-title {
        height: 20px;
        font-size: 15px;
        font-weight: 300;
        cursor: pointer;
        user-select: none;
        margin-left: 10px;
    }

    & .checkbox-child {
        display: flex;
        align-items: center;
    }

    & .checkbox-parent {
        display: flex;
        align-items: flex-end;
        margin-bottom: 5px;
    }

    & .filter-form-table {
        width: 90%;
        line-height: 25px;
        text-align: right;

        & th,
        & td {
            width: 15%;
        }

        & th.label-col,
        & td.label-col {
            width: 45%;
            padding-left: 25px;
            text-align: left;
        }
    }

    & .tree-view-container .node-child-container {
        margin-left: 25px;
    }

    & .filter-tree-view {
        width: 90%;
        position: relative;
        left: 5px;
    }

    & .filter-row {
        display: flex;
        width: 90%;
        justify-content: space-between;
        & title {
            text-transform: capitalize;
        }
        align-items: center;
    }
}
