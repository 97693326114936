.map-container {
    flex-grow: 1;
    background-color: transparent;

    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;

    &.gray-vector-basemap {
        background-color: #eee;
    }

    &.topo-vector-basemap {
        background-color: #fff;
    }
}
