@import "../../../../../../../theme/css-variables/master-variables.css";

.event-application-field-data {
    & .input-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        & > * {
            flex-grow: 1;
            width: 20px;
        }
    }
}

.event-application-form {
    padding: 5px;

    & .add-mix {
        text-align: right;
        padding-top: 15px;
        padding-right: 15px;
        top: 0px;
    }

    & .product-blend-list {
        display: flex;
        flex-flow: row wrap;
        padding: 10px;

        & .product-blend-row {
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px dashed;

            & .mix-information {
                display: flex;
                flex-flow: row wrap;
                min-width: 60%;
                justify-content: flex-start;

                & .mix-title {
                    font-size: 15px;
                    max-width: 200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-weight: 700;
                    width: 100%;
                    padding-bottom: 4px;
                }

                & .mix-product-list {
                    display: flex;
                    flex-flow: column nowrap;
                    padding-top: 10px;
                    & .product-row {
                        padding-left: 20px;
                        padding-bottom: 10px;
                    }
                }
            }

            & .rate-information {
                display: flex;
                min-width: 120px;
                justify-content: flex-end;
                line-height: 40px;

                & .target-rate {
                    padding-right: 3px;
                    min-width: 40px;
                }
                & .target-rate.is-locked {
                    padding-right: 3px;
                    min-width: 70px;
                    & .form-input {
                        height: 25px;
                        & input {
                            text-align: center;
                        }
                    }
                }
                & .target-rate-unit {
                    min-width: 40px;
                }
            }
        }
    }
}
@each $val in var(--theme-array) {
    .$(val) {
        & .event-application-field-data {
            background-color: var(--base-palette-4-$(val));
        }
    }
}
