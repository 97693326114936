@import "../../../../../theme/css-variables/master-variables.css";

.drag-and-drop-file-uploader-container {
    flex: auto;
    height: 95%;
    display: flex;
    & .drop-message {
        flex-grow: 1;
        display: flex;
        height: 100%;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border-style: dashed;
        border-width: 2px;

        & .default-svg-icon {
            height: 100px;
            width: 100px;
        }

        & .greyed-text,
        & a {
            font-family: open_sansregular;
            font-size: 17px;
            font-weight: 300;
            padding: 3px;
        }
    }
    .add-default-nolink {
        margin-left: 20px;
    }
    .default-button {
        margin-left: auto;
    }
    .product-info-section {
        justify-content: space-between;
    }
}

@each $val in var(--theme-array) {
    .$(val) .drag-and-drop-file-uploader-container {
        & .drop-message {
            border-color: var(--base-palette-3-$(val));

            & .greyed-text {
                color: var(--base-palette-2-$(val));
            }

            & .default-svg-icon {
                & #box {
                    fill: var(--base-palette-3-$(val));
                }
                & #arrow {
                    fill: var(--highlight-$(val));
                }
            }
        }
    }
}
