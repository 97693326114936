@import "../../../../../../../../theme/css-variables/master-variables.css";

.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .tissue-sample-results-tbl-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: calc(100% - 50px);
    position: relative;
    & .sample-results-tbl {
        overflow: auto;
        position: relative;

        & .select-form-input-container {
            margin: 0;
            width: 70px;

            & .input-container {
                margin-top: 5px;
            }

            & .form-input {
                border-radius: 0;
                height: 30px;
                margin: 0;
                min-width: 70px;
                padding: 0;

                .default-svg-icon {
                    height: 10px;
                    width: 10px;
                }

                & .expand-contract-arrow,
                & .clear-btn-icon {
                    height: 10px;
                    padding: 0;
                    width: 10px;
                }
                & input {
                    font-size: 11px;
                    height: 16px;
                    min-width: 45px;
                    text-align: center;
                    text-indent: 0;
                }
                & svg {
                    height: 10px;
                    margin: 0;
                    padding: 0;
                    width: 10px;
                }
                & .input-label-container {
                    width: 40px;
                }
            }
            & .select-form-input-options {
                margin-top: 0;
                margin-bottom: 0;
                text-align: left;
            }
        }
    }

    & .measure {
        display: inline-block;
        min-width: 100px;
        max-height: 100px;
        visibility: hidden;
    }

    & .attribute-name {
        font-weight: 400;
        visibility: hidden;
        z-index: 2;
        &.sticky {
            position: absolute;
            visibility: visible;
        }
    }

    & .header-cell {
        height: 24px;
        padding: 10px 5px 0 5px;

        &.sticky-col,
        &.sticky {
            position: absolute;
            z-index: 10;
        }
    }

    & .body-cell {
        height: 25px;
        padding: 5px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.prod-rating {
        min-width: 70px;
        overflow: visible;
    }

    & .attribute-val-cols {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
        margin: 3px;
    }

    & .row {
        display: flex;
        flex-direction: row;
        text-align: center;

        & > div:not(.attribute-name) {
            flex-grow: 1;
            min-width: 70px;
        }

        &.sticky {
            position: absolute;
            left: 0;
            right: 0;
            z-index: 10;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .sample-results-tbl {
        background-color: var(--base-palette-1-$(val));
        & .header-cell {
            background-color: var(--base-palette-1-$(val));
            border-bottom: 1px solid var(--base-palette-2-$(val));
        }
        & .sticky-col {
            background-color: var(--shadow-$(val));
        }
        & .body-cell {
            border-bottom: 1px solid var(--base-palette-2-$(val));
            &.selected {
                background-color: var(--highlight-$(val));
            }
        }
        & .attribute-name {
            background-color: var(--shadow-$(val));
            border-bottom: 1px solid var(--base-palette-2-$(val));
        }
    }
}
