@import "https://js.arcgis.com/4.22/@arcgis/core/assets/esri/themes/light/main.css";

/** available themes:
@import "https://js.arcgis.com/4.22/@arcgis/core/assets/esri/themes/light/main.css";
@import "https://js.arcgis.com/4.22/@arcgis/core/assets/esri/themes/dark/main.css";
*/

@import "../../theme/css-variables/master-variables.css";

.theme-root {
    font-family: "open_sansregular";
    font-size: 13px;
    font-weight: 300;
}

@each $val in var(--theme-array) {
    .$(val) {
        color: var(--white-$(val));
        background-color: var(--base-palette-1-$(val));

        & a:link,
        & a:visited {
            color: var(--clickable-$(val));
        }

        & a:hover,
        & a:active,
        & a:focus {
            color: var(--highlight-$(val));
        }
    }
}
