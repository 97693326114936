.report-book-options-table {
    margin-top: 10px;
    display: flex;

    & .dialog-window {
        & .report-book-options-error-container {
            display: flex;
            flex-direction: column;

            & .report-book-options-error-header {
                font-size: 16px;
                margin-bottom: 10px;

                & .config-name {
                    font-weight: bold;
                }
            }

            & p {
                margin-bottom: 5px;
            }
        }
    }
}
