@import "../../../../theme/css-variables/master-variables.css";

.map-div {
    position: relative;
    user-select: none;
    width: 100%;
    height: 100%;
}
.esri-view-root {
    & .esri-view-surface--inset-outline:focus::after {
        outline: none !important;
    }
}

.zoom-container {
    position: absolute;
    left: auto;
    top: 5px;
    right: 5px;
    z-index: 10;
    border-radius: 5px;
    cursor: pointer;

    & > div {
        height: 38px;
        width: 24px;
        margin: 0 5px;
        background-position: center;
        background-repeat: no-repeat;
    }

    & .svg-container {
        height: 100%;

        & svg {
            margin: 7px 0;
        }
    }
}

@keyframes slim {
    0% {
        width: 50px;
    }

    to {
        width: 0px;
    }
}

@keyframes widen {
    0% {
        width: 0px;
    }

    to {
        width: 50px;
    }
}

.basemap-select-container {
    position: absolute;
    border-radius: 5px;
    padding: 5px;
    right: 5px;
    bottom: 25px;
    z-index: 10;
    cursor: pointer;

    & .basemap-thumbnail-enter {
        animation: widen 250ms ease-in;
    }

    & .basemap-thumbnail-leave {
        animation: slim 250ms ease-in;
    }

    & img {
        height: 50px;
        width: 50px;
        border-radius: 5px;
        float: left;
        margin-left: 2px;
        object-fit: cover;
        object-position: center;
    }

    & img:first-child {
        margin-left: 0;
    }
}

svg text {
    pointer-events: none;
}

.esri-view .esri-view-surface[data-cursor="move"],
.esri-view .esri-view-surface[data-cursor="copy"] {
    cursor: pointer;
}

@each $val in var(--theme-array) {
    .$(val) {
        & .zoom-container {
            background-color: var(--base-palette-1-$(val));

            & .zoom-plus {
                border-bottom: var(--base-palette-5-$(val)) solid 1px;
            }
        }

        & .basemap-select-container {
            background-color: var(--base-palette-5-$(val));

            & img {
                border: 2px solid var(--shadow-$(val));
            }
        }
    }
}
