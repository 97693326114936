@import "../../../../../theme/css-variables/master-variables.css";

.buffer-setup {
    display: none;
}
.event-module + .buffer-setup,
.rec-module + .buffer-setup,
.layer-module + .buffer-setup {
    &.buffer-setup-open {
        display: flex;
    }
}
.dialog-container.buffer-setup-modal {
    width: 385px;
    height: 365px;
    line-height: 17px;

    & .dialog-box-body {
        overflow: visible;
        margin: 0;
        padding: 0;
        & .dialog-box-children > div {
            margin: 10px 20px;
        }
        & .buffer-source {
            display: flex;
            align-items: center;

            & .radio-grp-lbl {
                flex-grow: 1;
                text-align: center;
                margin-bottom: 3px;
            }
            & .radio-grp-source {
                flex-grow: 1;
                font-size: 14px;
                line-height: 19px;

                & .radio-div {
                    margin: 3px;
                }
            }
        }

        & .buffer-dimensions {
            & .buffer-input-forms {
                display: flex;
                margin: 0 20px;
                flex-direction: row;
                & .select-form-input-container {
                    margin: 0;
                }
                & .form-input {
                    margin: 5px;
                }
                & > * {
                    flex-grow: 1;
                    min-width: 10px;
                }
            }
        }

        & .buffer-instructions {
            padding: 10px;
            font-style: italic;
        }
    }
}
