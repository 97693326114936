@import "../../theme/css-variables/master-variables.css";

.notification-msg {
    & .toastify {
        width: 350px;

        &.toastify--bottom-right {
            bottom: 1em;
            right: 2em;
        }

        & .toastify-content {
            border-radius: 5px;
            border-left-width: 6px;
            border-left-style: solid;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 10px 0px, rgba(0, 0, 0, 0.05) 0px 2px 15px 0px;
            margin-bottom: 12px;
            min-height: 62px;
            padding: 8px;
            position: relative;
            width: 100%;

            & .toastify__body {
                font-family: open_sansregular;
                font-size: 13px;
                font-weight: 300;
                line-height: 20px;
            }

            & .toastify__close {
                background: transparent;
                border-color: initial;
                border-image: initial;
                border-style: none;
                border-width: initial;
                border: none;
                color: inherit;
                cursor: pointer;
                font-size: 14px;
                font-weight: 700;
                height: 16px;
                opacity: 1;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 2px;
                text-align: center;
                text-decoration: none;
                top: 2px;
                transition: 0.3s ease;
                width: 16px;
                z-index: 100;

                & svg {
                    width: 16px;
                    height: 16px;
                }
            }

            & .toastify__progress {
                opacity: 1;
            }
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .notification-msg .toastify {
        & .toastify-content {
            color: var(--white-black-toggle-$(val));
            background-color: var(--base-palette-1-rgba-$(val));

            &:hover {
                background-color: var(--base-palette-1-$(val));
            }

            & .toastify__progress {
                background-color: var(--base-palette-4-$(val));
                opacity: 1;
            }
        }

        & .toastify-content--info {
            border-left-color: var(--clickable-$(val));
        }

        & .toastify-content--error {
            border-left-color: var(--error-$(val));
        }

        & .toastify-content--success {
            border-left-color: var(--highlight-$(val));
        }

        & .toastify-content--warning {
            border-left-color: var(--warning-$(val));
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) .notification-msg .toastify {
        & .toastify-content {
            color: var(--black-$(val));
            background-color: var(--base-palette-6-$(val));

            &:hover {
                background-color: var(--base-palette-6-$(val));
            }
        }
    }
}
