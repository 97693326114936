@import "../../../../../../../../theme/css-variables/master-variables.css";

.event-sample-tissue-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(100% - 60px);
    overflow: hidden;

    & .bucket {
        min-height: unset;
    }

    & .method-selection {
        margin: 0 9px 9px 9px;

        &.tabs {
            & .tabs-labels li.tab-label:not(.left-non-label):not(.right-non-label) {
                padding-top: 12px;
            }

            & .tabs-pane {
                padding: 8px;

                & .manual-method-steps,
                & .grid-method-steps {
                    display: flex;
                    flex-direction: row-reverse;
                }
            }
        }
    }

    & .readonly-row {
        width: 95%;
        margin-left: 1em;
        padding-bottom: 1em;
        flex-direction: column;

        & .readonly-label {
            padding-top: 1em;
            font-weight: bold;
        }

        & .readonly-value {
            padding-left: 2em;
            padding-top: 0.5em;
        }
    }

    & .input-row {
        & .select-form-input-container {
            max-width: 50%;
        }
    }

    & .sample-points {
        padding: 5px 10px 0px 10px;

        & .table-controls {
            display: flex;
            flex-direction: row;
            min-height: 20px;
            max-height: 20px;
            justify-content: space-between;
            line-height: 20px;
            padding-bottom: 20px;

            & .checkbox-div {
                width: initial;
            }

            & .btn-place-points {
                width: 100px;
            }
        }
    }

    & .crop-selected-results {
        height: calc(100% - 145px);
    }

    & .alt-results {
        height: calc(100% - 195px);
    }

    & .no-crop-selected-results {
        height: calc(100% - 90px);
    }

    & .alt-no-results {
        height: calc(100% - 205px);
    }

    & .crop-selected-no-results {
        height: calc(100% - 165px);
    }

    & .no-crop-selected-no-results {
        height: calc(100% - 110px);
    }
}

@each $val in var(--theme-array) {
    .$(val) .rec-event-info {
        & .event-sample-tissue-form {
            & .tabs .tabs-labels {
                background-color: var(--base-palette-4-$(val));

                & li.tab-label {
                    background-color: var(--base-palette-2-$(val));
                    &:not(.active) {
                        color: var(--white-black-toggle-$(val));
                    }
                    &.active {
                        background-color: var(--base-palette-1-$(val));
                    }
                }
            }

            & .method-selection .tabs-pane {
                background-color: var(--base-palette-1-$(val));
            }
        }
    }
}
