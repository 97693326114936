.attribute-edit-modal {
    & .dialog-box-body {
        padding: 0;
    }
    & .edit-modal-row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;

        & .select-form-input-container {
            margin: 0px;
            & .select-form-input {
                width: unset;
                margin: 5px;
            }
        }
        & .auto-search {
            width: 97%;
        }
    }
}
.crop-edit-modal {
    min-width: 450px;
    width: 450px;
    min-height: 253px;
    max-height: 350px;
    overflow-y: auto;
    & .cbv-inputs {
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        & .select-form-input-container {
            margin: 0px;
            width: 49%;
            & .select-form-input {
                width: unset;
                margin: 5px;
            }
        }
        & .text-form-input:not(.select-form-input) {
            width: 45%;
            margin: 4px;
        }
    }
}
.layer-edit-modal {
    & .edit-modal-row {
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        & .text-form-input:not(.select-form-input):not(.date-form-input) {
            min-width: 93%;
        }
        & .select-form-input-container {
            top: 5px;
            left: 5px;
            & .select-form-input-options {
                margin-left: 5px;
            }
        }
    }
}
