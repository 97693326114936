@import "../../../../theme/css-variables/master-variables.css";

.dijitReset {
    margin: 0;
    border: 0;
    padding: 0;
    font: inherit;
    line-height: normal;
    color: inherit;
}

.dijitPopup {
    position: absolute;
    background-color: transparent;
    margin: 0;
    border: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
}

.dijitMenuTable {
    border-collapse: separate;
    border-spacing: 0 0;
    padding: 0;
    border-width: 0;
    background-color: transparent;
}

.dijitMenu {
    border-radius: 5px;

    & .dijitMenuItem td {
        padding: 0 0 0 5px;
        line-height: 24px;
        border-width: 1px 0 1px 0;
        border-style: solid;
    }
    & .dijitMenuItem td:first-child {
        border-radius: 4px 0 0 4px;
        border-width: 1px 0 1px 1px;
    }
    & .dijitMenuItem td:last-child {
        border-radius: 0 4px 4px 0;
        border-width: 1px 1px 1px 0;
        max-width: 5px;
    }
}

.dijitMenuItem {
    border-radius: 5px;
    text-align: left;
    white-space: nowrap;
    padding: 0.1em 0.2em;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

td.dijitMenuItemIconCell {
    padding: 2px;
    margin: 0 0 0 4px;
}

.dijitInline {
    display: inline-block;
    border: 0;
    padding: 0;
    vertical-align: top;
}

.dijitMenuItemLabel {
    font-family: "open_sansregular" !important;
    font-size: 12px;
    vertical-align: middle;
}

@each $val in var(--theme-array) {
    .$(val) .dijitMenuItem {
        background-color: var(--base-palette-1-$(val));
        color: var(--clickable-$(val));

        & td {
            border-color: var(--base-palette-4-$(val));
        }

        &.dijitMenuItemHover {
            background-color: var(--base-palette-4-$(val));
            color: var(--highlight-$(val));

            & td {
                border-color: var(--base-palette-1-$(val));
            }
        }
    }
}
