@media screen and (max-width: 1240px) {
    .slide-content-container {
        & .person-setup-cont,
        & .user-setup-cont,
        & .vendor-setup-cont,
        & .owner-setup-cont,
        & .org-level-details,
        & .system-settings-cont {
            & .person-section {
                margin-left: 0;
            }

            & .user-section {
                & .user-request-password {
                    margin: 10px 0 5px 0;
                }

                & .select-form-input-container {
                    width: 50%;
                }
            }

            & .only-child-responsive,
            & .policy-section .form-input.privacy-policy-title.only-child-responsive {
                width: 50%;
            }

            & .add-link-container,
            & .add-edit-levels-link {
                margin: 10px 0;
            }

            & .form-section {
                &.phone-header,
                &.email-header,
                &.url-header {
                    & .form-section-header {
                        margin-bottom: 0;
                    }

                    & .form-section-children {
                        margin-bottom: 0;
                    }

                    & .form-sub-section.url-item,
                    & .form-sub-section .email-container {
                        width: 97%;
                    }
                }

                & .copyright-text.form-input.text-form-input {
                    width: 97%;
                }
            }

            & .date-form-input.form-input.text-form-input {
                width: 50%;

                &.employee-info-end-date {
                    margin-left: 0;
                }

                &.privacy-last-updated {
                    width: 100%;
                }
            }

            & .person-filter.user-supervisor-search.select-form-input-container {
                width: 50%;
                margin-bottom: 10px;
            }

            & .checkbox-div,
            & .checkbox-div.is-primary-container {
                margin-left: 0px;
                margin-top: 12px;
                justify-content: flex-start;
            }

            & .checkbox-div.user-is-active {
                margin-top: 12px;
                margin-bottom: 0;
            }

            & .time-zone-select {
                margin-bottom: 5px;
            }
        }

        & .section-container .form-section .form-sub-section.copyright-policy-sub-section {
            margin-bottom: 10px;
        }

        & .owner-setup-cont {
            & .form-section-children {
                margin-bottom: 10px;
            }
        }
    }
}

@media screen and (max-width: 440px) {
    .slide-content-container {
        & .person-setup-cont & .user-setup-cont,
        & .vendor-setup-cont,
        & .owner-setup-cont,
        & .org-level-details,
        & .system-settings-cont {
            &.form-section {
                width: auto;
            }
        }
    }
}
