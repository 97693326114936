@import "../../../../../../theme/css-variables/master-variables.css";

.import-module {
    & .import-template-accordion-item {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & .template-name {
            margin-left: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-style: italic;
        }

        & .loader-container {
            z-index: inherit;

            & .line-scale-pulse-out-rapid {
                max-height: 25px;
                & > div {
                    max-height: 20px;
                }
            }
        }

        & .context-menu-container {
            margin-right: 5px;
            padding-top: 2px;
            height: 100%;

            & .context-menu {
                height: calc(100% - 4px);
                width: 15px;
                margin: 0;
                line-height: inherit;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                &:not(.expanded) .menu-dot {
                    visibility: hidden;
                }

                & .menu-dot {
                    line-height: 6px;
                    margin-bottom: -2px;
                }
            }
        }

        &:hover {
            & .context-menu-container .context-menu .menu-dot {
                visibility: visible;
            }
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .import-module {
        & .import-template-accordion-item {
            background-color: var(--shadow-$(val));
            border-bottom: 1px solid var(--base-palette-1-$(val));

            &:hover {
                & .context-menu-container .context-menu .menu-dot {
                    color: var(--highlight-$(val));
                }
            }
        }
    }
}
