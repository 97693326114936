@import "../../../../theme/css-variables/master-variables.css";

.rec-event-info-form {
    & .event-zone-info {
        display: flex;
        align-items: center;
        padding: 15px 20px 10px 5px;

        & div {
            vertical-align: middle;
        }

        & .no-zone-msg {
            font-size: 14px;
            padding-left: 15px;
        }

        & .zone-info-heading {
            flex-grow: 1;
            display: flex;
            align-items: center;
            height: 24px;

            & .default-svg-icon {
                height: 15px;
                width: 15px;
                margin-left: 5px;
                margin-right: 12px;
            }

            & .zone-name {
                font-size: 14px;
                font-weight: 700;
            }

            & .zone-size {
                padding-left: 5px;
                font-size: 14px;
                font-weight: 300;
            }
        }

        & .copy-btn,
        & .clear-btn {
            flex-grow: 0;
            cursor: pointer;
            & .default-svg-icon {
                height: 22px;
                width: 22px;
                margin-left: 12px;
                margin-right: 4px;
            }
        }

        & .copy-equation-btn {
            margin-right: 80px;
        }

        & .dialog-window {
            position: absolute !important;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        & .copy-zone-modal {
            position: fixed !important;
            width: 385px;
            line-height: 17px;

            & .copy-zone-msg {
                margin-bottom: 10px;
            }
        }

        & .modal-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    & .zone-legend-heading {
        margin-left: 10px;
        margin-bottom: 3px;
        font-size: 14px;
        font-weight: 300;
    }

    & .event-zone-classbreaks-legend {
        display: flex;
        align-items: center;
        padding: 2px 10px 2px 2px;
        margin: 0 8px;
        cursor: pointer;

        & .event-zone-legend-color {
            flex-shrink: 0;
            height: 20px;
            width: 20px;
            margin: 0 7px;
            padding: 2px;
        }

        & .event-zone-legend-label {
            display: flex;
            min-width: 10px;
            line-height: 20px;

            & span {
                min-width: 10px;
                white-space: nowrap;

                &.label-size {
                    flex-shrink: 0;
                    margin-left: 5px;
                }

                &.label-text {
                    flex-shrink: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .rec-event-info-form {
        & .event-zone-info {
            & .no-zone-msg {
                color: var(--base-palette-3-$(val));
            }
            & .copy-btn,
            & .clear-btn {
                color: var(--clickable-$(val));
                & .default-svg-icon {
                    fill: var(--clickable-$(val));
                }
            }
        }
        & .event-zone-classbreaks-legend {
            &.event-zone-legend-active {
                background-color: var(--shadow-$(val));
            }
            &:hover {
                background-color: var(--base-palette-5-$(val));
            }
        }
    }
}
