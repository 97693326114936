@import "../../../../../../../theme/css-variables/master-variables.css";

.import-wizard-footer {
    display: flex;
    justify-content: center;
    padding-top: 2px;
    min-height: 50px;

    & button {
        margin: 7px;
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .import-wizard-footer {
            background-color: var(--base-palette-1-$(val));
            border-top: 1px solid var(--base-palette-3-$(val));
        }
    }
}
