@import "../../../theme/css-variables/master-variables.css";

.quill {
    border-radius: 0px 0px 4px 4px;
    font-family: "open_sansregular";
    & .ql-container {
        height: 300px;
        & strong {
            font-weight: bold;
        }
        & em {
            font-style: italic;
        }
    }
}

.ql-toolbar {
    border-radius: 4px 4px 0px 0px;
    & span.view-html {
        font-size: 15px;
        font-weight: 600;
    }
}

div.raw-editor {
    display: none;
}

.show-raw {
    & .quill {
        display: none;
    }

    & div.raw-editor {
        display: block;
        margin: 0px;
        width: inherit;
        height: 300px;
        border-radius: 0px 0px 4px 4px;
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .quill {
            background-color: var(--base-palette-3-$(val));
            border: 2px solid var(--base-palette-3-$(val));
            color: var(--base-palette-1-$(val));
        }

        & .ql-toolbar {
            background-color: var(--base-palette-3-$(val));
            border: 2px solid var(--base-palette-3-$(val));
            color: var(--base-palette-1-$(val));
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) {
        & .quill {
            color: var(--black-$(val));
        }

        & .ql-toolbar {
            border-bottom: 1px solid var(--black-$(val));
        }
    }
}
