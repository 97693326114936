@import "../../../../../../theme/css-variables/master-variables.css";

.import-module .import-type-accordion-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & .type-name {
        margin-left: 6px;
        font-size: 15px;
        display: flex;
        align-items: center;
        & .icon-container {
            margin-right: 10px;
            & .default-svg-icon {
                height: 30px;
                width: 30px;
            }
            &.field-boundary {
                margin-right: 20px;
                & .default-svg-icon {
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }

    & .context-menu-container {
        margin-right: 5px;
        padding-top: 4px;
        height: 100%;

        & .context-menu {
            height: calc(100% - 8px);
            width: 15px;
            margin: 0;
            line-height: 15px;
            padding-right: 1px;

            &:not(.expanded) .menu-dot {
                visibility: hidden;
            }
        }
    }

    &:hover .context-menu-container .context-menu {
        & .menu-dot {
            visibility: visible;
        }
    }

    & .svg-stroke-white {
        stroke: #fff;
    }
}

@each $val in var(--theme-array) {
    .$(val) .import-module .import-type-accordion-item {
        background-color: var(--base-palette-1-$(val));
        border-top: 1px solid var(--base-palette-3-$(val));
        border-bottom: 1px solid var(--base-palette-3-$(val));

        &:hover .context-menu-container .context-menu {
            & .menu-dot {
                color: var(--highlight-$(val));
            }

            &:hover {
                background-color: var(--base-palette-4-$(val));
            }
        }
    }
}
