@import "../../../../theme/css-variables/master-variables.css";

.layer-module {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & .layer-list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    & .tabs .tabs-labels {
        min-height: 40px;

        & li.tab-label {
            height: 40px;
            box-sizing: border-box;

            &.left-non-label {
                flex-grow: 1;
            }

            &.right-non-label {
                width: 30px;
                display: flex;
                flex-direction: row-reverse;
            }
        }
    }

    & .standard-layers {
        display: flex;
        flex-direction: column;
        padding-top: 4px;

        & .dynamic-layer,
        & .graphics-layer {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-top: 5px;
            min-height: 25px;
            padding-left: 20px;
            cursor: pointer;

            & .checkbox-div {
                width: auto;
            }

            & .legend-icon {
                box-sizing: border-box;
                height: 20px;
                min-width: 20px;
                width: 20px;
            }

            & .legend-label {
                flex-grow: 1;
                line-height: 20px;
                margin-left: 6px;

                &.disabled {
                    opacity: 50%;
                }
            }

            & .sub-legend-layers {
                flex-basis: 100%;

                & > :first-child {
                    padding-top: 0;
                }

                & .legend-icon {
                    margin-left: 33px;
                }
            }

            & .context-menu {
                height: 21px;
                margin-top: -1px;
                padding-top: 1px;

                & .menu-dot {
                    line-height: 8px;
                    margin-bottom: -2px;
                }
            }

            & .svg-container {
                margin-top: -2px;
                padding-right: 5px;
            }
        }
    }

    & .layer-props-section {
        padding: 10px 0 0 20px;
    }
}

.event-module,
.layer-module {
    & .surface-properties-dialog {
        max-width: 300px;

        & .attribute-label {
            border-radius: 4px;
            padding: 10px;
            margin: 5px 10px;
            white-space: normal;
        }

        & .attribute-select .select-form-input {
            margin-top: 5px;
        }
        & .display-type-select .select-form-input,
        & .color-scheme-select .select-form-input {
            margin-top: 15px;
            margin-bottom: 5px;
        }

        & a {
            margin: 0 10px;
        }
        & .surfaces-reset-link {
            margin-top: 5px;
        }

        & .color-option {
            height: 24px;
        }

        & .color-ramp-section {
            display: flex;
            flex-direction: row;
            margin-top: 7px;

            & .inputs-section {
                flex: 78;
                display: flex;
                flex-direction: column;
            }

            & .legend-preview {
                display: flex;
                flex: 22;
                flex-direction: column;
                justify-content: center;
                margin-left: 5px;
                padding: 7px 0 7px 15px;
            }
        }

        & .legend-preview-item {
            display: flex;
            flex-direction: row;
            flex-grow: 1;

            & .legend-icon {
                margin-bottom: 1px;
                max-height: 20px;
                min-height: 17px;
                min-width: 20px;
            }

            & .legend-label {
                flex-grow: 1;
                overflow: hidden;
                white-space: nowrap;
                font-size: 13px;
                line-height: 17px;
                padding: 0 7px;
            }
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .event-module,
    .$(val) .layer-module {
        & .dynamic-layer:hover,
        & .graphics-layer:hover {
            background-color: var(--shadow-$(val));

            & .context-menu .menu-dot {
                color: var(--highlight-$(val));
            }
        }

        & .surface-properties-dialog {
            & .attribute-label {
                border: solid 1px var(--white-black-toggle-rgb3-$(val));
            }

            & .color-ramp-section > div:last-child {
                border-left: solid 1px var(--white-black-toggle-rgb3-$(val));
            }
        }
    }
}
