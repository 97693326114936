@import "../../../../../../theme/css-variables/master-variables.css";

.layer-module .surface-accordion-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & .line-scale-pulse-out-rapid {
        max-height: 25px;
        & > div {
            max-height: 20px;
        }
    }

    & .surface-checkbox,
    & .surface-radio {
        margin: 5px 0 0 40px;
        font-size: 13px;
        width: inherit;
    }

    & .surface-checkbox .checkbox-label {
        margin: 0;
    }

    & .sample-sites-icon {
        border-radius: 50%;
        height: 12px;
        margin: 8px 5px 0 7px;
        width: 12px;
    }

    & .surface-item-label {
        flex: 1;
        text-overflow: ellipsis;
        margin-top: 5px;
        overflow: hidden;
        line-height: 20px;
        white-space: nowrap;
    }

    & .warning-icon {
        height: 20px;
        margin: 5px;
    }

    & .context-menu {
        height: 21px;
        margin-top: 4px;
        padding-top: 1px;

        & .menu-dot {
            line-height: 8px;
            margin-bottom: -2px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .layer-module .surface-accordion-item {
        background-color: var(--shadow-$(val));

        & .context-menu .menu-dot {
            color: var(--shadow-$(val));
        }

        &.expanded .context-menu .menu-dot,
        &:hover .context-menu .menu-dot {
            color: var(--highlight-$(val));
        }

        & .warning-icon {
            fill: var(--caution-$(val));
        }
    }
}
