@import "../../../../theme/css-variables/master-variables.css";

.map-toolbar {
    min-height: 50px;
    display: flex;
    flex-direction: row;

    & .primary {
        flex-grow: 1;
    }

    & .queue-status-info {
        font-size: 32px;
        line-height: 35px;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
    }
    & .panel-modal {
        & .dialog-window {
            position: absolute !important;
            width: auto;
            height: auto;
        }
        & .dialog-container {
            position: fixed !important;
        }
    }
}

.map-tools {
    min-height: 50px;
    display: flex;
    flex-direction: row;

    &.action-panel-is-collapsed {
        padding-left: 40px;
    }

    & .text-form-input {
        flex-grow: 1;
        margin: 8px 5px 3px 0px;
    }

    & .tabs-labels {
        line-height: 20px;
        font-size: 11px;
    }

    & .measure-tools {
        width: 300px;

        & .measure-pane-content {
            display: flex;
            flex-direction: row;

            & .select-form-input-container {
                flex-grow: 1;
                margin: 0 5px 0 0;
                min-width: 125px;
                max-width: 125px;

                & .select-form-options > div {
                    margin-left: 0;
                }

                & > div {
                    margin-right: 0;
                }
            }
            & .measure-info {
                cursor: text;
                flex-grow: 2;
                font-size: 14px;
                line-height: 50px;
                overflow: hidden;
                padding-left: 5px;
                text-align: center;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        & .clear-measure {
            width: 100%;
            text-align: right;
            font-size: 11px;
            margin-top: -10px;
        }
    }

    & .search-tools {
        width: 340px;

        & .search-pane-content {
            display: flex;
            flex-direction: row;

            & .arcgisSearch {
                margin: 8px 0 5px 0;

                & .searchInput {
                    width: 250px;

                    &::placeholder {
                        font-family: "open_sansitalic";
                        font-size: 13px;
                        font-weight: 300;
                    }
                }

                & li {
                    padding: 8px 14px;
                    cursor: pointer;
                    min-height: inherit;
                    min-width: inherit;

                    & strong {
                        font-weight: bold;
                    }
                }
            }

            & .esri-search {
                margin: 8px 0 5px 0;
                justify-content: start;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                width: 100%;

                & .esri-input {
                    padding-left: 1em;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;

                    &::placeholder {
                        font-family: "open_sansitalic";
                        font-size: 13px;
                        font-weight: 300;
                    }
                }

                & .esri-menu {
                    border-radius: 5px;
                }

                & .esri-search__submit-button {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }

                & li {
                    padding: 8px 14px;
                    cursor: pointer;
                    min-height: inherit;
                    min-width: inherit;

                    & strong {
                        font-weight: bold;
                    }
                }
            }

            & .search-city {
                margin-left: 9px;
            }

            & .auto-search-pane-context {
                width: 302px;

                &::placeholder {
                    font-family: "open_sansitalic";
                    font-size: 13px;
                    font-weight: 300;
                }
            }

            & .search-loader {
                height: 61px;
                width: 330px;
            }

            & .text-form-input {
                width: 132.5px;
            }

            & .text-form-input:last-child {
                margin-right: 0;
            }

            & .select-form-input-container {
                flex-grow: 1;
                margin: 0 5px 0 0;
                width: 72.25px;

                & .text-form-input {
                    width: 72.25px;
                }

                & > div {
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            & > div:last-child {
                margin-right: 0;
            }
        }
    }
}

.dialog-container.import-confirmation {
    & .dialog-box-body {
        padding-bottom: 30px;
        &.restricted {
            max-width: 550px;
        }
    }
}
@each $val in var(--theme-array) {
    .$(val) .map-tools {
        background-color: var(--base-palette-4-$(val));

        & .searchInput,
        & .searchBtn,
        & .searchClear {
            background-color: var(--base-palette-3-$(val)) !important;
        }

        & .searchBtn:hover {
            background-color: var(--shadow-$(val)) !important;
        }

        & .arcgisSearch :focus {
            outline-color: var(--highlight-$(val));
        }

        & .esri-search {
            background-color: var(--base-palette-1-$(val));
        }

        & .esri-search :focus {
            outline-color: var(--highlight-$(val));
        }

        & .measure-info {
            color: var(--attention-$(val));
        }

        & .search-tools .search-pane-content .arcgisSearch {
            & .searchInput {
                &::placeholder {
                    color: var(--black-$(val));
                }
            }

            & li:hover {
                background-color: var(--base-palette-1-$(val));
            }
        }
        & .esri-search {
            & .esri-input {
                background-color: var(--base-palette-3-$(val));
                &::placeholder {
                    color: var(--black-$(val));
                }
            }

            & .esri-menu__list {
                background-color: var(--base-palette-3-$(val));
            }

            & button {
                background-color: var(--base-palette-3-$(val));
            }

            & li:hover {
                color: var(--highlight-$(val));
                background-color: var(--base-palette-1-$(val));
            }
        }
    }
}
