.with-api-result {
    & .dialog-container {
        min-height: 75px;

        & .dialog-box-body {
            min-height: 50px;
            user-select: auto;
        }

        & .dialog-box-title {
            padding: 0;
            margin: 0;
        }
    }
}
