@import "../../../../../theme/css-variables/master-variables.css";

.dialog-container.break-even-config-modal {
    width: 650px;
    height: 650px;

    & .dialog-box-body {
        overflow: visible;
        margin: 0;
        padding: 0;
        min-height: 100px;
    }

    & .grid-container {
        display: grid;
        grid-template-columns: 3.7fr 1.5fr 0.3fr 0.3fr;
        grid-gap: 5px;

        & .detailed-profit-loss-input {
            width: 80px;
            height: 22px;
            margin-bottom: 1px !important;
            margin-right: 10px !important;
        }

        & .table-detailed {
            display: block;
        }

        & .row-detailed {
            display: inline-flex;
            width: 650px;
        }

        & .cell {
            display: inline-block;
        }

        & .use-cell {
            display: inline-block;
            margin-top: 12px;
        }

        & .text-cell {
            display: inline-block;
            width: 180px;
            text-align: right;
            padding-right: 20px;
            margin-top: 15px;
        }

        & .title-text-cell {
            display: inline-block;
            width: 180px;
            text-align: right;
            height: 20px;
            padding-top: 10px;
            margin-left: 10px;
            margin-top: 20px;
            margin-bottom: 5px;
            padding-left: 85px;
            padding-right: 55px;
        }

        & .actual-val {
            padding-right: 45px;
            padding-left: 16px;
            width: 40px;
            min-width: 40px;
            text-align: end;
            margin-top: 15px;
        }

        & .title-cell {
            display: inline-block;
            height: 20px;
            padding-top: 10px;
            padding-right: 35px;
        }

        & .total {
            margin-top: 15px;
            margin-bottom: 5px;
            font-weight: bold;
        }

        & .total-value {
            padding-left: 5px;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        & .init-no-value {
            padding-left: 42px;
        }

        & .total-center {
            width: 80px;
            text-align: center;
        }
    }

    & .form-input:not(.select-form-input) {
        & input {
            text-align: center;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .rec-event-info {
        & .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid var(--shadow-$(val));
        }

        & .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid var(--warning-$(val));
        }
    }
}
