.tree-view-container {
    & .tree-node-container {
        & .tree-view-parent {
            display: flex;

            & .checkbox-div {
                width: 25px;
                margin-top: 0px;
            }
        }
        & .expand-icon {
            & svg {
                height: 14px;
                width: 13px;
            }

            & .svg-container {
                cursor: pointer;
                margin-right: 5px;
            }

            & .collapse-button-rotate-90 {
                transform: rotate(-90deg);
            }

            & .collapse-button-rotate-45 {
                transform: rotate(-45deg);
            }
        }
        & .label {
            line-height: 25px;
        }
    }

    & .node-child-container {
        margin-left: 40px;
    }
}
