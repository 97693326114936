@import "../../../theme/css-variables/master-variables.css";
.action-panel {
    & .module-filter-row {
        display: flex;
        height: 51px;
        min-height: 51px;
        white-space: nowrap;
        align-items: center;
        justify-content: flex-end;

        /* rules for when filters on are a separate row from the toggle */
        &.module-filters {
            align-items: flex-start;
        }
        /* rules for when filters are on same row as toggle */
        & .module-filters {
            max-width: calc(100% - 80px);
            flex-grow: 1;

            & .module-filters-inputs {
                & > div {
                    &:first-child:not(:last-child) {
                        & .form-input {
                            margin: 0 2px 0 2px;
                        }
                    }
                    &:last-child:not(:first-child) {
                        & .form-input {
                            margin: 0 5px 0 2px;
                        }
                    }
                }
                & .form-input {
                    margin: 0 2px;
                }
            }
        }
    }

    & .filter-toggle {
        height: 100%;
        width: 73px;
        padding-right: 10px;
        line-height: 51px;
        font-size: 13px;
        overflow: hidden;
        text-align: right;
        text-overflow: ellipsis;
    }

    & .filter-toggle-with-badge {
        display: flex;
        height: 100%;
        width: 95px;
        padding-right: 8px;
        line-height: 51px;
        font-size: 13px;
        overflow: hidden;
        text-align: left;

        & .filter-value-count {
            padding: 2px 5px;
            font-family: "open_sansregular";
            font-size: 13px;
            font-weight: 300;
            border-radius: 5px 5px 5px 5px;
            height: 28%;
            line-height: 15px;
            margin-top: 15px;
            margin-left: auto;
        }
    }

    & .module-filters-inputs {
        display: flex;
        flex-grow: 1;
        max-width: 100%;
        align-items: center;

        & > div {
            flex-grow: 1;
            width: 100%;

            &:first-child {
                & .form-input {
                    margin-left: 10px;
                }
            }
            &:last-child {
                & .form-input {
                    margin-right: 10px;
                }
            }
        }
        & .form-input {
            margin: 0 5px 8px 0;
        }

        & .filter-115 {
            max-width: 115px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .module-filter-row {
        background-color: var(--base-palette-1-$(val));

        & .search-loading-container {
            & .line-scale-party > div {
                background-color: var(--base-palette-4-$(val)) !important;
            }
        }
    }
    .$(val) .action-panel {
        & .filter-value-count {
            background-color: var(--attention-$(val));
            color: var(--white-$(val));
        }
    }
}
