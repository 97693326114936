@import "../../../../../../theme/css-variables/master-variables.css";

.import-module {
    & .import-file-info-accordion-item {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 5px;
        font-weight: 300;

        & .spacer {
            min-width: 5px;
            max-width: 5px;
            margin: 3px 10px 3px 18px;
        }

        & .file-info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            min-width: 30px;

            & .file-name {
                height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            & .file-upload-dt {
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        & .import-info {
            margin-left: 15px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            & .point-count {
                font-size: 11px;
                height: 20px;
            }

            & .import-status {
                font-size: 12px;
            }
        }

        & .context-menu-container {
            padding-left: 5px;
            padding-top: 2px;
            height: 100%;

            & .context-menu {
                width: 15px;
                margin: 0;
                line-height: 15px;
                padding-right: 1px;

                &:not(.expanded) .menu-dot {
                    visibility: hidden;
                }
            }
        }

        &:hover {
            & .context-menu-container .context-menu {
                & .menu-dot {
                    visibility: visible;
                }
            }
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .import-module {
        background-color: var(--base-palette-1-$(val));

        & .import-file-info-accordion-item {
            background-color: var(--base-palette-4-$(val));
            border-bottom: 1px solid var(--base-palette-1-$(val));

            &.selected {
                background-color: var(--base-palette-5-$(val));

                & .spacer {
                    background-color: var(--highlight-$(val));
                }
            }

            & .spacer {
                background-color: var(--base-palette-3-$(val));
            }

            &:hover {
                &:not(.selected) {
                    background-color: var(--shadow-$(val));
                }

                & .context-menu-container .context-menu .menu-dot {
                    color: var(--highlight-$(val));
                }
            }
        }
    }
}
