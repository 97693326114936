@import "../../../theme/css-variables/master-variables.css";

.checkbox-div {
    height: 25px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;

    & .checkbox-container {
        position: relative;
        display: inline-block;
        width: var(--checkboxSize);
        height: var(--checkboxSize);
        border-radius: 4px;
        cursor: pointer;

        & .checkbox-display {
            width: var(--checkboxSize);
            height: var(--checkboxSize);
            line-height: 15px;
            font-size: 15px;
            font-weight: 900;
            text-align: center;
        }

        & .checkbox-display.check-mark-color {
            font-weight: 900;
        }
    }

    & .checkbox-container:focus {
        outline: none;
    }

    & .checkbox-label {
        display: inline-block;
        height: 19px;
        margin-left: 6px;
        margin-right: 6px;
        vertical-align: top;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 19px;

        & .red-star {
            padding-right: 3px;
        }

        & .checkbox-label-text-span {
            line-height: 19px;
            font-size: 13px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .checkbox-div {
        & .checkbox-container {
            border: 2px solid var(--base-palette-3-$(val));
            background-color: var(--base-palette-3-$(val));
            color: var(--black-$(val));

            & .checkbox-display.check-mark-color {
                text-shadow: -1px 0 var(--base-palette-1-$(val)), 0 1px var(--base-palette-1-$(val)),
                    1px 0 var(--base-palette-1-$(val)), 0 -1px var(--base-palette-1-$(val));
            }
        }

        & .checkbox-container.focus {
            border: 2px solid var(--highlight-$(val));
        }

        & .checkbox-label {
            & .red-star {
                color: var(--error-$(val));
            }
        }

        & .disabled-checkbox {
            color: var(--base-palette-2-$(val)) !important;
        }
    }
}
