@import "../../../theme/css-variables/master-variables.css";

.owner-table {
    margin-top: 5px;
}

@each $val in var(--theme-array) {
    .$(val) {
        & .owner-table .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: var(--error-$(val));
        }
    }
}
