@import "../../../theme/css-variables/master-variables.css";

.radio-div {
    height: 25px;
    width: 100%;

    & .radio-container.radio-button-container {
        position: relative;
        display: inline-block;
        width: var(--checkboxSize);
        height: var(--checkboxSize);
        cursor: pointer;
        float: left;
        border-radius: 12px;

        & .radio-display {
            width: var(--checkboxSize);
            height: var(--checkboxSize);
            line-height: calc(var(--checkboxSize) - 1px);
            font-size: 10px;
            text-align: center;
        }
        & .radio-display.check-mark-color {
            font-weight: bold;
        }
    }

    & .radio-container.focus {
        outline: none;
    }

    & .radio-label {
        display: inline-block;
        height: 19px;
        margin-left: 6px;
        vertical-align: top;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        & .radio-label-text-span {
            line-height: 19px;
            font-size: 13px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .radio-div {
        & .radio-container.radio-button-container {
            border: 2px solid var(--base-palette-3-$(val));
            background-color: var(--base-palette-3-$(val));
            color: var(--black-$(val));

            & .radio-display.check-mark-color {
                text-shadow: -1px 0 var(--base-palette-1-$(val)), 0 1px var(--base-palette-1-$(val)),
                    1px 0 var(--base-palette-1-$(val)), 0 -1px var(--base-palette-1-$(val));
            }
        }

        & .radio-container.focus {
            border: 2px solid var(--highlight-$(val));
        }

        & .radio-label {
            & .red-star {
                color: var(--error-$(val));
            }
        }

        & .disabled-radio {
            color: var(--base-palette-2-$(val)) !important;
        }
    }
}
