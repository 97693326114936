.field-filter.blownup {
    width: 321px;
    & .dialog-box-body {
        overflow: visible;
    }
    & .filter-location {
        & .form-input {
        }
    }
    & .multi-select-container {
        margin: 10px;
        padding-right: 0;
        width: initial;

        & .select-form-input-multi.form-input {
            margin: 0;
        }
        & .dropdown-menu {
            width: 277px;
        }
        &.menu-open {
            & .form-input {
                width: initial;
            }
        }
    }
    & .dialog-box-children > a {
        display: inline-block;
        margin: 10px 20px 20px;
    }
}
