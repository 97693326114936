@import "../../../theme/css-variables/master-variables.css";

.tabs .tabs-labels {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    & li.tab-label:not(.left-non-label):not(.right-non-label) {
        flex-grow: 1;
        min-width: inherit;
        min-height: inherit;
        padding-top: 5px;
        text-align: center;
        user-select: none;
    }

    & li.tab-label:not(.left-non-label):not(.right-non-label):not(.disabled) {
        cursor: pointer;
    }

    & li.tab-label.disabled {
        opacity: 0.5;
        cursor: default;
    }
}

.dialog-box-body .tabs-labels {
    line-height: 20px;
}

@each $val in var(--theme-array) {
    .$(val) .tabs .tabs-labels li.tab-label {
        color: var(--white-$(val));
        background-color: var(--base-palette-4-$(val));

        &:hover:not(.active):not(.left-non-label):not(.right-non-label):not(.disabled) {
            background-color: var(--shadow-$(val));
        }

        &.active {
            background-color: var(--base-palette-1-$(val));
            color: var(--highlight-$(val));
            border-bottom: 4px solid var(--highlight-$(val));
        }
    }
}
