@import "../../../../../../../../theme/css-variables/master-variables.css";

.event-sample-soil-form .dialog-container.place-grid-modal {
    height: 300px;

    & .dialog-box-body {
        overflow: visible;
        margin: 0;
        padding: 0;

        & .radio-btn-groups {
            margin: 10px 20px;

            & .radio-grp {
                display: flex;
                flex-direction: row;
                padding-top: 2px;
                font-size: 14px;

                & .radio-grp-lbl {
                    width: 85px;
                }
                & .radio-div {
                    width: 105px;
                }
            }
        }

        & .dimensions {
            & .dim-input {
                display: flex;
                flex-direction: row;
                margin-left: 20px;
                width: 250px;

                & .select-form-input-container {
                    margin: 0;
                }

                & > * {
                    flex-grow: 1;
                    min-width: 10px;
                }
            }
            & .out-of-range-msg {
                margin: 5px 10px;
                font-weight: 600;
            }
        }

        & .instructions {
            margin: 20px 20px 0 15px;
            font-style: italic;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .rec-event-info {
        & .dialog-container.place-grid-modal .dialog-box-body {
            & .out-of-range-msg {
                color: var(--warning-$(val));
            }
        }
    }
}
