@import "../../../../../../../../theme/css-variables/master-variables.css";

.rec-event-info-form.sampling-tissue-zone-info {
    display: flex;
    flex-direction: column;

    & .select-form-input-container {
        max-width: 250px;
    }

    & .display-layer-select-input {
        margin-left: 5px;
    }

    & .zone-legend-container {
        flex-shrink: 0;
        max-height: 230px;
        overflow-x: hidden;
        overflow-y: auto;

        & .zone-legend-heading-container {
            margin: 10px 0 5px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            & .svg-container {
                cursor: pointer;
                & .default-svg-icon {
                    height: 15px;
                    width: 15px;
                    margin: 0 10px;
                }
            }
        }
        & .event-zone-classbreaks-legend {
            cursor: default;
        }
        & .interpolation-cb {
            margin: 13px 10px 0 10px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .rec-event-info-form.sampling-zone-info {
        & .event-zone-classbreaks-legend:hover {
            background-color: inherit;
        }
    }
}
