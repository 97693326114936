.field-module {
    & .field-filter-tabs {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        & .tabs {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            & .tabs-labels {
                height: 36px;
                min-height: 36px;
                padding-bottom: 4px;

                & li.tab-label {
                    flex-grow: inherit;
                    width: 75px;
                }

                & li.tab-label:not(.right-non-label) {
                    line-height: 38px;
                    padding-top: 0px;
                    font-size: 13px;
                }

                & li.tab-label.left-non-label {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: row-reverse;
                }

                & li.tab-label.right-non-label {
                    width: 25px;
                }

                & .context-menu-container {
                    float: right;
                }
            }

            & .tabs-pane {
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                & .field-accordion-container {
                    flex-grow: 1;
                }
            }
        }
    }
}
