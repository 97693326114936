@import "../../../../../../../../theme/css-variables/master-variables.css";

.import-wizard-progress-bar.single-step {
    margin-top: 9px;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
    display: flex;
    justify-content: center;

    & .progress-stop-container {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        & .progress-stop {
        }
        & .progress-stop-label {
            position: static;
            font-size: 16px;
            margin-left: 45px;
            min-width: 195px;
            margin-left: 10px;
            margin-top: 4px;
        }
    }
}

.import-wizard-progress-bar {
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    width: 85%;
    margin-top: 15px;
    display: flex;
    justify-content: space-around;

    & .progress-section-container {
        display: flex;
        position: relative;

        & .progress-section {
            margin-top: 5px;
            height: 14px;
            width: 170px;
        }
    }

    & .progress-stop-container {
        display: flex;
        flex-flow: column nowrap;
        z-index: 2;
        margin-left: -5px;
        margin-right: -5px;

        & .progress-stop {
            & .progress-stop-symbol {
                max-width: 25px;
                min-width: 25px;
                width: 25px;
                border-radius: 50%;
                max-height: 25px;
                min-height: 25px;
                height: 25px;
                font-size: large;

                & .progress-stop-label {
                    margin-top: 5px;
                }
            }

            & .progress-stop-active,
            & .progress-stop-complete {
                border-radius: 50%;

                & .progress-active-symbol,
                & .progress-complete-symbol {
                    width: 21px;
                    height: 21px;
                    border-radius: 50%;
                    position: relative;
                    left: 2px;
                    top: 2px;
                    text-align: center;
                    line-height: 19px;
                    font-size: 15px;
                }

                & .progress-complete-symbol {
                    line-height: 20px;
                    font-size: 16px;
                }
            }
        }
        & .progress-stop-label {
            position: absolute;
            width: 70px;
            top: 32px;
            right: -28px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: center;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .import-wizard-progress-bar {
            & .progress-stop-symbol {
                background-color: var(--base-palette-3-$(val));
            }

            & .progress-section {
                color: var(--highlight-$(val));
                background-color: var(--base-palette-3-$(val));
            }

            & .progress-active-symbol,
            & .progress-complete-symbol,
            & .progress-section-complete {
                background-color: var(--highlight-$(val));
            }
        }
        & .import-wizard-progress-bar:not(.single-step) .progress-label-active {
            color: var(--highlight-$(val));
        }
    }
}
