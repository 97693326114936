@import "../../../../../../theme/css-variables/master-variables.css";

.import-module .import-accordion-item {
    user-select: none;
    cursor: pointer;
    box-sizing: border-box;
    white-space: nowrap;
}

@each $val in var(--theme-array) {
    .$(val) .import-module .import-accordion-item {
        color: var(--white-black-toggle-$(val));
    }
}
