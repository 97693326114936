.click-me-link {
    padding-right: 5px;
}

.telematics-grid {
    & .data-table-header .data-header-cell,
    & .plain-data-table .data-table-cell {
        max-width: 154px;
    }

    & .data-header-cell.connection-type {
        padding-left: 0px;
    }

    & .data-header-cell.connection-name {
        padding-left: 15px;
    }
}
