@import "../../../../../theme/css-variables/master-variables.css";

.dialog-container.upload-modal {
    padding-left: 0;

    & > .dialog-drag-bar {
        margin: 0;
        height: 45px;
        width: 100%;
    }

    & > .dialog-box-title {
        margin-left: 5px;
        padding: 5px 5px 10px 10px;
        user-select: none;
        font-size: 15px;
        font-weight: 300;
        min-width: inherit;
    }

    & > .dialog-box-body {
        margin-bottom: 0;
        padding-left: 5px;
        overflow-y: hidden;
    }

    & .upload-modal-body {
        display: flex;
        flex-direction: row;
        user-select: none;

        & .type-template-select {
            width: 295px;
        }
    }

    & .dialog-box-footer {
        padding: 10px 15px 0 0;
        min-height: 50px;
        display: flex;
        justify-content: flex-end;
        user-select: none;

        & .file-types {
            flex-grow: 1;
            cursor: default;

            & div {
                padding: 6px 15px;
                margin-left: -15px;
                width: 265px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }

        & button {
            margin: 5px 4px;
        }

        & .resize-handle {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 20px;
            width: 20px;
            cursor: nwse-resize;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .dialog-container.upload-modal {
        & .dialog-box-footer {
            padding: 10px 15px 0 0;
            min-height: 50px;
            display: flex;
            justify-content: flex-end;

            & .file-types div {
                background-color: var(--attention-$(val));
                color: var(--white-$(val));
            }
        }
    }
}
