@import "./theme/css-variables/master-variables.css";

.root-component {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    font-family: "open_sansregular";
    font-size: 14px;
    font-weight: 300;
    user-select: none;

    & .nav-component {
        text-align: right;
        padding: 0 10px;
    }

    & .main-content-container {
        max-height: calc(100% - 80px);
        height: calc(100% - 80px);
        background-color: transparent;
        flex-grow: 1;
        overflow: visible;
        display: flex;
        flex-direction: row;
        position: relative;
    }
}

a:focus,
a:hover {
    cursor: pointer;
}
::-ms-clear {
    display: none;
}

::-webkit-scrollbar-button {
    height: 15px;
}

::-webkit-scrollbar {
    width: 16px;
}

::-webkit-scrollbar,
::-webkit-scrollbar-button,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
    box-sizing: border-box;
}

@each $val in var(--theme-array) {
    .$(val) {
        color: var(--white-black-toggle-$(val));

        scrollbar-base-color: var(--base-palette-1-$(val));
        scrollbar-base-color: var(--base-palette-3-$(val));
        scrollbar-track-color: var(--base-palette-1-$(val));
        scrollbar-arrow-color: var(--base-palette-3-$(val));
        scrollbar-shadow-color: var(--base-palette-3-$(val));

        & .root-component .main-content-container {
            border-top: 2px solid var(--base-palette-3-$(val));
        }

        & a:focus,
        & a:hover {
            color: var(--highlight-$(val));
        }

        & ::-webkit-scrollbar-button {
            background-color: var(--base-palette-4-$(val));
            border: 1px solid var(--base-palette-3-$(val));
        }

        & ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 3px var(--base-palette-3-$(val));
            background-color: var(--base-palette-1-$(val));
        }

        & ::-webkit-scrollbar-track-piece {
            background-color: var(--base-palette-1-$(val));
            border-left: 1px solid var(--base-palette-3-$(val));
            border-right: 1px solid var(--base-palette-3-$(val));
        }

        & ::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px var(--shadow-rgb2-$(val));
            background-color: var(--base-palette-3-$(val));
        }

        & .disabled {
            color: var(--base-palette-2-$(val)) !important;
        }
    }
}
