@media screen and (max-width: 1240px) {
    .section-container {
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;

        & .form-section {
            flex-grow: 1;
            max-width: 394px;
            margin-right: 10px;

            & .form-sub-section {
                flex-direction: column;
                margin-bottom: 0px;

                & > div {
                    margin-top: 7px;
                    flex-grow: 1;

                    &.mini-grid {
                        margin-top: 0px;
                    }
                }

                & .form-input.textarea-form-input {
                    width: auto;
                }
            }
        }

        & .bar,
        & .no-bar {
            &.section-fence {
                display: none;
                margin: 0;
            }
        }

        & .bucket.form-section-bucket {
            flex-grow: 1;
            margin-right: 10px;

            & .bucket-header {
                flex-grow: 1;
            }
        }
    }
}

@media screen and (max-width: 610px) {
    .section-container {
        flex-direction: column;
    }
}

@media (min-width: 611px) and (max-width: 960px) {
    .section-container {
        & .bucket.form-section-bucket {
            max-width: 394px;
        }
    }
}

@media (min-width: 961px) and (max-width: 1240px) {
    .section-container {
        max-width: 1240px;
    }
}
