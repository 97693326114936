@import "../../../theme/css-variables/master-variables.css";
@import "react-datetime/css/react-datetime.css";

.date-form-input-container {
    position: relative;
    min-width: 20px;
    cursor: pointer;

    & .expand-contract-date-icon {
        margin: 2px 2px 2px 0;
        width: 20px;
        height: 20px;
    }

    & .clear-date-icon {
        width: 20px;
    }

    & .rdtStatic {
        position: absolute;
        left: 10px;
        margin-top: -11px;
        z-index: 100;
    }

    & .date-open-left.rdtStatic {
        left: 0;
        right: auto;
    }

    & .date-open-right.rdtStatic {
        left: auto;
        right: 0;
    }

    & .rdtPicker {
        user-select: none;
        padding: 2;
        border-radius: 2px 2px 8px 8px;

        & .dow {
            cursor: default;
        }

        & td,
        & th {
            vertical-align: middle;
        }

        & .rdtPrev {
            width: 38px;
        }

        & .rdtNext {
            width: 38px;
        }

        & th.rdtSwitch {
            width: inherit;

            &:hover {
                background-color: inherit;
            }
        }

        & .rdtTime {
            & td {
                cursor: default;
            }

            & :hover {
                background-color: inherit;
            }

            & table tbody {
                background-color: inherit;
            }
        }

        & thead tr:first-child th:hover {
            background-color: inherit;
        }

        & .rdtTimeToggle:hover {
            background-color: inherit;
            cursor: pointer;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .date-form-input-container {
            & .expand-contract-date-icon {
                fill: var(--black-$(val));
            }

            & .clear-date-icon {
                fill: var(--black-$(val));

                &:hover {
                    border: var(--black-$(val)) 1px dotted;
                }
            }
        }

        & .rdtPicker {
            background: var(--base-palette-1-$(val));

            & :not(.rdtTime) tbody {
                background-color: var(--base-palette-4-$(val));
            }

            & .rdtPrev {
                border-right: var(--white-$(val)) 1px solid;
            }

            & .rdtNext {
                border-left: var(--white-$(val)) 1px solid;
            }

            & .rdtTime {
                & td.rdtActive,
                & td.rdtActive:hover {
                    background-color: var(--attention-$(val));
                }
            }

            & .rdtDays,
            & .rdtMonths,
            & .rdtYears {
                & td,
                & th {
                    &.rdtDay:not(.rdtActive):hover,
                    &.rdtMonth:not(.rdtActive):hover,
                    &.rdtYear:not(.rdtActive):hover {
                        background-color: var(--clickable-$(val));
                    }
                }
            }
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) {
        & .date-form-input-container {
            & .expand-contract-date-icon {
                fill: var(--black-$(val));
            }

            & .clear-date-icon {
                fill: var(--black-$(val));

                &:hover {
                    border: var(--black-$(val)) 1px dotted;
                }
            }
        }

        & .rdtPicker {
            & :not(.rdtTime) tbody {
                background-color: var(--base-palette-6-$(val));
            }

            & .rdtTime td.rdtActive,
            & td.rdtActive:hover {
                background-color: var(--attention-$(val));
                color: var(--white-$(val));
            }

            & .rdtDays,
            & .rdtMonths,
            & .rdtYears {
                & td,
                & th {
                    &.rdtDay:not(.rdtActive):hover,
                    &.rdtMonth:not(.rdtActive):hover,
                    &.rdtYear:not(.rdtActive):hover {
                        background-color: var(--clickable-$(val));
                        color: var(--white-$(val));
                    }
                }
            }
        }
    }
}
