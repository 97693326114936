@import "../../../theme/css-variables/master-variables.css";

.org-level-grid-container {
    margin-left: auto;
    margin-right: auto;
    width: 1240px;

    & .org-level-nested-row {
        overflow-y: auto;

        & .data-table-paginator {
            padding-left: 30px;
        }
    }

    & .org-level-header-cont {
        display: flex;
        justify-content: flex-end;
        float: right;
        width: 50%;
    }

    & .org-level-table.child-component {
        & .content-table-container.data-table-cont.nested-data-table {
            width: 98%;

            &
                .data-table-row.data-table-header
                .data-table-cell.data-table-cell-relative.data-table-container {
                width: 10%;
                padding-left: 0px;
            }

            & .nested-table-row .data-table-container {
                width: 5%;

                &.no-arrow {
                    width: 10%;
                }
            }

            & .data-table-rows {
                overflow-y: hidden;
            }
        }
    }

    & .org-level-table.child-component.non-editable-grid {
        & .data-table-row.data-table-header {
            padding-left: 50px;
        }
    }

    & .nested-row-icon {
        cursor: pointer;
        line-height: 2;
    }

    & .footer-bar {
        width: 100%;
        margin: 0;
        height: 20px;
        line-height: 20px;
        margin-left: auto;
        margin-right: auto;
    }
    & .data-table-container.location-icon-box {
        width: 107px;
    }
    & .location-table-row .data-table-container.location-icon-box {
        width: 50px;
    }
    & .company-table-header .empty-cell {
        width: 65px;
    }
}

.owner-add-edit-page {
    width: 1240px;
    margin: auto;
}

.sliding-panel-header .button-container .default-button.cancel-button {
    &.org-level-cancel {
        margin-right: 1px;
    }

    &.owner-cancel {
        margin-right: 4px;
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .footer-bar {
            background-color: var(--base-palette-4-$(val));
        }
    }
}
