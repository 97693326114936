.import-filter-main-container {
    & .form-input {
        margin: 10px 0px 0px 0px;
    }
}
.sliding-panel-header .button-container .default-button.cancel-button.import-filter-cancel {
    margin-right: 1px;
}
.vertical-section-spacer {
    padding-top: 15px;
}
