.report-field-symbology-dialog {
    width: 300px;

    & .form-section-header {
        background-color: unset;
        font-size: 14px;
        margin-bottom: 0;
    }

    & .checkbox-div {
        margin-bottom: 0;
        margin-left: 10px;
        margin-top: 0;

        & .checkbox-label {
            height: 20px;
        }

        &.no-checkbox {
            & .checkbox-label:first-of-type {
                margin-left: 0;
            }
        }
    }

    & .checkbox-label-text-span.caret {
        font-size: 8px;
    }

    & .color-picker-container {
        & .color-selector-wrap {
            min-width: 19px;
            min-height: 19px;
        }
        & .color-selector-button {
            min-width: 18px;
            min-height: 18px;
        }
        & .default-svg-icon {
            width: 10px;
            height: 10px;
        }
    }

    & .reset {
        padding: 10px;
        width: 100px;
    }
}
