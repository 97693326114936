.filter-header-container {
    display: flex;
    width: 1240px;
    margin: auto;
    margin-bottom: 5px;
    min-height: 60px;
    & .filter-container {
        width: 50%;
    }
    & .active-inactive-container {
        width: 50%;
    }
}

.sliding-panel-header .button-container .default-button.cancel-button.person-cancel {
    margin-right: 4px;
}
