.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .harvest-event-container {
    & .label-title {
        height: 20px;
        font-size: 15px;
        font-weight: 300;
        cursor: pointer;
        user-select: none;
        margin-left: 10px;
    }

    & .harvest-event-checkbox {
        margin: auto;
        padding-left: 5px;
    }

    & .select-bales-type {
        & .select-form-input.form-input.text-form-input {
            margin: 5px 0px 5px 3px;
        }
    }

    & .harvest-select-cont {
        width: 49%;
    }

    & .harvest-text-cont {
        width: 44%;
    }

    & .col-3 {
        width: 35%;
    }

    & .feed-quality-3 {
        max-width: 29%;
    }
}
