.combine-fields-modal {
    width: 30rem;

    & .combine-field-list {
        margin: 1rem 0 1rem 1rem;
    }

    & .new-field-details {
        width: 15rem;
    }

    & .combine-fields-warning {
        margin-top: 1rem;
        font-weight: bold;
    }

    & .combine-fields-error {
        margin-top: 1rem;
        font-weight: bold;
        color: red;
    }
}
