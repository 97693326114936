@import "../../../../../../../theme/css-variables/master-variables.css";

.irrigation-event-container {
    margin: 0px 3px;
    & .drip-checkboxes {
        display: flex;
        padding: 10px;
    }

    & .irrigation-event-section-header {
        width: 370px;
        height: 40px;
        line-height: 40px;
    }
}

.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form {
    & .input-row > {
        & .irrigation-type {
            width: 195px;
            flex-grow: 0;
        }
        & .installation-type {
            width: 162px;
            flex-grow: 0;
        }
    }
    & .bucket.import-field-section-bucket {
        padding: 0px 4px;
    }
}

@each $val in var(--theme-array) {
    .$(val) .irrigation-event-section-header {
        background-color: var(--base-palette-5-$(val));
    }
}
