@import "../../../../../../../theme/css-variables/master-variables.css";

.event-scouting-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    & .method-selection {
        margin: 0 9px 9px 9px;

        &.tabs {
            & .tabs-labels li.tab-label:not(.left-non-label):not(.right-non-label) {
                padding-top: 12px;
            }

            & .tabs-pane {
                padding: 8px;

                & .manual-method-steps,
                & .grid-method-steps {
                    display: flex;
                    flex-direction: row-reverse;
                }
            }
        }
    }
    & .scouting-event-container {
        & .bucket-content {
            & .scouting-details {
                padding-top: 1em;
                width: 95%;
            }
            & .textarea-form-input {
                width: 95%;
                height: 90px;
            }
        }
        & .select-form-sub-header {
            font-weight: bold;
            cursor: default;
            height: 50%;
            display: flex;
            align-items: center;
            padding-top: 5px;
            margin-left: 5px;
        }
        & .observation-item-header {
            font-weight: bold;
            padding-bottom: 1em;
        }
        & .no-issues-found {
            height: 18px;
            margin-top: 3px;

            & .checkbox-div .checkbox-label {
                margin-top: 1px;
            }
        }
        & .input-row-observation {
            width: 100%;
            display: flex;
            flex-direction: row;

            & .value {
                flex-grow: 1;
                width: calc(50% - 16px);
            }

            & .unit {
                width: 50%;
            }

            & .select-input-category-container {
                position: relative;
                min-width: 20px;
            }

            & .pressure,
            & .growth {
                max-width: 50%;
            }
        }

        & .observation-photo-row {
            width: 100%;
            margin-left: 1px;
            font-size: 0px; /* required to eliminate the vertical space between img elements when wrapping */

            & .observation-photo-thumbnail {
                width: 73px;
                height: 73px;
                margin: 5px 3px;
                border-radius: 3px;
                border-style: solid;
                border-width: 3px;
                object-fit: contain;
                cursor: pointer;
            }

            & .observation-photo-thumbnail-loader {
                display: inline-block;
                position: relative;
                width: 73px;
                height: 73px;
                margin: 5px 3px;
                border-radius: 3px;
                border-style: solid;
                border-width: 3px;
                text-align: center;
                vertical-align: top;

                & .loader-container > div {
                    background-color: transparent !important;
                }
            }

            & .dialog-box-body {
                overflow-y: hidden;

                & .fullsize-photo-not-found-msg {
                    width: 400px;
                    height: 400px;
                    line-height: 400px;
                    text-align: center;
                }

                & .description-input-row {
                    width: 80%;
                }

                & .fullsize-photo-row {
                    width: 400px;
                    min-height: 400px;

                    & img {
                        max-width: 400px;
                        width: 100%;
                    }
                }
            }

            & .add-photo-link-short {
                display: inline-block;
                width: 100%;
                height: 100%;
                margin: 2px 1px 1px 7px;
                text-align: left;
            }

            & .add-photo-link-tall {
                display: inline-block;
                width: 79px;
                line-height: 83px;
                margin: 2px;
                text-align: center;
                vertical-align: top;
            }
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .rec-event-info {
        & .event-scouting-form {
            & .tabs .tabs-labels {
                background-color: var(--base-palette-4-$(val));

                & li.tab-label {
                    background-color: var(--base-palette-2-$(val));
                    &:not(.active) {
                        color: var(--white-black-toggle-$(val));
                    }
                    &.active {
                        background-color: var(--base-palette-1-$(val));
                    }
                }
            }

            & .method-selection .tabs-pane {
                background-color: var(--base-palette-1-$(val));
            }

            & .observation-photo-thumbnail {
                border-color: var(--base-palette-3-$(val));
                background-color: var(--base-palette-3-$(val));
            }

            & .observation-photo-thumbnail-loader {
                border-color: var(--base-palette-3-$(val));
                background-color: var(--base-palette-3-$(val));
            }
        }
    }
}
