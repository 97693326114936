.report-page-selection-dialog {
    & .dialog-box-body.restricted {
        max-height: 500px !important;
        overflow: auto;
    }
    & .report-page-selection-report {
        padding-bottom: 10px !important;
        & .checkbox-div {
            margin: 0 !important;
            width: 100% !important;
        }
        & .report-page-selection-pages {
            padding-left: 30px !important;
        }
    }
}
