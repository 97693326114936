@import "../../../../theme/css-variables/master-variables.css";

.rec-event-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 0;

    & .item-container {
        & .rec-event-accordion-item {
            position: static;
            height: 40px;
        }

        &.batch-strip {
            & .spacer {
                display: none;
            }
        }

        &.batch-rec-event-strip {
            cursor: pointer;
        }
    }

    & .tabs.information-tabs {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        min-height: 0;

        & .loader-container {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        & > .tabs-labels {
            flex-direction: column;
            overflow: hidden;
            min-width: 60px;

            & li.tab-label,
            & li.tab-label.right-non-label {
                padding: 0;
                flex-grow: inherit;
                border-bottom: none;
                height: 80px;
                min-height: 80px;
                width: 60px;

                & .default-svg-icon {
                    height: 32px;
                    width: 32px;
                }
            }

            & .rec-event-info-tab-label {
                padding: 12px 0 6px 0;
                height: 62px;
                width: 60px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                font-size: 12px;
                position: relative;

                & .remove-btn {
                    position: absolute;
                    right: 3px;
                    top: 5px;

                    & .default-svg-icon {
                        height: 10px;
                        width: 10px;
                    }
                }

                &.add-item {
                    padding: 0;
                    height: 80px;
                    justify-content: flex-start;
                    align-items: center;

                    & .menu-dots {
                        margin-top: 15px;
                        height: 25px;
                        width: 25px;
                        background-color: transparent;

                        & .svg-container {
                            & .default-svg-icon {
                                height: 25px;
                                width: 25px;
                                margin-bottom: 10px;
                            }
                        }
                    }

                    & .menu-item {
                        font-size: 12px;
                    }
                }
            }
        }

        & > .tabs-pane {
            flex-grow: 1;
            min-width: 50px;
            display: flex;
            flex-direction: column;

            & .rec-event-info-form {
                flex-grow: 1;
                overflow-y: auto;
                overflow-x: hidden;
                height: 10px;

                & .form-input {
                    margin: 5px 3px;
                    &.select-form-input-options {
                        margin: 0 3px;
                    }
                }

                & .multi-select-container {
                    margin: 5px 3px;
                    & .form-input {
                        margin: 0;
                    }
                }

                & .multi-select-container.menu-open .form-input {
                    width: unset;
                }

                & .bucket,
                & .bucket-header,
                & .bucket-content {
                    width: inherit;
                }

                & .bucket {
                    padding: 0 5px;
                }

                & .bucket-header {
                    padding-left: 5px;
                }

                & .input-row {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    & > * {
                        flex-grow: 1;
                        width: 20px;
                    }
                    & .width-40 {
                        width: 40%;
                        flex-grow: 0;
                    }
                    & .width-50 {
                        width: 50%;
                        flex-grow: 0;
                    }
                    & .width-25 {
                        width: 25%;
                        flex-grow: 0;
                    }
                    & .width-20 {
                        width: 20%;
                        flex-grow: 0;
                    }
                    & .width-30 {
                        width: 30%;
                        flex-grow: 0;
                    }
                }
                & .input-row.half {
                    width: 50%;
                }
                & .input-row.col-3 {
                    width: 33%;
                }
            }
        }
    }

    & .save-cancel-btns {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 15px;
        min-height: 34px;
        overflow: hidden;

        & .default-button {
            margin: 0 7px;
        }
    }

    & .equation-rec-loader {
        height: calc(100% - 110px);
        top: 50px !important;
    }
}

.error-field-list {
    padding-top: 15px;
}

@each $val in var(--theme-array) {
    .$(val) .rec-event-info {
        & .item-container:not(.batch-rec-event-strip) .rec-event-accordion-item {
            background-color: var(--base-palette-1-$(val));
        }
        & .item-container.batch-rec-event-strip .rec-event-accordion-item {
            background-color: var(--base-palette-5-$(val));
            &:hover {
                background-color: var(--base-palette-5-$(val));
            }
        }

        & .tabs.information-tabs {
            & > .tabs-labels {
                background-color: var(--base-palette-1-$(val));

                & li.tab-label {
                    background-color: var(--base-palette-1-$(val));

                    &.active {
                        background-color: var(--base-palette-4-$(val));
                        color: var(--highlight-$(val));
                        & .default-svg-icon {
                            fill: var(--highlight-$(val));
                        }
                    }

                    &:not(.active) {
                        color: var(--white-black-toggle-$(val));
                        & .default-svg-icon {
                            fill: var(--white-black-toggle-$(val));
                        }
                    }

                    & .remove-btn .default-svg-icon {
                        fill: var(--white-black-toggle-$(val));
                    }

                    & .rec-event-info-tab-label.has-error {
                        color: var(--error-$(val));
                        & .default-svg-icon {
                            fill: var(--error-$(val));
                        }
                    }
                }
            }
        }

        & .rec-event-info-form {
            background-color: var(--base-palette-4-$(val));

            & .select-form-input-error .text-form-input {
                /* TODO: enable just applying `form-input-error` to `<SelectInput />` */
                border: 2px solid var(--error-$(val));
            }

            & .bucket-arrow-icon {
                fill: var(--white-black-toggle-$(val));
            }
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) .rec-event-info {
        & .rec-event-info-form {
            background-color: var(--base-palette-6-$(val));
        }

        & .tabs.information-tabs > .tabs-labels li.tab-label.active {
            background-color: var(--base-palette-6-$(val));
        }
    }
}
