.imagery-form {
    & .imagery-input-row {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        & .imagery-form-input {
            width: 94%;
        }
        & .select-form-input-container {
            width: 49%;
        }
        & .date-form-input-container {
            width: 49%;
        }
        & .check-box-container {
            margin: 1mm 0 0 1mm;
        }
    }
}
