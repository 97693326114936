.product-mixes-table {
    & .product-row {
        margin: 10px;
        margin-left: 20px;
    }

    & .mix-title {
        margin-top: 10px;
        font-weight: 900;
        font-size: 14px;
    }

    & .product-menu {
        margin-top: 13px;
        padding-left: 15px;
        & .context-menu .menu-dot {
            color: #404040 !important;
        }

        &:hover .context-menu {
            & .menu-dot {
                color: #8fce00 !important;
            }
        }
    }

    & .rate-input {
        width: 20%;
        min-width: 20%;
        padding-top: 8px;
    }

    & .rate-unit {
        margin-top: 25px;
    }

    & .text-form-input {
        height: 22px;
        margin-right: 7px;
    }

    & .form-input:not(.select-form-input) input {
        text-align: center;
    }

    & .product-mixes-rows {
        display: flex;
        flex-direction: column nowrap;
        width: 100%;
    }

    & .product-name-column {
        min-width: 208px;
        width: 208px;
        margin-left: 8px;
        overflow-wrap: break-word;
    }
}
