@import "../../../../theme/css-variables/master-variables.css";

/*TODO: Clean up, create a common style css file*/
.data-table-cont {
    width: 1240px;
    margin: auto;
    font-family: "open_sansregular";
    font-size: 13px;
    font-weight: 300;
    flex: 1;
    display: flex;
    flex-flow: column;
    user-select: none;
}

.grid-col-5 {
    width: 5%;
    max-width: 60px;
}

.grid-col-10 {
    width: 10%;
    max-width: 121px;
}

.grid-col-13 {
    width: 13%;
    max-width: 13%;
}

.grid-col-15 {
    width: 15%;
    max-width: 183px;
}

.grid-col-16 {
    width: 16%;
    max-width: 16%;
}

.grid-col-18 {
    width: 18%;
    max-width: 200px;
}

.grid-col-20 {
    width: 20%;
    max-width: 242px;
}

.grid-col-25 {
    width: 25%;
    max-width: 255px;
}

.grid-col-30 {
    width: 30%;
    max-width: 369px;
}

.grid-col-40 {
    width: 40%;
    max-width: 484px;
}
.grid-expand {
    flex-grow: 1;
}

.col-shift-15 {
    left: 15px;
    margin-right: 15px;
}

.flex-grow-2 {
    & .data-table-cell {
        flex-grow: 2;
    }
}

.data-table-rows {
    margin-left: auto;
    margin-right: auto;
    clear: both;
    overflow-y: auto;
    flex-basis: max-content;
    width: 100%;

    &:after {
        flex-basis: "max-content";
    }

    & .data-table-plain-row {
        padding-left: 20px;
    }

    & .dt-row-log-window {
        & .data-table-cell.data-table-cell-relative {
            padding-left: 40px;
        }
    }
}

.data-table-row {
    height: 26px;
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-shrink: 0;
}

.data-table-header {
    flex-shrink: 0;
    font-size: 13px;

    & .search-input {
        & .form-input {
            margin: 0px;
            padding: 0px;
            border: none;
        }
    }

    & .data-table-container {
        padding-top: 0px;

        & .default-svg-icon {
            height: 26px;
            width: 26px;
        }
    }

    & .search-input .input-container input {
        width: 100%;
    }
}

.data-table-cell-relative {
    position: relative;
}

.data-table-cell {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 1px;
    min-height: 15px;

    & .svg-container {
        padding-right: 2px;
    }
    & .checkbox-div {
        padding-top: 2px;
    }

    & p,
    & a {
        max-width: 97%;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 15px;
        font-size: 13px;
    }
}

.data-table-cell-absolute {
    position: absolute;
}

.data-table-row-selected {
    & .data-table-container {
        & .data-table-icon-box {
            & .svg-container {
                cursor: pointer;
            }
        }
    }
}

.data-table-paginator {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 5px 0 10px 5px;
    flex-shrink: 0;
}

.data-table-paginator-indices {
    display: flex;
    flex-wrap: nowrap;
}

.data-table-paginator-input {
    display: flex;
    align-items: center;
    margin-right: 22px;

    & span {
        margin-right: 5px;
    }

    & .select-form-input-container {
        width: 60px;
        height: 20px;
        margin: 0;
        padding-bottom: 10px;

        & input {
            width: 30px;
        }

        & .form-input {
            margin: 0;
            padding: 0;
            height: auto;
        }
        & .input-label-container {
            display: none;
        }
    }
}

.line-item {
    padding: 5px 0 0 5px;
    display: inline;
}

.data-header-sort {
    padding-left: 3px;
    padding-top: 10px;
    position: relative;
    flex-grow: 1;
    min-width: 20px;
}

.data-header-sort-shrinked {
    min-width: 15px;
    padding-left: 0px;
    padding-top: 10px;
}

.data-header-cell {
    display: flex;

    & .data-table-cell-relative {
        display: flex;
    }

    & .filter-container {
        width: calc(100% - 5px);

        & .auto-search-input.select-form-input-container {
            border: 1px solid transparent;
        }

        & div.text-form-input .input-container input {
            text-indent: 0px;
            cursor: text;
            margin-right: 5px;
            margin-left: 2px;
        }

        & .filter-input {
            & .form-input.text-form-input.focus {
                border: none;
            }
        }
    }

    & .select-form-input.select-form-input-expanded.form-input.focus {
        border-radius: 4px;
    }

    & .filter-input.has-filter-value.list-expanded.select-form-input-expanded.focus {
        border-radius: 4px 4px 0 0;
        border-bottom: none;
    }

    & .select-form-options {
        position: fixed;
        z-index: 1;
        & .form-input {
            margin: 0px;
        }
    }

    & .data-header-sorting-icon {
        height: 15px;
        width: 15px;
    }
}

.filter-input {
    height: 24px;
    margin: 0px;
    padding: 0px;
}

.data-table-container {
    height: 100%;
    min-width: 50px;
    padding-left: 15px;

    & .nested-grid-expand-button {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        & .svg-container {
            cursor: pointer;
        }
    }

    & .default-svg-icon {
        height: 22px;
        width: 22px;
    }
}

.data-table-footer {
    display: flex;
    height: 20px;
    align-items: center;
    padding-left: 15px;
    flex-shrink: 0;
    & a {
        margin-left: 10px;
    }
    & .justify-links {
        margin-right: 40px;
    }
}

.data-table-add-icon {
    cursor: pointer;
}

.invisible-add-edit-icon {
    visibility: hidden;
    pointer-events: none;
}

.bar {
    margin-left: 10px;
}

.data-table-icon-box {
    display: flex;
}

.filter-input {
    & .auto-search-options {
        margin-top: 5px;
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .data-table-row {
            border-bottom: 1px solid var(--base-palette-4-$(val));
        }

        & .data-table-header {
            background-color: var(--base-palette-4-$(val));
        }

        & .data-table-row-selected {
            background-color: var(--clickable-$(val));
        }

        & .line-item {
            & a:focus,
            & a:active {
                color: var(--clickable-$(val));
            }
            & .selected-page-index {
                color: var(--highlight-$(val)) !important;
            }
        }

        & .data-header-cell {
            & .select-form-input.select-form-input-expanded.form-input.focus {
                padding: 0px;
            }
        }

        & .filter-input.has-filter-value {
            & .form-input.text-form-input {
                background-color: var(--attention-rgb-$(val));
            }

            & .form-input.text-form-input {
                background-color: var(--attention-rgb-$(val));
            }

            & .input-container {
                background-color: var(--attention-rgb-$(val));

                & input {
                    background-color: var(--attention-rgb-$(val));
                }
            }
        }

        & .data-table-footer {
            background-color: var(--base-palette-4-$(val));

            & .disabled-link {
                color: var(--base-palette-2-$(val));
            }
        }

        & .bar {
            border-left: 1px solid var(--base-palette-3-$(val));
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) {
        & .data-table-header {
            color: var(--white-$(val));

            & .svg-container svg {
                fill: var(--white-$(val));
            }
        }

        & .data-table-row-selected {
            background-color: var(--clickable-$(val));
            color: var(--white-$(val));

            & .svg-container svg {
                fill: var(--white-$(val));
            }
        }

        & .data-table-footer {
            font-weight: bold;

            & .disabled-link {
                color: var(--base-palette-3-$(val));
                font-weight: normal;
            }
        }
    }
}
