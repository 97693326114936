@media screen and (max-width: 1240px) {
    .section-container .form-section .form-sub-section {
        & .address-street-one.form-input {
            width: auto;
        }

        & .address-type.select-form-input-container {
            width: 50%;
        }

        & .checkbox-div.address-is-primary {
            margin-left: 0px;
            margin-top: 12px;
            justify-content: flex-start;
        }
    }
}
