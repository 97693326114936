@import "../../../../../theme/css-variables/master-variables.css";

.drag-and-drop-file-uploader-container .files-list-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 0 15px;
    min-height: 150px;

    & .files-list-header {
        display: flex;
        flex-direction: row;

        & .files-count {
            margin-right: 5px;
            padding: 1px 7px;
            border-radius: 5px;
        }
        & .clear-link {
            flex-grow: 1;
            margin-left: 20px;
        }
    }

    & .files-list-body {
        flex-grow: 1;

        & .drag-and-drop-file-uploader-accordion-item {
            display: flex;
            flex-direction: row;
            white-space: nowrap;
            vertical-align: center;
            height: 100%;
            cursor: default;

            & .remove-icon .default-svg-icon {
                margin-left: 2px;
                margin-right: 2px;
                width: 17px;
                height: 17px;
                cursor: pointer;
            }
            & .upload-filename-errs {
                flex-grow: 1;
                height: 100%;
                padding-top: 2px;
                overflow: hidden;

                & div {
                    height: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            & .upload-filesize {
                padding: 1px 7px 0 7px;
                height: 100%;
            }
        }
    }

    & hr {
        width: 100%;
    }
}

@each $val in var(--theme-array) {
    .$(val) .drag-and-drop-file-uploader-container .files-list-container {
        & .files-count {
            background-color: var(--attention-$(val));
            color: var(--white-$(val));
        }
        & .drag-and-drop-file-uploader-accordion-item {
            background-color: var(--base-palette-1-$(val));
            &.sticky {
                border-bottom: var(--base-palette-2-$(val)) 1px solid;
            }
            & .upload-file-error {
                color: var(--error-$(val));
            }
        }
    }
}
