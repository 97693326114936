@import "../../../../theme/css-variables/master-variables.css";

.simple-table {
    & .ReactVirtualized__Table__headerRow,
    & .ReactVirtualized__Table__row {
        box-sizing: border-box;
        cursor: pointer;
        line-height: normal;
    }

    & .ReactVirtualized__Table__headerColumn > span {
        vertical-align: text-bottom;
    }

    & .ReactVirtualized__Table__headerRow {
        text-transform: none;
        font-weight: 300;
        font-size: 14px;
    }
}

@each $val in var(--theme-array) {
    .$(val) .simple-table {
        & .ReactVirtualized__Table__headerRow,
        & .ReactVirtualized__Table__row:hover {
            background-color: var(--base-palette-4-$(val));
        }

        & .ReactVirtualized__Table__headerRow {
            border-bottom: 1px solid var(--base-palette-1-$(val));
        }

        & .row-selected {
            background-color: var(--clickable-$(val)) !important;
        }
    }
}
