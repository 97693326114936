@import "../../../../../theme/css-variables/master-variables.css";

.analysis-info-bucket-header {
    padding-bottom: 5px;
}

.analysis-form-input {
    max-width: 180px;
    min-width: 164px;
}

.analysis-input {
    margin-left: 7px;
    height: 40px;
    & .checkbox-label {
        height: 40px;
    }
}

.analysis-error-message {
    padding: 10px;
}

.analysis-yield-label-cont {
    display: flex;
    flex-direction: column;
}

.analysis-yield-label {
    color: var(--base-palette-3-default);
}

@each $val in var(--theme-array) {
    .$(val) {
        & .analysis-error-message {
            background-color: var(--error-$(val));
        }
    }
}

.event-count {
    padding-top: 10px;
    padding-left: 15px;
    padding-bottom: 10px;
}

.seasons {
    padding-left: 15px;
}
