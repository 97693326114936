@import "../../../theme/css-variables/master-variables.css";

.auto-search-input {
    & .search-input {
        & .form-input {
            height: auto;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .auto-search-options {
            position: absolute;
            display: flex;
            flex-direction: column;
            max-height: 300px;
            min-width: 100%;
            background-color: var(--base-palette-3-$(val));
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            color: var(--base-palette-1-$(val));
            z-index: 1;
            overflow-y: auto;

            & .autosearch-list-item {
                padding: 5px 5px 5px 10px;
                border-bottom: 1px solid var(--base-palette-1-$(val));

                & svg {
                    margin-top: 5px;
                    fill: var(--base-palette-1-$(val));
                }

                cursor: pointer;
            }

            & .autosearch-list-item:hover {
                background-color: var(--clickable-$(val));
            }

            & .autosearch-list-content {
                font-size: 13px;

                & .autosearch-sub-item {
                    font-size: 12px;
                    font-style: italic;
                }

                & div {
                    padding-top: 5px;
                }
            }
        }
    }
}
