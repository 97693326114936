.form-sub-section {
    & .address-type {
        width: 180px;
    }
    & .address-street-one.form-input {
        width: 100%;
    }
    & .address-state {
        width: 80px;
    }
    & .address-city {
        width: 160px;
    }
    & .address-city.form-input-error {
        height: 39px;
    }
    & .address-zip-code {
        width: 110px;
    }
    & .address-is-primary {
        margin: auto;
        display: flex;
        width: 100px;
        justify-content: flex-end;
        margin-right: 0px;
    }
}
.address-header {
    & .form-section-header,
    & .form-section-children {
        margin-bottom: 5px;
    }
}
