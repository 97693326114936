@import "../../../../../../../../theme/css-variables/master-variables.css";

.event-sample-soil-form .dialog-container.trace-points-modal {
    & .dialog-box-body {
        overflow: visible;
        margin: 0;
        padding: 0;

        & > div {
            display: flex;
            flex-direction: column;
            margin: 15px 20px;

            & .trace-progress-options {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            & .trace-instructions {
                padding-top: 10px;
                font-style: italic;
            }
        }
    }
}
