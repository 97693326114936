.analysis-layer-profit-loss-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
}

.analysis-layer-profit-loss-radio-button {
    margin: auto;
    margin-left: 3px;
}

.analysis-layer-profit-loss-input {
    width: auto;
    display: inline-block;
}

.detailed-link {
    height: 100px;
    padding-top: 5px;
    padding-left: 218px;
}
