.notification-msg .toastify__body {
    line-height: 20px;

    & .api-error-notification {
        & .err-msg {
            min-height: 48px;
        }

        & .action-links {
            margin-top: 4px;
            & * {
                padding: 0 4px;
            }
            & :first-child {
                padding-left: 0;
            }
        }
    }
}

.api-error-notification,
.notifications-err-dialog {
    & ul {
        list-style: disc !important;

        & li {
            margin-left: 17px;
        }
    }
}
