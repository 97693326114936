.customer-main-container {
    & .slide-content-container {
        display: block;
    }

    & .community-data-section {
        display: flex;
        margin: 10px 0px 5px 0px;
        width: 50%;
    }

    & .customer-info-container {
        & .customer-name-section {
            width: 300px;
        }

        & .checkbox-div {
            width: 20%;
        }
    }

    & .equipment-grid {
        font-size: 13px;

        & .data-header-cell {
            width: 24%;

            &:last-child {
                padding-right: 15px;
            }

            & .data-table-cell {
                width: 75%;
            }
        }

        & .data-table-cell {
            width: 25%;
        }
    }

    & .add-equipment-link {
        margin: 10px 0px 0px 0px;
    }

    & .alias-name-container .alias-name.form-input {
        width: 100%;
    }

    & .enrollment-container {
        width: 100%;

        & .enrollment-auto {
            color: #8fa61a;
            font-size: 25px;
            margin-top: 2px;
        }

        & .enrollment-input {
            width: 50%;
        }

        & .enrollment-checkbox {
            width: 90px;
            margin-left: 5px;
        }

        & .enrollment-form-link {
            font-size: 13px;
            margin: auto;
            line-height: 27px;
            padding-left: 3px;

            &:hover {
                & .context-menu .menu-dot {
                    visibility: visible;
                }
            }

            & .context-menu-container {
                float: right;
                margin-left: 5px;

                & .menu-dot {
                    visibility: hidden;
                }
            }
        }

        & .enrollment-form-add {
            font-size: 13px;
            margin: auto;
            margin-left: 3px;
            line-height: 27px;
        }
    }
    & .enrollment-button {
        width: 44%;
        max-width: 200px;
        margin-top: 0px;
        height: 45px;
    }

    & .auto-search-list-container.additional-agvance-customer .auto-search {
        margin-right: 0;
    }

    & .agvance-search-loading-container {
        position: relative;
        min-width: 68px;
        left: 4px;

        & .loader-container {
            position: relative;
            margin-bottom: 4px;
        }

        & .loader-container > div {
            background-color: transparent;
        }

        & .line-scale-party > div {
            background-color: #404040;
            height: 20px;
        }
    }
}
