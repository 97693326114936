@import "../../../theme/css-variables/master-variables.css";

.default-button {
    min-width: 100px;
    height: 34px;
    max-width: 150px;
    border-radius: 4px;
    border: none;
    text-align: center;
    display: inline-block;
    margin: 2px 3px;
    cursor: pointer;
    padding: 1px 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-family: "open_sansregular";
    font-size: 14px;
    font-weight: 300;
}
.wide-button {
    max-width: 100%;
}

.default-button:disabled {
    cursor: default;
    opacity: 0.6;
}

@each $val in var(--theme-array) {
    .$(val) {
        & .default-button {
            background-color: var(--clickable-$(val));
            border: 1px solid var(--clickable-$(val));
            color: var(--white-$(val));
        }

        & .default-button:focus,
        & .cancel-button:focus {
            border: 1px solid var(--highlight-$(val));
            outline: none;
        }

        & .cancel-button {
            background-color: var(--base-palette-5-$(val));
            color: var(--white-$(val));
            border: 1px solid var(--base-palette-5-$(val));
        }
    }
}
