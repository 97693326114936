@import "../../../../../../../theme/css-variables/master-variables.css";
.non-field-feature-information-detail-container {
    padding-top: 16px;
    flex: 1;
    width: 84%;
    overflow: auto;
    & .non-field-feature-information-detail {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;

        & .top-row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        & .feature-type .form-input,
        & .field-selection .form-input {
            margin-right: 3px;
            margin-bottom: 8px;
        }

        & .field-selection .form-input {
            margin-top: 0;
        }

        & .feature-name {
            margin-left: 3px;
            margin-bottom: 8px;
        }

        & .private-checkbox {
            margin-left: 10px;
        }

        & .description {
            margin-top: 0;
            height: 100px;
        }

        & .extent-checkbox {
            margin-top: 10px;
            margin-left: 3px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .non-field-feature-information-detail-container {
        background-color: var(--base-palette-4-$(val));
        & .label-error {
            color: var(--error-$(val));
        }

        & .non-field-feature-information-detail {
            & .bucket-arrow-icon {
                fill: var(--white-black-toggle-$(val));
            }
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) .non-field-feature-information-detail-container {
        background-color: var(--base-palette-6-$(val));
    }
}
