@import "../../../../theme/css-variables/master-variables.css";

.rec-event-info .tabs.information-tabs > .tabs-pane {
    & .rec-event-info-form .bucket.crop-info-section-bucket {
        margin-top: 0px;
    }

    & .event-planting-info-form .rec-event-info-form {
        flex: 1 0;
    }
}

.event-planting-info-form {
    overflow-y: auto;
    flex: 1 0;
    display: flex;
    flex-direction: column;

    & .field-table-container {
        border-bottom: 0.5px dashed;
    }

    & .zone-info-heading {
        display: flex;
        margin: 15px 5px 8px 5px;
        font-size: 14px;
        font-weight: 300;

        & .zone-size {
            margin-left: 5px;
        }
    }
    & .crop-unit-table {
        & .crop-unit-header {
            display: flex;
            height: 20px;
            font-weight: bold;
            font-family: "open_sansitalic";
            font-size: 14px;
            padding: 0px 5px 5px 5px;
        }
        & .crop-unit-row {
            height: 15px;
            margin: 5px 5px;
            padding: 5px 5px;
            display: flex;
            flex-wrap: nowrap;
        }

        & .crop-section {
            width: 225px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        & .section-label {
            padding-left: 10px;
            min-width: 50px;
            max-width: 60px;
        }
        & .section-label-header {
            text-align: center;
            max-width: 60px;
        }

        & .unit-section {
            flex-grow: 0.5;
        }
    }

    & .total-cost-container {
        float: right;
        width: auto;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-weight: 300;

        & .total-cost-items {
            text-align: end;
            padding-right: 7px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .event-planting-info-form {
        background-color: var(--base-palette-4-$(val));
    }
    .$(val) .crop-unit-header-border {
        border-bottom: 1px solid var(--base-palette-3-$(val));
    }
    .$(val) .crop-unit-table {
        border-bottom: 1px solid var(--base-palette-3-$(val));
    }
}

@each $val in var(--sky-array) {
    .$(val) .event-planting-info-form {
        background-color: var(--base-palette-6-$(val));
    }
    .$(val) .crop-unit-header-border {
        border-bottom: 1px solid var(--base-palette-3-$(val));
    }
    .$(val) .crop-unit-table {
        border-bottom: 1px solid var(--base-palette-3-$(val));
    }
}
