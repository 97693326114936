@import "../../../../../../theme/css-variables/master-variables.css";

.event-module .legend-accordion-item,
.layer-module .legend-accordion-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: default;

    & .legend-icon {
        margin-left: 66px;
    }

    & .legend-label.legend-label-hover {
        left: 86px;
    }
}

@each $val in var(--theme-array) {
    .$(val) .layer-module .legend-accordion-item {
        background-color: var(--shadow-$(val));
    }
}
