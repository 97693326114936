@import "../../../../../../theme/css-variables/master-variables.css";

.layer-module {
    & .customer-item {
        display: flex;
        align-items: center;
        height: 54px;
        cursor: pointer;

        & .customer-name {
            padding-left: 20px;
            font-size: 15px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .layer-module {
        & .customer-item {
            background-color: var(--base-palette-1-$(val));
            border-top: 1px solid var(--base-palette-3-$(val));

            &.is-not-expanded {
                border-bottom: 1px solid var(--base-palette-3-$(val));
            }

            &:hover {
                background-color: var(--base-palette-4-$(val));
            }
        }
    }
}
