.export-controller-file-modal .row {
    display: flex;

    & .checkboxes {
        flex-grow: 1;
        padding-top: 6px;

        & .checkbox-div {
            margin: 2px 10px;
        }
    }

    & .select-form-input-container {
        width: 236px;
    }
}

.validation-message {
    margin: 8px 0px;
}
