@import "../../../../../../../theme/css-variables/master-variables.css";
.information-menu {
    min-width: 60px;
    height: 100%;
    display: inline-block;

    & .information-menu-tab {
        height: 80px;
        width: 100%;
        cursor: pointer;

        & .information-menu-tab-text {
            font-size: 10pt;
            text-align: center;
            width: 100%;
            user-select: none;
        }
    }

    & .side-menu-icon {
        height: 37px;
        width: 37px;
        margin: 11px 11px 7px;
    }
}

@each $val in var(--theme-array) {
    .$(val) .information-menu {
        background-color: var(--base-palette-1-$(val));

        & .active-tab {
            background-color: var(--base-palette-4-$(val));
            color: var(--highlight-$(val));

            & .side-menu-icon {
                fill: var(--highlight-$(val));
            }
        }

        & .general-tab.error {
            color: var(--error-$(val));

            & .side-menu-icon {
                fill: var(--error-$(val));
            }
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) .information-menu .active-tab {
        background-color: var(--base-palette-6-$(val));
    }
}
