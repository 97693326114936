@import "../../../../../../../../theme/css-variables/master-variables.css";

.import-append-modal {
    & .append-list-section {
        margin-bottom: 18px;
        min-height: 150px;
        & .append-event-list-title {
            font-size: 13px;
            padding-bottom: 3px;
        }
        & .append-row {
            display: flex;
            flex-flow: row nowrap;
            font-size: 12px;
            text-align: center;
            padding-top: 1px;
            padding-bottom: 1px;
            cursor: pointer;
            line-height: 16px;

            & .append-cell {
                min-width: 120px;
                max-width: 120px;
                width: 120px;
                padding-left: 5px;
                padding-right: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        & .append-header-row {
            border-bottom: 1px solid;
            & .append-cell {
                font-weight: bold;
            }
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .selected-row {
            background-color: var(--highlight-$(val));
        }
        & .error-text {
            color: var(--error-$(val));
        }
    }
}
