@import "../../../../theme/css-variables/master-variables.css";

.linked-map-div {
    flex-grow: 1;

    & .esri-popup__main-container {
        width: 9.5em;

        & .esri-popup__header {
            display: none;
        }

        & .esri-popup__footer {
            display: none;
        }

        & .esri-popup__content {
            width: 9em;
            margin-top: 0.5em;
        }
    }
    & .esri-popup__pointer {
        display: none;
    }
}

@each $val in var(--theme-array) {
    .$(val) .linked-map-div {
        & .esri-popup__main-container {
            background-color: var(--base-palette-1-$(val));
            color: var(--white-black-toggle-$(val));
        }
    }
}
