@import "../../../../theme/css-variables/master-variables.css";

.report-book-creation-container-spacer {
    display: flex;
    justify-content: center;

    & .report-book-creation-container {
        display: flex;
        padding: 0 5px 5px 5px;
        width: 1240px;

        & .report-book-name-and-active-container {
            display: flex;
            align-items: center;

            & .name-options-location-container {
                display: flex;
                flex-direction: column;
            }

            & .report-book-name {
                width: 395px;
                margin-left: 0;
                margin-right: 20px;
            }
        }

        & .cover-page-section {
            display: flex;
            justify-content: space-between;
            position: relative;

            & .checkbox-div {
                width: fit-content;
            }

            & .loader-container {
                position: absolute;
                top: -10px;
            }
        }

        & .cover-page-info {
            display: flex;
            align-items: center;
            flex: 1 1 auto;
            justify-content: flex-end;

            & a {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 255px;
                line-height: 20px;
                padding: 0 5px 5px 0;
                font-size: 13px;
            }

            & button {
                min-width: 38px;
                margin-bottom: 5px;

                & .svg-container {
                    padding-top: 2px;
                }
            }
        }

        & .report-book-options {
            display: flex;
            flex-direction: column;

            & .options {
                &.form-section {
                    width: 405px;
                }

                & .form-sub-section {
                    flex-direction: column;
                }

                & .report-book-memo {
                    width: 395px;
                }
            }

            & .location-container {
                display: flex;

                & .form-section {
                    width: 405px;
                }

                & .location-selection-container {
                    display: flex;
                    flex-direction: column;
                    margin-right: 10px;
                    & .select-form-input-container {
                        width: 395px;
                    }
                }
            }
        }

        & .report-types-and-configurations-container {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            margin-top: 10px;

            & .form-section {
                width: 955px;

                & .data-table-header {
                    & .data-table-container {
                        max-width: 50px;
                    }
                }

                & .data-table-rows {
                    max-height: 650px;
                }

                & .no-arrow {
                    max-width: 50px;
                }

                & .grid-col-5 {
                    max-width: 25px;
                }

                & .grid-col-40 {
                    max-width: 385px;
                }
            }

            & .reorder-button {
                cursor: pointer;

                & .arrow-icon {
                    height: 15px;
                    width: 15px;
                }
            }
        }

        & .dialog-window {
            & .report-type-and-configuration-modal {
                & .dialog-box-children {
                    display: flex;
                    flex-direction: row;
                }

                & .dialog-box-footer {
                    & .default-button {
                        max-width: 155px;
                    }
                }
            }

            & .report-book-report-options-modal {
                min-width: 400px;

                & .dialog-box-body {
                    max-height: unset;

                    & .dialog-box-children {
                        & .reports-options-container {
                            flex-direction: column;
                            border-bottom: unset;

                            & > .text-form-input {
                                margin-left: 15px;
                            }

                            & > .text-form-input ~ .text-form-input {
                                margin-left: 10px;
                            }

                            & .report-options {
                                overflow-y: unset;
                            }
                        }
                    }
                }
            }
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .default-button svg {
        fill: var(--white-$(val));
    }
}
