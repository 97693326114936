.import-zones-modal-form {
    & .inputs {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        & .select-form-input-container {
            width: 250px;
        }

        & .checkbox-input {
            margin-top: 10px;
            width: 150px;
        }
    }

    & .form-input {
        margin: 20px 0px 10px 0px;
    }

    & .nbr-zones-msg {
        min-height: 28px;

        &.error {
            color: red;
            font-weight: bold;
        }
    }
}
