@import "../../../../../../../theme/css-variables/master-variables.css";

.import-wizard-panel {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    justify-content: space-between;
    height: calc(100% - 165px);

    & .add-customer {
        cursor: pointer;
        height: 100%;
        text-align: start;
        line-height: 45px;
        margin-left: 5px;
    }

    & .files-panel {
        width: 100%;
        flex: 1;
        height: 10px;
        overflow: auto;
    }

    & .edit-modal-container {
        & .dialog-window {
            position: absolute !important;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        & .edit-field-modal-body-message {
            margin-top: 15px;
            margin-bottom: 15px;
            margin-left: 10px;
        }

        & .edit-field-modal-footer {
            font-size: 10px;
            text-align: right;
        }

        & .modal-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        & .dialog-container {
            position: fixed !important;
        }

        & .edit-field-area-warning {
            margin-left: 40%;
        }
    }

    & .lock-icon {
        height: 35px;
        width: 45px;
        padding-left: 200px;
        padding-top: 50px;
    }

    & .lock-icon-text {
        padding-top: 20px;
        text-align: center;
    }

    & .fields-panel,
    & .events-panel {
        display: flex;
        flex-flow: column nowrap;
        height: 10px;
        flex: 1;

        & .fields-section,
        & .events-section {
            overflow-y: auto;
            overflow-x: hidden;
            flex-grow: 1;
            height: 10px;
        }

        & .events-single-step-section {
            flex-grow: 1;
            height: 10px;
            & .bucket {
                margin: 0;
                padding-left: 0px;
                text-align: left;
                display: flex;
                flex-flow: column nowrap;
                flex-grow: 1;
                height: 100%;

                & .bucket-header {
                    padding-bottom: 5px;
                    padding-top: 10px;
                }

                & .bucket-content {
                    display: block;
                    overflow-y: auto;
                    overflow-x: hidden;
                }
            }
        }

        & .import-field-section-bucket {
            margin: 0;
            padding: 0;
            min-height: 30px;

            & .fields-section-header {
                padding-left: 15px;
            }

            & .import-field-section-header {
                height: 30px;
                font-size: 13px;
                line-height: 30px;

                & .create-events-header {
                    padding-left: 15px;
                }
            }

            & .field-section-content {
                display: flex;
                flex-flow: column;
                width: 100%;

                & .ifb-row {
                    font-size: 15px;
                    width: 100%;

                    & .field-selected-column {
                        width: 6px;
                        padding-left: 15px;
                        padding-right: 10px;
                        vertical-align: middle;
                        padding-top: 9px;

                        & .field-selected-div {
                            width: 5px;
                            height: 35px;
                        }
                    }

                    & .ifb-strip {
                        display: flex;
                        justify-content: space-between;
                        font-size: 15px;
                        width: 100%;
                        height: 54px;
                        min-height: 54px;
                        max-height: 54px;
                        line-height: 54px;

                        & .field-strip-content {
                            display: flex;
                            flex-flow: column nowrap;
                            flex: 1;
                            justify-content: flex-start;

                            & .field-strip-content-row {
                                display: flex;
                                flex-direction: row;

                                & > div {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }

                                & .farm-field-text,
                                & .customer-name {
                                    flex: 1;
                                    width: 100px;
                                }

                                & .feature-count,
                                & .season {
                                    padding: 0 10px;
                                    text-align: right;
                                }

                                & .farm-field-text,
                                & .season {
                                    line-height: 30px;
                                }

                                & .customer-name,
                                & .feature-count {
                                    line-height: 17px;
                                }

                                & .farm-field-text {
                                    font-size: 13px;
                                }

                                & .customer-name,
                                & .feature-count {
                                    font-size: 12px;
                                }

                                & .season {
                                    font-size: 11px;
                                }
                            }
                        }

                        & .field-strip-content-cols {
                            display: flex;
                            flex-direction: row;
                            flex: 1;
                            justify-content: flex-start;

                            & .field-strip-content-section {
                                display: flex;
                                flex-direction: column;

                                & > div {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }

                                &:first-child {
                                    flex: 1;
                                    width: 100px;
                                }

                                & .farm-field-text {
                                    line-height: 30px;
                                    font-size: 13px;
                                }

                                & .customer-name {
                                    line-height: 15px;
                                    font-size: 12px;
                                }

                                & .feature-count {
                                    font-size: 12px;
                                    line-height: 55px;
                                    text-align: right;
                                    padding: 0 10px;
                                }
                            }
                        }
                    }

                    & .ifb-strip:hover {
                        cursor: pointer;
                    }

                    & .field-context-menu.menu-dots {
                        margin-top: 8px;
                        margin-right: 10px;
                    }

                    & .file-strip {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-around;
                        font-size: 12px;
                        width: 100%;
                        height: 46px;
                        line-height: 46px;

                        & .field-selected-column {
                            padding-left: 30px;

                            & .field-selected-div {
                                height: 28px;
                            }
                        }

                        & .field-context-menu.menu-dots {
                            margin-top: 8px;
                        }

                        & .field-context-menu {
                            & .menu-item {
                                font-size: 12px;
                            }
                        }

                        & .file-name {
                            flex: 1;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }

                        & .file-feature-count {
                            font-size: 12px;
                            padding: 0 10px;
                            line-height: 45px;
                            text-align: right;
                        }
                    }
                }
            }
        }

        & .field-checkbox-options {
            display: flex;
        }

        & .checkbox-div {
            margin: 10px 0 3px 15px;
            width: 96%;
        }
    }

    & .field-boundary-panel {
        display: flex;
        flex-flow: column nowrap;
        flex-grow: 1;
        height: 10px;

        & .field-boundary-context-menu {
            & .menu-items-drop-down .menu-item a {
                font-size: 12px;
            }

            &.menu-dots {
                margin-top: 10px;
            }
        }

        & .ignore-farm-checkbox {
            padding: 10px;
        }

        & .bucket {
            margin: 0;
            padding-left: 0px;
            text-align: left;
            display: flex;
            flex-flow: column nowrap;
            flex-grow: 1;

            & .bucket-content {
                display: block;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }

        & .import-field-boundary-header {
            display: flex;
            justify-content: space-between;
            min-height: 20px;
        }

        & .column {
            width: 72px;
            padding-right: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 13px;
        }

        & .column1 {
            width: 130px;
            padding-right: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 13px;
        }

        & .acres-column {
            width: 50px;
            padding-right: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 13px;
        }

        & .space {
            width: 30px;
        }

        & .ifb-row {
            font-size: 15px;
            width: 100%;
            min-height: 55px;

            & .ifb-strip {
                display: flex;
                justify-content: space-between;
                font-size: 15px;
                width: 100%;
                height: 54px;
                min-height: 54px;
                max-height: 54px;
                line-height: 54px;

                & .field-selected-column {
                    width: 6px;
                    vertical-align: middle;
                    padding: 12px 10px 0 22px;

                    & .field-selected-div {
                        width: 5px;
                        height: 30px;
                    }
                }
            }

            & .ifb-strip:hover {
                cursor: pointer;
            }

            & .ifb-file {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                min-height: 40px;
                padding-left: 40px;
                margin-top: 5px;
                width: calc(100% - 40px);

                & .ifb-file-name {
                    width: 80%;
                    font-size: 12px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    line-height: 40px;
                }
            }
        }
        & .ifb-row.selected {
            min-height: 101px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .import-wizard-panel {
            background-color: var(--base-palette-4-$(val));

            & .fields-panel,
            & .events-panel {
                & .import-field-section-header {
                    background-color: var(--base-palette-1-$(val));
                    border-top: 1px solid var(--base-palette-4-$(val));
                    border-bottom: 1px solid var(--base-palette-4-$(val));
                }

                & .create-events-section-header {
                    background-color: var(--base-palette-4-$(val));
                    border-top: 1px solid var(--base-palette-4-$(val));
                    border-bottom: 1px solid var(--base-palette-4-$(val));
                }

                & .ifb-strip {
                    background-color: var(--base-palette-1-$(val));
                    border-top: 1px solid var(--base-palette-3-$(val));
                    border-bottom: 1px solid var(--base-palette-3-$(val));

                    & .field-context-menu:not(.menu-expanded) {
                        & .menu-dot {
                            color: var(--base-palette-1-$(val));
                        }
                    }

                    & .field-context-menu:hover,
                    & .field-context-menu.menu-expanded {
                        background-color: var(--base-palette-4-$(val));

                        & .menu-dot {
                            color: var(--highlight-$(val));
                        }
                    }
                }

                & .field-selected-column {
                    & .field-selected-div {
                        background-color: var(--base-palette-3-$(val));
                    }
                }

                & .ifb-strip:hover,
                & .file-strip:hover {
                    & .field-context-menu {
                        & .menu-dot {
                            color: var(--highlight-$(val));
                        }
                    }
                }

                & .file-strip:hover {
                    background-color: var(--shadow-$(val));
                    cursor: pointer;
                }

                & .selected {
                    background-color: var(--base-palette-2-$(val));

                    & .farm-field-text,
                    & .feature-count {
                        color: var(--wizard-selected-$(val));
                    }

                    & .ifb-strip {
                        & .field-selected-div {
                            background-color: var(--highlight-$(val)) !important;
                        }
                    }
                }

                & .nomatch {
                    & .farm-field-text,
                    & .feature-count {
                        color: var(--wizard-nomatch-$(val));
                    }
                }

                & .matched {
                    & .farm-field-text,
                    & .feature-count,
                    & .customer-column,
                    & .farm-field-column,
                    & .season-column {
                        color: var(--wizard-matched-$(val));
                    }
                }

                & .file-strip {
                    background-color: var(--base-palette-4-$(val));
                    border-bottom: 1px solid var(--base-palette-3-$(val));

                    & .field-context-menu:not(.menu-expanded) {
                        & .menu-dot {
                            color: var(--base-palette-4-$(val));
                        }
                    }

                    & .field-context-menu:hover,
                    & .field-context-menu.menu-expanded {
                        background-color: var(--base-palette-1-$(val));

                        & .menu-dot {
                            color: var(--highlight-$(val));
                        }
                    }
                }
                & .import-event-single-step-header {
                    display: flex;
                    justify-content: space-between;
                    min-height: 20px;
                }

                & .column,
                & .farm-field-column {
                    width: 72px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }

                & .customer-column {
                    width: 130px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }

                & .season-column {
                    width: 50px;
                    padding-right: 0px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }

                & .space {
                    width: 13px;
                }
            }

            & .field-boundary-panel {
                & .ifb-strip {
                    background-color: var(--base-palette-1-$(val));
                    border-top: 1px solid var(--base-palette-3-$(val));
                    border-bottom: 1px solid var(--base-palette-3-$(val));

                    & .field-selected-column {
                        & .field-selected-div {
                            background-color: var(--base-palette-3-$(val));
                        }
                    }
                }

                & .ifb-file {
                    border-bottom: 1px solid var(--base-palette-3-$(val));
                }

                & .ifb-strip.selected:not(:hover) {
                    & .field-boundary-context-menu:not(.menu-expanded) {
                        & .menu-dot {
                            color: var(--base-palette-2-$(val));
                        }
                    }
                }
                & .ifb-strip:not(.selected):not(:hover) {
                    & .field-boundary-context-menu:not(.menu-expanded) {
                        & .menu-dot {
                            color: var(--base-palette-1-$(val));
                        }
                    }
                }

                & .field-boundary-context-menu:hover,
                & .field-boundary-context-menu.menu-expanded {
                    background-color: var(--base-palette-4-$(val));

                    & .menu-dot {
                        color: var(--highlight-$(val));
                    }
                }

                & .ifb-strip:hover {
                    background-color: var(--shadow-$(val));

                    & .field-boundary-context-menu {
                        & .menu-dot {
                            color: var(--highlight-$(val));
                        }
                    }
                }

                & .ifb-strip.spatial-conflict {
                    color: var(--caution-$(val));
                }

                & .ifb-strip.no-match {
                    color: var(--error-$(val));
                }

                & .ifb-strip.editing {
                    color: var(--attention-$(val));
                }

                & .ifb-strip.selected {
                    background-color: var(--base-palette-2-$(val));

                    & .field-selected-div {
                        background-color: var(--highlight-$(val));
                    }
                }
            }
        }
    }
}
