@import "../../../theme/css-variables/master-variables.css";

.entity-container {
    display: flex;
    height: 21px;
    width: 521px;
    padding: 5px 0px 0px 5px;

    & span {
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    & .checkbox-div {
        width: 30px;
        margin-top: 0px;
        margin-right: 5px;
        margin-bottom: 0px;
    }
}

.content-zapper-container {
    display: flex;
    width: 1240px;
    margin: auto;
}

.batch-zapper-tabs {
    max-width: 100%;
    display: inline-block;
    width: 394px;
    padding-top: 5px;

    & .tab-label {
        line-height: 40px;
        width: 200px;
    }
}

.batch-zapper-container {
    margin: auto;
    margin-top: 10px;
    width: 1240px;

    & .select-company-container {
        width: 100%;
        margin-bottom: 15px;

        & .select-form-input-container {
            margin-top: 15px;
            width: 77%;
        }

        & .tree-view-container {
            font-size: 13px;
        }
    }

    & .use-only-cont {
        padding-top: 50px;
    }

    & .location-entity-container {
        & .tree-view-container {
            margin-top: 15px;

            & .tree-node-container {
                & .label,
                & .expand-icon {
                    font-size: 13px;
                    line-height: 13px;
                    padding-top: 4px;
                }
            }
        }
    }

    & .batch-zapper-entities {
        margin-top: 15px;
        margin-left: 30px;

        & span {
            font-family: "open_sansregular";
            font-size: 13px;
            font-weight: 300;
        }
    }

    & .section-container {
        font-size: 13px;
    }

    & .select-form-sub-header {
        font-weight: bold;
        cursor: default;
        height: 50%;
        display: flex;
        align-items: center;
        padding-top: 5px;
        margin-left: 5px;
    }

    & .batch-zapper-attribute.select-form-input-container {
        width: 384px;
    }

    & .entity-search {
        & .form-input {
            padding-bottom: 5px;
            width: 300px;
        }
    }

    & .hierarchy-filter-container {
        width: 100%;

        & .auto-search {
            & .select-form-input-container {
                padding-top: 5px;
                width: 290px;
            }
        }

        & .select-form-input-container {
            width: 290px;
        }
    }
}

.batch-zapper-main-container .slide-content-container {
    display: block;
}

.batch-zapper-attribute-search {
    padding-top: 15px;
    padding-bottom: 25px;
}

.batch-zapper-use-only {
    position: absolute;
    margin-left: 220px;
    top: 340px;
}

@each $val in var(--theme-array) {
    .$(val) .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid var(--base-palette-4-$(val));
    }
}
