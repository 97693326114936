@import "../../theme/css-variables/master-variables.css";

.notifications-main-container {
    overflow-x: auto;
    width: 100%;
    padding-top: 13px;

    & .bucket {
        width: calc(100% - 5px);
        min-height: 15px;
        margin-top: 2px;
        margin-bottom: 9px;

        & .bucket-content {
            & .list-item {
                font-size: 13px;
                margin-left: 30px;
                padding-top: 5px;
            }

            & .report-list {
                display: flex;
                flex-direction: column;

                & .click-me-link {
                    padding-top: 4px;
                }
            }
        }
    }

    & .notification-bucket-header {
        height: 40px;
        padding-left: 10px;
        font-family: "open_sansregular";
        font-size: 14px;
        font-weight: 500;
        vertical-align: middle;
        line-height: 40px;
        margin-bottom: 10px;
        max-width: 400px;

        & .bucket-symbol {
            float: right;
        }

        & .time-bucket-title {
            display: flex;
        }
    }

    & .list-bucket-header {
        height: 15px;
        font-size: 13px;
    }

    @each $val in var(--theme-array) {
        .$(val) & .new-tag {
            background-color: var(--highlight-$(val));
            color: #000;
            font-size: 13px;
            height: 17px;
            margin-left: 8px;
            padding-top: 3px;
            width: 100px;
            text-align: center;
        }
    }
}
