@import "../../../theme/css-variables/master-variables.css";

@each $val in var(--theme-array) {
    .$(val) {
        & a.form-input-error {
            color: var(--error-$(val)) !important;
            border: none !important;
        }
    }
}
