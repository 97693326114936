@import "../../../../../../../../../theme/css-variables/master-variables.css";

.import-filter {
    margin: 6px 0 10px 15px;
    width: 93%;
    font-size: 12px;
    & .import-filter-title {
        width: 100%;
        margin: 0;
        padding-top: 4px;
        padding-bottom: 3px;
        font-size: 13px;
    }
    & .import-filter-header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding-top: 4px;
        padding-bottom: 4px;
        font-weight: 700;
    }
    & .import-filter-body {
        min-height: 112px;
        max-height: 112px;
        height: 112px;
        overflow: auto;

        & .import-cell-text {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    & .import-filter-item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        line-height: 23px;
        cursor: pointer;

        & .filter-link-container {
            text-align: center;
            & .filter-link:link,
            & .filter-link:hover,
            & .filter-link:visited {
                margin-right: 25px;
            }
        }
        & .filter-link-container.import-cell-10 {
            & .filter-link:link,
            & .filter-link:hover,
            & .filter-link:visited {
                margin-right: 0;
            }
        }
    }
    & .import-filter-item,
    & .import-filter-header {
        & .import-filter-cell {
            text-align: center;
        }
    }
    & .import-cell-3 {
        width: 3%;
        min-width: 3%;
        max-width: 3%;
    }
    & .import-cell-10 {
        width: 7%;
        min-width: 7%;
        max-width: 7%;
    }
    & .import-cell-15 {
        width: 15%;
        min-width: 15%;
        max-width: 15%;
    }
    & .import-cell-20 {
        width: 20%;
        min-width: 20%;
        max-width: 20%;
    }
    & .import-cell-25 {
        width: 25%;
        min-width: 25%;
        max-width: 25%;
    }
    & .import-cell-30 {
        width: 30%;
        min-width: 30%;
        max-width: 30%;
    }
    & .import-cell-40 {
        width: 40%;
        min-width: 40%;
        max-width: 40%;
    }
    & .import-cell-45 {
        width: 45%;
        min-width: 45%;
        max-width: 45%;
    }
    & .import-cell-60 {
        width: 60%;
        min-width: 60%;
        max-width: 60%;
    }
    & .import-cell-70 {
        width: 70%;
        min-width: 70%;
        max-width: 70%;
    }
    & .context-menu {
        margin: 0px !important;
    }
    & .menu-dots {
        height: 22px;
        line-height: 13px;
        width: 12px;
        & .menu-dot {
            height: 4px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .import-filter {
            background-color: var(--black-white-toggle-$(val));
            color: var(--white-black-toggle-$(val));
            & .import-filter-title {
                background-color: var(--base-palette-4-$(val));
            }
            & .import-filter-header {
                border-bottom: 1px solid var(--highlight-$(val));

                & .required {
                    & .red-star {
                        color: var(--error-$(val));
                    }
                }
            }
            & .import-filter-item {
                & .filter-link-container {
                    & .filter-link:link,
                    & .filter-link:hover,
                    & .filter-link:visited {
                        color: var(--white-black-toggle-$(val));
                    }
                }
            }
            & .import-filter-item.selected {
                background-color: var(--highlight-$(val));
                & .import-filter-item-delete {
                    background-color: var(--highlight-$(val));
                }
                & .context-menu {
                    background-color: var(--highlight-$(val));
                }
                & .context-menu:hover {
                    background-color: var(--base-palette-1-$(val));
                }
            }
        }
        & .no-match {
            color: var(--error-$(val));
        }
        & .equipment-info-no-match {
            color: var(--white-$(val));
        }
    }
}
