.select-form-input-container {
    & .select-form-sub-header {
        font-weight: bold;
        cursor: default;
        height: 50%;
        display: flex;
        align-items: center;
        padding-top: 5px;
        margin-left: 5px;
    }
}
