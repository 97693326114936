.product-id-section {
    & span {
        margin: 2px 0;
    }
}
.manufacturer-section {
    padding-left: 5px;
}
.product-type-section {
    padding-left: 4px;
}
.nutrient-primary-checkbox {
    padding-left: 25px !important;
}
