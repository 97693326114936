@import "../../../../../../../../theme/css-variables/master-variables.css";

.rx-file-import-container {
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .drag-and-drop-file-uploader-container {
        height: 300px;
    }

    & .drop-message {
        flex-grow: 1;
        padding: 20px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border-style: dashed;
        border-width: 2px;

        & .default-svg-icon {
            height: 100px;
            width: 100px;
        }

        & .greyed-text,
        & a {
            font-family: open_sansregular;
            font-size: 14px;
            font-weight: 300;
            padding: 3px;
        }
    }
    .files-list-container {
        min-height: 300px;
    }
    .files-list-body {
        font-size: 12px;
    }
    .add-default-nolink {
        margin-left: 20px;
    }
    .default-button {
        margin-left: auto;
    }
    .product-info-section {
        justify-content: space-between;
        .rate-column-name-container {
            width: 200px;
        }
    }
    .rx-file-import-error {
        margin-top: 5px;
        color: red;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

@each $val in var(--theme-array) {
    .$(val) .rx-file-import-container {
        & .drop-message {
            border-color: var(--base-palette-3-$(val));

            & .greyed-text {
                color: var(--base-palette-2-$(val));
            }

            & .default-svg-icon {
                & #box {
                    fill: var(--base-palette-3-$(val));
                }
                & #arrow {
                    fill: var(--highlight-$(val));
                }
            }
        }
        .drag-and-drop-file-uploader-accordion-item {
            background-color: var(--base-palette-4-$(val)) !important;
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) .rx-file-import-container {
        .drag-and-drop-file-uploader-accordion-item {
            background-color: var(--base-palette-6-$(val)) !important;
        }
    }
}
