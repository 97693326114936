@import "../../../theme/css-variables/master-variables.css";
.hidden {
    visibility: hidden !important;
}

.import-export-header-container {
    display: flex;
    justify-content: flex-end;
    min-height: 18px;
    line-height: 18px;
    padding: 6px 0;
    width: 1240px;
    margin: auto;
    font-size: 13px;
    font-weight: 300;

    & .menu-items-text {
        padding: 0 5px;
    }

    & a.menu-items-text.last-header-link {
        padding-right: 0px;
    }

    & .bar {
        margin: 0 5px;
    }

    & .export-modal {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;

        & .dialog-box-title {
            justify-content: flex-end;
        }

        & .close-icon {
            text-align: right;
        }

        & span {
            text-align: center;
        }
    }

    & .product-import-header {
        & .hidden {
            height: 0px;
            width: 0px;
        }

        & .menu-items {
            margin: 0 5px;
        }
    }
}

.dialog-box-title {
    justify-content: flex-end;

    & .close-icon {
        text-align: right;
    }
}

.view-log-dialog-box {
    width: 600px;

    & .dialog-drag-bar {
        height: 35px;
    }

    & .dialog-box-body {
        margin-bottom: 0;

        & > div {
            width: auto;
        }
    }

    & .data-table-cont {
        width: 100%;

        & .data-table-header .data-header-cell {
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-wrap: none;
            overflow: hidden;
            padding: 3px 10px;
            display: flex;
            justify-content: center;

            &.data-table-cell {
                flex: 0;
            }

            & .data-header-sort {
                padding-top: 0px;
                flex-grow: inherit;
            }

            & .data-header-sorting-icon {
                height: 10px;
                width: 10px;
            }
        }

        & .data-table-rows {
            height: 300px;

            & .data-table-row {
                padding: 1px 10px;
                height: 24px;
                font-size: 13px;
                font-weight: 300;

                & .data-table-cell {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    word-wrap: none;
                    overflow: hidden;
                }
            }
        }

        & .data-table-footer {
            display: none;
        }
    }
}
@each $val in var(--theme-array) {
    .$(val) {
        & .import-export-header-container .bar {
            border-left: 1px solid var(--base-palette-3-$(val));
        }

        & .view-log-dialog-box .data-table-cont .data-table-rows {
            border-bottom: 5px solid var(--base-palette-4-$(val));
        }
    }
}
