@media screen and (max-width: 1240px) {
    .slide-content-container {
        margin-left: 10%;

        & .add-edit-panel {
            flex-grow: 1;
        }

        & .content-table-container.data-table-cont {
            flex-grow: 1;
            overflow-x: auto;
        }
    }

    .sliding-panel-header {
        & .display-container {
            flex-grow: 1;
            width: 96%;
        }
    }
}

@media (min-width: 961px) and (max-width: 1240px) {
    .slide-content-container {
        margin-left: 6%;

        & .add-edit-panel {
            max-width: 1240px;
        }
    }
}
