@import "../../../theme/css-variables/master-variables.css";

.toolbar-svg-icon {
    position: absolute;
    height: 30px;
    left: 0;
    right: 0;
    top: 3px;
    width: 70px;
}

.toolbar-item-icon .toolbar-svg-icon {
    height: 28px;
    top: 4px;
}

svg image {
    cursor: pointer;
}

@each $val in var(--theme-array) {
    .$(val) {
        & .toolbar-svg-icon {
            fill: var(--white-black-toggle-$(val));
            stroke: var(--white-black-toggle-$(val));
            &.no-stroke {
                stroke: none;
            }
        }

        & .expanded .toolbar-svg-icon {
            fill: var(--highlight-$(val));
        }

        & .expanded .toolbar-item-icon .toolbar-svg-icon {
            fill: var(--white-black-toggle-$(val));
        }

        & .active .toolbar-svg-icon,
        & .selected .toolbar-svg-icon {
            fill: var(--highlight-$(val)) !important;
            stroke: var(--highlight-$(val)) !important;
            &.no-stroke {
                stroke: none !important;
            }
        }
    }
}
@each $val in var(--sky-array) {
    .$(val) {
        & .toolbar-svg-icon {
            fill: var(--white-$(val));
        }

        & .expanded .toolbar-item-icon .toolbar-svg-icon {
            fill: var(--black-$(val));
        }
    }
}
