@import "../../../../../../../theme/css-variables/master-variables.css";

.move-fields-modal {
    & .mf-row {
        display: flex;
    }

    & .mf-col {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-width: 250px;
        padding: 0 10px;

        & .auto-search {
            padding-top: 10px;
        }
    }

    & .mf-header {
        font-size: 15px;
        line-height: 24px;
        text-align: center;
    }

    & .mf-content {
        height: 150px;
        margin-left: 15px;
    }

    & .mf-field-list-item {
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    & .select-form-input-container {
        width: 300px;
    }
}

@each $val in var(--theme-array) {
    .$(val) .move-fields-modal {
        & .mf-col:last-child {
            border-left: solid 1px var(--white-black-toggle-$(val));
        }

        & .mf-header {
            border-bottom: solid 1px var(--white-black-toggle-$(val));
        }
    }
}
