.recommendation-dialog {
    flex-flow: column nowrap;
    max-width: 60em;
    min-height: 30em;
    min-width: 40em;
    position: fixed !important;
    width: 40em;
    left: 35%;

    & .textarea-form-input {
        width: 40em;
        height: 350px;
    }

    & .recommendation-content {
        max-height: 70vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    & .recommendation-top-bar {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
    }
}
