.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form {
    & .input-row > {
        & .price-unit {
            display: flex;
            align-items: flex-end;
            padding: 10px 5px;
            width: 25px;
            flex-grow: unset;
        }

        & .events-rootstock-name {
            width: 5px;
        }

        & .price-per-unit {
            flex-grow: 0.75;
        }

        & .seed-treatment-rate {
            width: 30%;
        }

        & .seed-treatment-unit {
            width: 70%;
        }
    }

    & .input-row.seed-treatment-price-container {
        width: 50%;
    }

    & .bucket.seed-treatment-section-bucket {
        padding: 0px;
    }

    & .event-planting-info-form + .event-zone-info {
        padding: 7px 20px 6px 0;
    }
}

.planting-variety-hybrid {
    & .add-link-container {
        margin: 10px 5px;
    }

    & .child-row .delete-link-container {
        padding: 7px 3px 3px 0px;
    }
}

.price-unit {
    margin-right: 5px;
}
