@import "~/../../theme/css-variables/master-variables.css";

.slide-container {
    position: absolute;
    z-index: 1001;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    flex: auto;
}
.slide-container.slide {
    animation: slide 1s backwards;
    animation-delay: 1s;
}

.slide-content-container {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex: 1;

    & .add-edit-panel {
        max-width: 1240px;
        margin: 15px auto;

        & .bar {
            margin-left: 13px;
        }
    }

    & .content-table-container {
        flex: 1 1 auto;
        display: flex;
        flex-flow: column;
        overflow: hidden;
        & .slide-content-container {
            display: block;
        }
    }
}

.sliding-panel-header {
    height: 40px;
    font-family: "open_sansregular";
    font-size: 17px;
    font-weight: 500;
    line-height: 40px;

    & .display-container {
        width: 1240px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        & .display-text-container {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            & .header-item {
                display: inline;
            }

            & .header-arrow {
                padding: 0 5px;
            }
        }

        & .svg-container {
            cursor: pointer;
            float: right;

            & .default-svg-icon {
                height: 20px;
                width: 20px;
                margin: 10px 0;
            }
        }
    }

    & .button-container {
        display: flex;
        padding: 2px 0 2px 5px;

        & .default-button {
            margin-right: 5px;
            height: 32px;
            line-height: 32px;

            &.cancel-button.align-with-content {
                margin-right: 3px;
            }
        }
    }
}
.slide-container.slide-half {
    left: 444px;
    width: calc(100% - 444px);
    animation: slide-half 3s backwards;
    animation-delay: 1s;

    & .sliding-panel-header {
        height: 50px;

        & .display-container {
            font-size: 20px;
            font-weight: 500;
            height: 50px;
            line-height: 50px;
            width: 99%;
            padding: 0 10px;

            & .svg-container {
                & .default-svg-icon {
                    margin: 15px;
                }
            }
        }
    }
}

@keyframes slide-half {
    100% {
        left: 444px;
    }
}

@keyframes slide {
    100% {
        left: 0;
    }
}

@keyframes slide {
    0% {
        left: 2000;
    }
}

@keyframes slide-half {
    0% {
        left: 2000;
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .slide-container {
            background-color: var(--base-palette-1-$(val));

            & .slide-content-container .content-table-container {
                color: var(--white-black-toggle-$(val));
            }
        }

        & .sliding-panel-header {
            background-color: var(--base-palette-4-$(val));
            color: var(--white-$(val));

            & .display-container {
                & .display-text-container .header-item:last-child {
                    & .display-text {
                        color: var(--attention-$(val));
                    }
                }

                & .svg-container:hover .default-svg-icon {
                    fill: var(--highlight-$(val));
                }
            }
        }

        & .slide-container.slide-half .sliding-panel-header {
            background-color: var(--base-palette-4-$(val));
        }
    }
}

@each $val in var(--sky-array) {
    .$(val)
        .sliding-panel-header
        .display-container
        .display-text-container
        .header-item:last-child
        .display-text {
        color: var(--attention-$(val));
        font-weight: bold;
    }
}
