.rec-event-batch-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    & .rec-event-info {
        flex-grow: 0;
    }

    & .rec-event-batch-list-accordion {
        flex-grow: 1;
        position: relative;
    }

    & .save-cancel-btns {
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 15px;
        min-height: 34px;
        overflow: hidden;

        & .default-button {
            margin: 0 7px;
        }
    }
}

.batch-rec-event-info {
    & .batch-equation-rec-loader {
        height: calc(100% - 160px);
        top: 100px !important;
    }
}
