@import "../../../../../../theme/css-variables/master-variables.css";

.import-module .search-user {
    display: flex;
    flex-direction: row;
    align-items: center;

    & .search-user-input {
        flex-grow: 1;

        & .select-form-input-container {
            & .search-icon {
                margin: 4px 0;
                margin-left: 2px;
                padding: 2px;
                width: 15px;
                height: 15px;
                border: none;
                cursor: pointer;

                & .default-svg-icon {
                    width: 15px;
                    height: 15px;
                    fill: black;
                }
            }

            & .form-input {
                margin: 3px 10px;
                &.select-form-input-options {
                    margin: 0 10px;
                }
            }
        }

        & .select-form-input-options .select-form-input-option {
            display: flex;
            flex-direction: row;

            & .add-icon {
                margin: 6px 0;
                margin-left: 2px;
                padding: 2px;
                width: 28px;
                height: 28px;
                border: none;
                cursor: pointer;

                & .default-svg-icon {
                    width: 28px;
                    height: 28px;
                    fill: black;
                }
            }

            & .user-info {
                margin-left: 8px;
                flex-grow: 1;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                & .loc {
                    font-family: "open_sansitalic";
                    font-size: 11px;
                }

                & .title {
                    margin-left: 20px;
                    margin-right: 8px;
                }
            }
        }
    }

    & button {
        margin-left: 75px;
        margin-right: 15px;
    }
}

@each $val in var(--theme-array) {
    .$(val) .import-module .search-user {
        background-color: var(--base-palette-4-$(val));
    }
}
