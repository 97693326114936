@import "../../../theme/css-variables/master-variables.css";

.bucket {
    margin: 10px 0px;
    padding: 0 0 0 5px;
    width: 100%;
    min-height: 40px;

    & .bucket-header {
        width: 100%;
        height: 20px;
        font-size: 15px;
        font-weight: 300;
        cursor: pointer;
        user-select: none;

        & .bucket-symbol {
            float: left;
            margin-left: 3px;
            margin-right: 5px;

            & .bucket-arrow-icon {
                width: 15px;
                height: 15px;
            }
        }
    }

    & .bucket-content {
        display: flex;
        flex-flow: column wrap;
        align-items: flex-start;
        width: 100%;
    }
}
.bucket.non-collapsible {
    & .bucket-header {
        cursor: default;
    }
}
@each $val in var(--theme-array) {
    .$(val) .bucket-arrow-icon {
        fill: var(--white-black-toggle-$(val));
    }
}
