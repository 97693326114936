.import-wizard-header {
    min-height: 45px;
    max-height: 115px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    & .import-wizard-header-action {
        width: 90%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }

    & .dialog-box-body {
        width: 400px;
        height: 125px;
        line-height: 17px;
        & .size-info {
            padding-left: 25px;
            & :first-child {
                padding-top: 15px;
            }
            & :last-child {
                padding-bottom: 15px;
            }
        }
    }

    & .single-step {
        & .progress-section-container {
            & .progress-stop-container {
                & .progress-stop-label {
                    font-size: 15px;
                }
            }
        }
    }
}
