@import "../../theme/css-variables/master-variables.css";

.about {
    & .quill {
        width: 1150px;
    }

    & .ql-editor {
        overflow-x: hidden;
    }

    .ql-container.ql-snow {
        border: none;
        height: 72vh;
        max-height: 72vh;
    }

    & .tabs-labels {
        max-width: fit-content;
        margin-left: 400px;
    }

    & .tab-label {
        width: 200px;
        line-height: 40px;
    }

    & .release-notes {
        user-select: text;
        margin-top: 10px;
        width: 1160px;
    }

    & .form-section {
        width: 800px;
    }

    & .build-version {
        margin-left: 380px;
        margin-top: 10px;
    }
}

@each $val in var(--sky-array) {
    .$(val) {
        & .quill {
            color: var(--black-$(val));
        }

        & .ql-toolbar {
            border-bottom: 1px solid var(--black-$(val));
        }
    }
}
