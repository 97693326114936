.non-field-feature-information-button-bar {
    max-height: 60px;
    min-height: 60px;
    width: 100%;
    text-align: center;
    padding-top: 15px;

    & .default-button {
        margin: 9px;
    }
}
