@import "../../../../../theme/css-variables/master-variables.css";

.product-blend-modal.equation-blend-modal {
    min-width: 1025px;
    width: 1025px;
    max-width: 1025px;

    & .blend-type {
        & .tabs-labels {
            width: 33%;
        }
    }
}

.product-blend-modal {
    min-width: 1050px;
    width: 1050px;
    max-width: 1050px;

    min-height: 640px;

    & .dialog-box-body {
        margin-top: 0px;
    }

    & .load-custom-blend {
        text-align: right;
        padding-right: 10px;
        display: inline;
        position: relative;
        float: right;
        top: 15px;

        & .separator {
            padding-left: 10px;
            padding-right: 10px;
        }

        & .load-custom-blend-modal {
            display: flex;
            flex-flow: column nowrap;

            & .load-blend-table-header {
                font-weight: 700;
            }
            & .load-blend-table-row {
                display: flex;
                flex-flow: row nowrap;
                width: 400px;
                height: 25px;
                line-height: 25px;

                & .blend-table-name {
                    width: 35%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-left: 5px;
                }
                & .blend-table-ga {
                    width: 52%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-left: 5px;
                }
                & .blend-table-custom {
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-left: 5px;
                }
                & .blend-table-chemical {
                    width: 87%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-left: 5px;
                }
                & .remove-product-icon {
                    & .svg-container {
                        & .default-svg-icon {
                            width: 18px;
                        }
                    }
                }
            }
            & .load-blend-table-body {
                max-height: 500px;
                overflow-y: auto;
                overflow-x: hidden;

                & .load-blend-table-row {
                    cursor: pointer;
                }
            }
        }
    }
    & .blend-type {
        width: 100%;
        margin-top: 0px;

        & .tabs-labels {
            width: 50%;
            margin-bottom: 0px;

            & .tab-label {
                line-height: 28px;
            }
        }
    }

    & .product-selection {
        padding-top: 10px;

        & .product-filter-bar {
            min-width: 100%;

            & .product-filters-toggle {
                display: block;
                padding-left: 3px;
                min-height: 20px;
            }

            & .product-filters {
                display: flex;
                flex-flow: row nowrap;
            }
        }
        & .product-add {
            width: 55%;
            display: flex;
            flex-flow: row nowrap;

            & .product-select-input {
                width: 100%;
            }
            & .product-add-nolink-section {
                display: flex;
                flex-flow: row nowrap;
                min-width: 50%;

                & .product-add-nolink {
                    flex-grow: 1;
                    margin-top: 20px;
                    margin-left: 20px;
                }
            }
        }
    }

    & .product-blend-table {
        margin-top: 5px;
        height: 340px;
        max-height: 340px;
        margin-bottom: 20px;
        justify-content: flex-start;
        overflow-y: auto;

        & .product-blend-body {
            display: flex;
            flex-flow: column nowrap;
            width: 100%;
            justify-content: flex-start;

            & .guaranteed-analysis {
                margin-top: 5px;
                line-height: 25px;
            }
            & > .product-blend-item {
                & .form-input {
                    height: 22px;
                }
                & .form-input:not(.select-form-input) {
                    & input {
                        text-align: center;
                    }
                }
                & .select-form-input-options {
                    text-align: left;
                }
            }
        }

        & .product-blend-header-margin {
            margin-top: 15px;
        }

        & .product-blend-header {
            font-weight: 700;
            min-height: 30px !important;
            line-height: 30px;
            height: 30px;

            & .checkbox-div {
                margin-left: 11px;
                margin-top: 3px;
            }
        }

        & .carrier-header {
            height: 25px !important;
            line-height: 25px !important;
            min-height: 25px !important;

            & .product-blend-column {
                line-height: 25px !important;
            }
        }

        & .product-blend-item {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            min-width: 100%;
            min-height: 40px;

            & .remove-product-icon {
                cursor: pointer;
                margin-top: 9px;

                & .default-svg-icon {
                    height: 22px;
                    width: 22px;
                }
            }

            & .product-blend-column {
                min-width: 5%;
                text-align: center;
                white-space: nowrap;
                line-height: 40px;
                & .tall {
                    line-height: 40px;
                }
                & .expand-contract-arrow {
                    position: relative;
                    top: -10px;
                }
                & .lock-mix-checkbox {
                    margin-left: 128px;
                }
            }
            & .product-blend-column.xsmall {
                min-width: 4%;
                width: 4%;
                max-width: 4%;
            }
            & .product-blend-column.small {
                min-width: 8%;
                width: 8%;
                max-width: 8%;
            }
            & .product-blend-column.med {
                min-width: 10%;
                width: 10%;
                max-width: 10%;
            }
            & .product-blend-column.large {
                min-width: 12%;
                width: 12%;
                max-width: 12%;
            }
            & .product-blend-column.xlarge {
                min-width: 16%;
                width: 16%;
                max-width: 16%;
            }
            & .product-blend-column.xxlarge {
                min-width: 19%;
                width: 19%;
                max-width: 19%;
            }
            & .product-blend-column.xxxlarge {
                min-width: 25%;
                width: 25%;
                max-width: 25%;
            }
        }
        & .field-avg-rate {
            line-height: 50px;
            width: 59.5%;
            display: flex;
            & .field-avg-rate-label {
                width: 19%;
            }
            & .field-avg-rate-value {
                text-align: center;
                width: 10%;
            }
        }
        & .min-max-settings {
            display: column;
            width: 24%;
            line-height: 50px;
            & .min-max-lock-bar {
                display: flex;
                flex-flow: row nowrap;
                & .min-lock-checkbox {
                    margin-top: 16px;
                    margin-left: 25px;
                    max-width: 30px;
                }
                & .max-lock-checkbox {
                    margin-top: 16px;
                    margin-left: 40px;
                    max-width: 30px;
                }
            }
        }
        & .settings-row {
            display: flex;
            justify-content: flex-end;
            min-width: 100%;
        }
        & .minimum-settings {
            display: flex;
            justify-content: flex-end;
            flex-flow: row wrap;
            width: 100%;
            & .select-form-input-container {
                width: 23%;
            }
        }
        & .switch-rate-input {
            & .form-input {
                float: right;
                margin-right: 118px;
                width: 10%;
            }
        }
    }

    & .equation-credit-bucket {
        & .bucket-header {
            display: flex;
            flex-flow: row nowrap;
            height: 40px;
            line-height: 40px;

            & .apply-credits-section {
                display: flex;
                flex-flow: row nowrap;
                width: 100%;
                line-height: 40px;
                padding-left: 25px;
                cursor: default;

                & .credits-not-applied {
                    flex-grow: 1;
                    text-align: right;
                    font-size: 12px;
                }
            }
        }
        & .equation-credits-body {
            display: flex;
            flex-flow: column nowrap;
            width: 100%;

            & .credit-options-section {
                display: flex;
                flex-flow: row nowrap;
                width: 100%;
                min-height: 100px;

                & .rec-credit-section {
                    width: 30%;
                    flex-flow: column nowrap;
                    justify-content: space-between;
                    margin-right: 30px;
                    max-height: 200px;
                    overflow-y: auto;

                    & .credit-section-header {
                        font-weight: bold;
                        font-size: 14px;
                        text-decoration: underline;
                        line-height: 30px;
                    }
                }
                & .rec-credit-section.flat-rate-section {
                    max-width: 26%;
                    margin-right: 75px;

                    & .credit-section-body {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: flex-start;
                        line-height: 50px;
                        height: 50px;

                        & .credit-checkbox {
                            margin-top: 20px;
                            max-width: 30px;
                        }

                        & .form-input {
                            flex-grow: 1;
                        }
                    }
                }
            }
        }
    }

    & .carrier-item {
        line-height: 40px;
        & .form-input {
            line-height: 20px;
        }

        & .expand-contract-arrow {
            top: 0px !important;
        }
    }

    & .product-blend-totals {
        & .product-blend-column {
            line-height: 48px !important;
        }
        & .form-input {
            height: 35px;
            line-height: 20px;
        }
        & .checkbox-div {
            margin-top: 15px;
            margin-left: 10px;
        }

        & .text-form-input:not(.select-form-input) {
            & input {
                text-align: center;
            }
        }
        & .select-form-input-container {
            text-align: left;
        }
    }

    & .custom-product-builder {
        & .property-bar {
            display: flex;
            flex-flow: row nowrap;
            width: 100%;

            & .property-input {
                width: 15.5%;
                justify-content: flex-start;
            }
            & .property-label {
                line-height: 55px;
                margin-left: 5px;
            }
            & .custom-product-controls {
                width: 70%;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-end;
                line-height: 50px;
                text-align: right;
            }
            & .add-custom-product-nolink {
                margin-right: 20px;
            }
            & .clear-custom-product-nolink {
                margin-left: 15px;
                margin-right: 15px;
            }
        }
        & .nutrient-bar {
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            justify-content: space-between;

            & .nutrient-input {
                min-width: 7.5%;
            }
        }
    }

    & .product-blend-save {
        display: flex;
        flex-flow: column nowrap;
        width: 50%;

        & .blend-save-message {
            font-size: 12px;
            margin-left: 5px;
        }

        & .blend-save-row {
            display: flex;
            flex-flow: row nowrap;
            width: 100%;

            & .blend-save-input {
                width: 50%;
            }
            & .blend-save-nolink {
                margin-top: 20px;
                margin-left: 10px;
            }
        }
    }
    & .blending-validation-error-msg {
        margin-top: 20px;
    }
    & .blending-validation-reset-msg {
        margin-top: 20px;
        text-align: center;
        font-weight: bold;
    }
}

.product-blend-modal.equation-blend-modal {
    & .equation-product-blend-table {
        & .product-blend-column.xsmall {
            min-width: 4%;
            width: 4%;
            max-width: 4%;
        }
        & .product-blend-column.xxlarge {
            min-width: 23%;
            width: 23%;
            max-width: 23%;
        }
        & .product-blend-column.small {
            min-width: 7%;
            width: 7%;
            max-width: 7%;
        }
        & .product-blend-column.med {
            min-width: 8%;
            width: 8%;
            max-width: 8%;
        }
    }
}
.product-blend-modal.equation-blend-modal.lime-blend-modal {
    min-width: 1125px;
    width: 1125px;
    max-width: 1125px;

    & .equation-product-blend-table {
        & .product-blend-column.xxlarge {
            min-width: 16%;
            width: 16%;
            max-width: 16%;
        }

        & .field-avg-rate {
            width: 61%;
            & .field-avg-rate-label {
                width: 16%;
            }
        }
    }
    & .product-blend-table .min-max-settings {
        width: 30%;

        & .min-max-lock-bar {
            & .min-lock-checkbox {
                margin-left: 24px;
            }
            & .max-lock-checkbox {
                margin-left: 46px;
            }
        }
    }
}

.trim-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@each $val in var(--theme-array) {
    .$(val) .product-blend-modal {
        & .blend-type-tabs {
            border-top: none !important;
            & .tab-label:not(.active) {
                border-right: 1px solid var(--base-palette-1-$(val));
            }
        }
        & .product-blend-table {
            border-top: 1px solid var(--base-palette-4-$(val));
            border-bottom: 1px solid var(--base-palette-4-$(val));
        }
        & .equation-product-blend-table {
            background-color: var(--base-palette-4-$(val));
        }
        & .selected-custom-blend {
            background-color: var(--highlight-$(val));
        }
        & .invalid-rate {
            color: var(--error-$(val));
        }
        & .carrier-disabled {
            opacity: 0.4;
        }
        & .custom-product-controls {
            color: var(--base-palette-2-$(val));
        }
        & .credits-not-applied {
            color: var(--warning-$(val));
        }
    }
}
