@import "../../../theme/css-variables/master-variables.css";

.admin-scripts-container {
    display: flex;
    width: 1240px;
    margin: 15px auto;

    & .select-form-input-container {
        width: 384px !important;
    }

    & .org-level-search-container {
        margin-top: -10px;
    }

    & .section-fence {
        border-width: 1px;
        margin: 0 13px;
    }

    & .checkbox-div {
        width: 100%;
    }
}
.admin-scripts-main-container .dialog-container .default-button {
    max-width: unset;
}
