@import "../../theme/css-variables/master-variables.css";

.action-panel {
    display: flex;
    flex-direction: column;
    font-size: 13px;

    &.action-panel-expanded,
    &.action-panel-expanded .panel-modal {
        min-width: 444px;
        max-width: 444px;
    }

    &.action-panel-collapsed,
    &.action-panel-collapsed .panel-modal {
        min-width: 1px;
        max-width: 1px;
        border-right: none;
    }

    & .context-menu {
        float: right;
        margin: 5px 5px 0 0;
    }

    & .tabs .tabs-labels {
        min-height: 0;

        & li.tab-label {
            height: 40px;
            box-sizing: border-box;

            &.left-non-label {
                flex-grow: 1;
            }

            &.right-non-label {
                width: 30px;
                display: flex;
                flex-direction: row-reverse;
            }

            & .tab-nav-text {
                padding: 0 2px;
                line-height: 20px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }

    & .panel-modal {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;

        & .dialog-window,
        & .modal-overlay {
            position: absolute !important;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        & .dialog-container {
            position: fixed !important;
            width: 385px;
            line-height: 17px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .action-panel {
        border-right: 2px solid var(--base-palette-3-$(val));

        & .tabs {
            border-top: 1px solid var(--base-palette-4-$(val));

            & .tabs-labels {
                background-color: var(--base-palette-4-$(val));
            }
        }

        & .context-menu {
            & .menu-dot {
                color: var(--base-palette-1-$(val));
            }

            &:hover,
            &.menu-expanded {
                background-color: var(--base-palette-1-$(val));

                & .menu-dot {
                    color: var(--highlight-$(val));
                }
            }
        }

        & .import-status-error {
            color: var(--error-$(val));
        }

        & .import-status-processing {
            color: var(--attention-$(val));
        }

        & .import-status-ready {
            color: var(--highlight-$(val));
        }
    }
}
