.form-sub-section {
    & .license-type {
        width: 30%;
    }
    & .license-number {
        width: 70%;
    }
    & .license-expiration-date.form-input {
        width: 130px;
    }
    & .license-country {
        width: 150px;
    }
    & .license-state {
        width: 70px;
    }
}
.license-header {
    & .form-section-header {
        margin-bottom: 0px;
    }
    & .sub-description-container {
        margin-bottom: 7px;
    }
}
.license-container {
    margin-bottom: 5px;
}
.license-table {
    & .data-header-cell .data-table-cell {
        max-width: 150px;
    }
}
