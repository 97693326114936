@import "../../../../theme/css-variables/master-variables.css";

.rec-event-info-summary {
    padding: 10px;
    display: flex;
    flex-flow: row wrap;

    & .field-totals {
        min-width: 100%;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }

    & .summary-table {
        display: flex;
        flex-flow: column wrap;
        min-width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;

        & .summary-table-header,
        & .summary-row {
            display: flex;
            flex-flow: row nowrap;
            min-width: 100%;
            max-width: 100%;
            height: 22px;
        }

        & .summary-table-header {
            font-style: italic;
            font-weight: 700;
        }

        & .summary-table-body {
            display: flex;
            flex-flow: row wrap;
            min-width: 100%;
            max-width: 100%;
            padding-top: 5px;
        }

        & .summary-column {
            min-width: 25%;
            width: 25%;
            max-width: 25%;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        & .summary-column-wide {
            min-width: 49%;
            width: 49%;
            max-width: 49%;
            text-align: left;
        }

        & .summary-table-totals {
            display: flex;
            flex-flow: row wrap;
            margin-top: 10px;

            & .total-row {
                display: flex;
                flex-flow: row nowrap;
                min-width: 100%;
                width: 100%;
                max-width: 100%;
                font-size: 13px;
                justify-content: flex-end;

                & .total-row-label {
                    min-width: 20%;
                    width: 20%;
                    max-width: 20%;
                }

                & .total-row-value {
                    min-width: 20%;
                    width: 20%;
                    max-width: 20%;
                    text-align: right;
                }
            }
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .rec-event-info-summary {
            background-color: var(--base-palette-4-$(val));

            & .summary-table-body {
                border-top: 1px solid var(--base-palette-5-$(val));
                border-bottom: 1px solid var(--base-palette-5-$(val));
            }
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) {
        & .rec-event-info-summary {
            background-color: var(--base-palette-6-$(val));
        }
    }
}
