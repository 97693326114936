@import "../../../../../../theme/css-variables/master-variables.css";

.legend-icon {
    height: 20px;
    min-width: 20px;
}

.legend-label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
    line-height: 20px;
    height: 20px;
    padding: 0 7px;

    &.legend-label-hover {
        position: fixed;
        z-index: 100000;
    }
}

.legend-filler {
    flex-grow: 1;
}

@each $val in var(--theme-array) {
    .$(val) .legend-label:hover {
        background-color: var(--shadow-$(val));
    }
}
