.batch-edit-recs-modal {
    & .primary-rec {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    & .secondary-list {
        margin-left: 30px;
        margin-top: 15px;
    }
}
