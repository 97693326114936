@import "../../../theme/css-variables/master-variables.css";

.search-input {
    & .form-input {
        height: 25px;
    }

    & .search-img {
        height: 13px;
        width: 13px;
        margin-top: 7px;
    }

    & .clear-img {
        width: 20px;
        height: 24px;
        cursor: pointer;
    }

    & .search-input-left-container {
        display: flex;

        & .search-input-required {
            font-size: 15px;
            line-height: 15px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .search-input {
        & .search-img {
            fill: var(--black-$(val));
        }

        & .clear-img {
            fill: var(--black-$(val));
        }

        & .search-input-left-container {
            & .search-input-required {
                color: var(--error-$(val));
            }
        }
    }
}
