.layer-stats-dialog {
    & h2 {
        font-size: 15px;
        font-weight: 700;
        line-height: 30px;
        padding: 0 10px 10px 10px;
        text-align: center;
    }

    & table {
        margin: 0 auto;
    }

    & tr {
        padding: 1px 10px;
    }

    & .title {
        padding: 1px 5px;
        text-align: left;
    }

    & .number {
        padding: 1px 2px 1px 5px;
        text-align: right;
    }

    & .units {
        padding: 1px 5px 1px 2px;
        text-align: left;
    }
}
