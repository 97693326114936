.field-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    font-size: 15px;

    & .field-list-fill {
        flex-grow: 1;
    }

    & .displayed {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    & .not-displayed {
        display: none;
    }

    & .module-filter-row {
        justify-content: flex-start;
    }

    & .module-search {
        max-width: 341px;
    }
    & .module-filters {
        height: auto;
        min-height: auto;
    }
}
