.analysis-layer-management-area-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    width: 100%;
}

.analysis-layer-management-area-radio-button {
    margin: auto;
    margin-left: 3px;
}

.analysis-layer-management-area-input {
    width: auto;
    display: inline-block;
    margin: auto 0;
}

.analysis-layer-management-area-type-input {
    width: 66%;
    display: inline-block;
    grid-column: 1 / 2 span;
}

.detailed-link {
    height: 100px;
    padding-top: 5px;
    padding-left: 218px;
}

.zone-icon {
    height: 15px;
    width: 15px;
    margin-left: 5px;
    margin-right: 12px;
}

.zone-info-heading {
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 0;
}

.zone-name {
    font-size: 14px;
    font-weight: 700;
}

.zone-size {
    padding-left: 5px;
    font-size: 14px;
    font-weight: 300;
}

.no-zone-selected {
    padding: 15px 20px 10px 20px;
    color: #bfbfbf;
}
