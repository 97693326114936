.analytics-main-container {
    margin: auto;
    margin-top: 10px;
    width: 1240px;
    height: 100%;

    display: inline-block;

    & .dashboardSelector {
        display: flex;
        width: 75%;
        flex-direction: row;
        align-content: center;

        & .select-form-input-container {
            width: 30%;
            margin: -10px -10px 0 -5px;
        }
    }

    & #dashboardContainer {
        margin-left: 5px;
        height: 90%;
    }
}
