@import "../../../theme/css-variables/master-variables.css";

.letter-icon {
    height: 17px;
    min-width: 17px;
    line-height: 17px;
    font-size: 12px;
    border-radius: 50%;
    margin: 3px 0 3px 20px;
    text-align: center;
}

.dialog-window {
    position: fixed !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dialog-container {
    position: relative;
    min-width: 300px;
    max-height: 95%;
    max-width: 95%;
    border-radius: 4px;
    padding: 5px;
    display: flex;
    flex-direction: column;

    & .dialog-box-title {
        font-size: 15px;
        font-family: "open_sansregular";
        font-weight: 500;
        text-align: left;
        min-height: 28px;
        min-width: 100%;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;

        & .letter-icon {
            margin: 3px;
        }

        &.restricted {
            max-width: 450px;
        }

        & > div {
            padding: 3px;
            flex-grow: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    & .dialog-box-body {
        font-size: 13px;
        font-family: "open_sansregular";
        font-weight: 300;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: left;
        min-height: 41px;
        padding: 0 10px;
        margin: 10px 0;
        flex-grow: 1;

        &.restricted {
            max-height: 300px;
            max-width: 450px;
        }

        /*fixes scrollbar edge rounding issues:*/
        width: calc(100% + 0.5px);
        box-sizing: border-box;

        & > div {
            padding: 2px 0;
        }

        & .select-form-input-container .select-form-options {
            margin-top: 0;
        }
    }

    & .dialog-box-footer {
        padding: 5px 0 0 0;
        min-height: 38px;
        display: flex;
        justify-content: flex-end;

        & .default-button:last-child {
            margin-right: 2px;
        }
    }

    & .dialog-drag-bar {
        height: 25px;
        width: 100%;
        margin: -5px;
        cursor: move;
        position: absolute;
        z-index: 2;
    }

    & .dialog-tool-icon {
        cursor: pointer;
        z-index: 3;
        margin-right: 10px;
    }
    & .dialog-tool-icon:last-child {
        margin-right: 5px;
    }

    &.allow-overflow .dialog-box-body {
        overflow: visible;
    }

    & .loader-container {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.dialog-expanded .dialog-box-body.restricted {
    min-width: 585px;
}

.modal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}

.error-message-dialog-box {
    & .dialog-box-body {
        user-select: auto;
        * > div {
            padding-bottom: 7px;
        }
    }
}
.dialog-container.confirm-dialog .dialog-box-body {
    text-align: center;
}

@each $val in var(--theme-array) {
    .$(val) .letter-icon {
        color: var(--white-black-toggle-$(val));
        border: 1px solid var(--white-black-toggle-$(val));
        box-shadow: 0 0 1px var(--white-black-toggle-$(val));
    }

    .$(val) .dialog-container {
        background: var(--base-palette-1-$(val));
        color: var(--white-black-toggle-$(val));
        box-shadow: 0 3px 2px var(--shadow-rgb3-$(val));
        border: 1px solid var(--white-black-toggle-$(val));

        & .dialog-box-title {
            border-bottom: 1px solid var(--shadow-$(val));
        }

        & .dialog-box-footer {
            border-top: 1px solid var(--shadow-$(val));
        }

        & .dialog-tool-icon .default-svg-icon {
            fill: var(--white-black-toggle-$(val));
        }

        & .header-theme-title {
            background: var(--base-palette-5-$(val));
        }

        & .dialog-tool-icon:hover .default-svg-icon {
            fill: var(--highlight-$(val));
        }
    }

    .$(val) .modal-overlay {
        background: var(--overlay-$(val));
    }
}
