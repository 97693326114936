.merge-events-modal {
    overflow: none;
    padding: 10px;
}

.event-list-item {
    display: block;
}

.event-list-box {
    padding-top: 10px;
}
