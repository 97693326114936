.import-validation-modal {
    & .error-result {
        display: flex;
        flex-flow: row wrap;
        width: 375px;
        padding: 5px 0;

        & .error-context {
            max-width: 357px;
            margin-bottom: 5px;
            cursor: pointer;
        }

        & .nested-grid-expand-button {
            height: 18px;
            cursor: pointer;
        }

        & ul {
            width: 100%;
            padding-left: 18px;

            & li {
                margin: 5px 0;
            }
        }

        & .expand-collapse-icon {
            width: 18px;
            height: 18px;
        }
    }
}
