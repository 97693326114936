.data-table-container {
    width: 80px;
    display: flex;
    justify-content: center;

    &.data-table-checkbox {
        width: 44px;
    }

    & .expand-collapse-icon {
        height: 14px;
        width: 13px;
    }
}

.collapse-button-rotate-90 {
    transform: rotate(-90deg);
}

.collapse-button-rotate-45 {
    transform: rotate(-45deg);
}

.row-left-content {
    width: 30px;
    padding-top: 10px;
}

.nested-data-table {
    & .data-table-header {
        & .data-table-container {
            width: 60px;

            &.data-table-checkbox {
                width: 44px;
            }
        }
    }

    & .nested-table-row {
        & .data-table-container {
            padding-left: 0px;
            min-width: 44px;
            width: 50px;

            &.data-table-checkbox {
                width: 44px;
            }
        }

        & .row-left-content {
            & .svg-container {
                padding-left: 20px;
            }
        }
    }
}
.child-component {
    margin-left: 15px;

    & .data-table-cont {
        width: 1188px;
        margin-top: 3px;
    }
}
