.non-field-feature-information {
    flex: 100;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: column nowrap;

    & .non-field-feature-information-content {
        flex-grow: 100;
        height: 0;
        display: flex;
        flex-flow: row nowrap;

        & .content-table-container.with-api-result {
            display: flex;
            flex-grow: 1;
        }
    }
}
