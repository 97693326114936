@import "../../../../../../theme/css-variables/master-variables.css";

.import-module .tabs-labels {
    & li.tab-label.right-non-label {
        flex-grow: 1;
        display: flex;
        flex-direction: row-reverse;
    }

    & button {
        margin-right: 5px;
    }

    & .import-filter-tabs-right {
        display: flex;
        flex-flow: row nowrap;
    }
    & .onsite-telematics-status {
        display: flex;
        flex-flow: row nowrap;
        width: 110px;
        padding-top: 3px;
        & .default-svg-icon {
            width: 33px;
            height: 33px;
        }
        & .st0,
        & .st1 {
            stroke-width: 2;
            stroke-miterlimit: 10;
        }
        & .st1 {
            fill: none;
        }
        & .onsite-telematics-count {
            line-height: 35px;
            font-size: 16px;
        }
    }
}
@keyframes blinker {
    90% {
        opacity: 0;
    }
}
@each $val in var(--theme-array) {
    .$(val) {
        & .onsite-telematics-status .default-svg-icon {
            stroke: var(--white-black-toggle-$(val));
        }

        & .onsite-telematics-status.processing {
            & .svg-container svg {
                fill: var(--highlight-$(val));
                stroke: var(--highlight-$(val));

                & circle {
                    fill: var(--highlight-$(val));
                    stroke: var(--highlight-$(val));
                }
            }

            & .onsite-telematics-count {
                color: var(--highlight-$(val));
            }
        }
    }
}
