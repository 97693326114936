@import "../../../theme/css-variables/master-variables.css";

.system-profile-main-container {
    & .slide-content-container {
        display: block;

        & .add-edit-panel.system-settings-cont {
            overflow: visible;
            padding-top: 15px;
            margin: 0 auto;
        }
    }

    & .system-profile-section {
        width: 33.33%;
    }

    & .form-sub-section {
        & .copyright-text.form-input {
            width: 100%;
        }

        & .policy-last-updated.form-input {
            width: 95%;
        }
    }

    & .grid-section .form-section-header {
        margin-bottom: 10px;
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .system-settings-cont .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: var(--error-$(val));
        }
    }
}
