@import "../../../../../../../theme/css-variables/master-variables.css";

.yield-calibration-dialog {
    height: 550px;
    max-height: 550px;
    min-width: 1000px;

    & .calibrated {
        width: 80%;
        & .checkbox-label {
            padding-left: 0.5em;
            padding-right: 0.5em;
        }
    }

    & .calibrated-legend {
        margin-left: 9em;
        display: flex;
        position: absolute;
        bottom: 1em;

        & .calibrated-legend-symbol {
            width: 20px;
            height: 20px;
            margin-right: 0.5em;
        }

        & .calibrated-legend-label {
            line-height: 20px;
        }
    }

    & .no-bucket-header {
        margin: 0;
        padding: 0;
        height: 0;
    }

    & .yield-calibration-select-loads {
        padding-right: 10px;

        & > .bucket-content {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 433px;
        }
        & .select-load-panel {
            min-height: 375px;
            & .bucket {
                margin: 0;
            }
            & .customer-field-name,
            & .equipment-profile-name {
                font-size: 13px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 92%;
                width: 92%;
                margin-left: 42px;
            }
            & .customer-field-name {
                height: 15px !important;
            }

            & .equipment-profile-name {
                font-weight: 700;
                height: auto !important;
                margin-left: 20px;
            }

            & .customer-field-bucket {
                & .checkbox-div {
                    margin-left: 41px;
                }
            }
        }
    }

    & .yield-calibration-title {
        border-bottom: 1px;
        border-bottom-style: solid;
        font-size: 14px;
        display: flex;
        padding-bottom: 5px;
        height: 15px !important;

        & .bucket-symbol {
            display: none;
        }

        & .checkbox-div,
        & span {
            width: 67%;
        }

        & .reset-link {
            font-size: 13px;
            text-align: right;
            width: 34%;
        }
    }

    & .yield-calibration-select-loads {
        min-height: 450px;
        border-right: 1px;
        border-right-style: solid;
        width: 450px;

        & .bucket-content {
            display: block;
            & .bucket.non-collapsible {
                margin-bottom: 5px;
            }
        }

        & .checkbox-div {
            height: 22px;
            padding: 2px;
            border-radius: 3px;
        }
    }

    & .yield-calibration-averages-tables {
        max-height: 200px;
        border-bottom-style: solid;
        border-bottom-width: 1px;
    }

    & .yield-calibration-averages {
        position: absolute;
        top: 50px;
        right: 20px;
        min-width: 500px;
        width: 500px;
        margin: 6px 0;

        & .crop-total-yield-label {
            padding: 5px 0;
        }

        & .simple-table {
            & > div {
                font-size: 13px;
            }
        }
    }
    & .yield-calibration-results-table {
        max-height: 200px;
        font-size: 13px;
    }

    & .yield-calibration-averages-input {
        z-index: 1000;
        margin-top: 0;

        & .radio-div {
            height: 50px;
            margin-top: 1em;

            & .radio-label {
                height: 50px;
                width: 35em;
            }
            & .radio-button-container {
                margin-top: 23px;
            }
        }

        & .input-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            & > * {
                flex-grow: 1;
                width: 20px;
                margin: 3px 2px;
                max-width: 11em;
            }
            & > .select-form-input-container .select-form-input.form-input.text-form-input {
                margin: 0px;
            }
        }

        & .label {
            margin-top: 25px;
            max-width: 11em;
        }

        & .no-grow {
            flex-grow: 0;
            margin-top: 1em;
        }

        & .override-checkbox {
            flex-grow: 0.8;
            margin-top: 1em;
        }

        & .yield-calibration-form-input {
            max-width: 125px;
        }
    }

    & .label-title {
        height: 20px;
        font-size: 15px;
        font-weight: 300;
        cursor: pointer;
        user-select: none;
        margin-left: 10px;
    }

    & .harvest-event-checkbox {
        margin: auto;
        padding-left: 5px;
    }

    & .select-bales-type {
        margin-right: 8px;
    }
}

@each $val in var(--theme-array) {
    .$(val) .yield-calibration-dialog {
        & .calibrated {
            & .checkbox-label {
                background-color: var(--highlight-$(val));
            }
        }

        & .calibrated-legend-symbol {
            background-color: var(--highlight-$(val));
        }

        & .yield-calibration-title {
            border-bottom-color: var(--white-black-toggle-$(val));
        }

        & .yield-calibration-select-loads {
            border-right-color: var(--white-black-toggle-$(val));
        }

        & .yield-calibration-averages-tables {
            border-bottom-color: var(--white-black-toggle-$(val));
        }
    }
}
