@import "../../../../../../../../theme/css-variables/master-variables.css";

.event-sample-soil-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(100% - 60px);
    overflow: hidden;
    padding-top: 5px;

    & .method-selection {
        margin: 0 9px 9px 9px;

        &.tabs {
            & .tabs-labels li.tab-label:not(.left-non-label):not(.right-non-label) {
                padding-top: 12px;
            }

            & .tabs-pane {
                padding: 8px;

                & .manual-method-steps,
                & .grid-method-steps {
                    display: flex;
                    flex-direction: row-reverse;
                }
            }
        }
    }

    & .sample-points {
        height: calc(100% - 30px);
        padding: 0 10px 0 10px;

        & .table-controls {
            display: flex;
            flex-direction: row;
            min-height: 25px;
            max-height: 25px;
            justify-content: space-between;
            line-height: 18px;
            padding-bottom: 5px;

            & .checkbox-div {
                width: initial;
            }
        }
    }

    & .with-results {
        height: calc(100% - 30px);
    }

    & .no-results {
        height: calc(100% - 105px);
    }

    & .panel-modal {
        & .dialog-window,
        & .modal-overlay {
            position: absolute !important;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        & .dialog-container {
            position: fixed !important;
            width: 385px;
            line-height: 17px;
        }
    }
}

.dialog-container.sample-soil-depth-modal {
    width: 350px;

    & .dialog-box-body {
        overflow: visible;
        margin: 0;
        padding: 0;

        & .select-form-input-container {
            width: 110px;
            margin: 5px;
        }

        & .depth-table {
            margin: 20px 40px;

            & .depth-tbl-hdr {
                font-size: 14px;
                font-weight: 500;
                padding-bottom: 5px;
            }

            & .depth-tbl-hdr,
            & .depth-tbl-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                text-align: center;

                & .form-input {
                    margin: 5px;
                    & .input-container,
                    & input {
                        height: 100%;
                        text-align: center;
                        text-indent: 0;
                    }
                }

                & > div {
                    flex-grow: 1;
                    width: 10px;
                }

                & .remove-icon {
                    cursor: pointer;
                }
            }
        }

        & .add-link {
            display: block;
            margin-top: 15px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .rec-event-info {
        & .event-sample-soil-form {
            & .tabs .tabs-labels {
                background-color: var(--base-palette-4-$(val));

                & li.tab-label {
                    background-color: var(--base-palette-2-$(val));
                    &:not(.active) {
                        color: var(--white-black-toggle-$(val));
                    }
                    &.active {
                        background-color: var(--base-palette-1-$(val));
                    }
                }
            }

            & .method-selection .tabs-pane {
                background-color: var(--base-palette-1-$(val));
            }
        }

        & .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid var(--shadow-$(val));
        }
    }
}
