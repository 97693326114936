@import "./../theme/css-variables/master-variables.css";

.svg-container {
    display: inline-block;
}

.default-svg-icon {
    height: 24px;
    width: 24px;
}

.with-masked-container {
    & .loader-container {
        position: fixed;
        top: 0;
        left: 0;
    }
}
.dialog-container.delete-confirm-container {
    & .dialog-box-body.restricted {
        min-height: 65px;
    }

    & .dialog-delete-confirm {
        display: inline;
        overflow: hidden;
        text-align: left;
        min-height: 76px;
        width: 750px;
        padding: 10px;
        line-height: 25px;

        & span {
            font-size: 20px;
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .default-svg-icon {
            fill: var(--white-black-toggle-$(val));

            &.border-only-svg-icon {
                fill: transparent;
                stroke: var(--white-black-toggle-$(val));
            }
            &.inverted-svg-icon {
                fill: var(--white-black-toggle-$(val));
                stroke: var(--black-white-toggle-$(val));
            }
        }

        & .dialog-delete-confirm span {
            color: var(--black-$(val));
            background-color: var(--caution-$(val));
        }
    }
}
