@import "../../../theme/css-variables/master-variables.css";

.color-picker-container {
    & .popover {
        position: absolute;
        z-index: 2;
    }

    & .cover {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }

    & .color-picker-popover-content {
        display: inline-block;
        position: relative;
    }

    & .button-container {
        display: flex;
    }

    & .color-selector-wrap {
        min-width: 37px;
        min-height: 37px;
        border-radius: 4px;
        display: inline-block;
        outline: none;
        overflow: hidden;
        position: relative;

        & div {
            z-index: -1;
        }

        & .color-selector-button {
            min-width: 37px;
            min-height: 37px;
            border-radius: 4px;
            outline: none;
            z-index: 2;
        }
    }

    & .default-svg-icon {
        margin-left: 6px;
        width: 15px;
        height: 35px;
    }

    & .compact-picker,
    & .compact-transparency-picker {
        border-radius: 4px;
        padding: 5px;

        & .flexbox-fix {
            padding-bottom: 0px;

            & div {
                & input {
                    height: 21px !important;
                }
            }
        }
    }

    & .compact-picker {
        & div:first-child {
            & div {
                border-radius: 4px;
            }
        }
    }

    & .compact-transparency-picker {
        & .compact-swatches {
            & div {
                border-radius: 4px;
            }
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .color-picker-container {
            & .color-selector-button {
                border: 1px solid var(--white-$(val));
            }

            & .default-svg-icon {
                fill: var(--white-$(val));
            }

            & .compact-picker,
            & .compact-transparency-picker {
                background-color: var(--base-palette-4-$(val));
                border: 1px solid var(--white-$(val));

                & input {
                    color: var(--white-$(val)) !important;
                }

                & .flexbox-fix div {
                    & input {
                        color: var(--white-$(val));
                    }

                    & span {
                        color: var(--white-$(val)) !important;
                    }
                }
            }

            & .compact-picker {
                & div:first-child div {
                    border: 1px solid var(--white-$(val));
                }
            }

            & .compact-transparency-picker {
                & .compact-swatches div {
                    border: 1px solid var(--white-$(val));
                }
            }
        }

        & .color-picker-container.focus {
            & .color-selector-button {
                border: 1px solid var(--highlight-$(val));
            }

            & .default-svg-icon {
                fill: var(--highlight-$(val));
            }
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) .color-picker-container {
        & .color-selector-button {
            border: 1px solid var(--black-$(val));
        }

        & .compact-picker,
        & .compact-transparency-picker {
            background-color: var(--base-palette-6-$(val));
            border: 1px solid var(--black-$(val));

            & input {
                color: var(--black-$(val)) !important;
            }

            & .flexbox-fix div {
                & input {
                    color: var(--black-$(val));
                }

                & span {
                    color: var(--black-$(val)) !important;
                }
            }
        }

        & .compact-picker {
            & .compact-swatches div {
                border: 1px solid var(--black-$(val));
            }
        }

        & .compact-transparency-picker {
            & .compact-swatches div {
                border: 1px solid var(--white-$(val));
            }
        }
    }
}
