@import "../../../theme/css-variables/master-variables.css";

.action-panel .filter-tabs.tabs {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & .tabs-labels {
        height: 40px;

        & li.tab-label:not(.right-non-label):not(.left-non-label) {
            flex-grow: inherit;
            min-width: 55px;
            width: auto;
            padding: 0 10px;
            font-size: 13px;
            line-height: 38px;
        }
        & .context-menu-container {
            float: right;
        }
    }

    & .tabs-pane {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        & .pane-content {
            flex-grow: 1;

            &.scroll-y {
                overflow-y: auto;
            }

            & .lock-icon {
                height: 35px;
                width: 45px;
                padding-left: 200px;
                padding-top: 50px;
            }

            & .lock-icon-text {
                padding-top: 20px;
                text-align: center;
            }
        }

        & .pane-content-loader {
            position: relative;
        }
    }

    & .warning-icon {
        margin: 8px;
    }
}

@each $val in var(--theme-array) {
    .$(val) .action-panel .filter-tabs {
        & .warning-icon {
            fill: var(--caution-$(val));
        }
    }
}
