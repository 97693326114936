@import "../../../../theme/css-variables/master-variables.css";

.planting-event-container {
    & .planting-event-section-header {
        width: 370px;
        height: 40px;
        line-height: 40px;
    }

    & .transplant-container {
        width: 100%;
    }

    & .transplant-checkbox {
        padding-top: 15px;
        padding-left: 5px;
    }
}

@each $val in var(--theme-array) {
    .$(val) .planting-event-section-header {
        background-color: var(--base-palette-5-$(val));
    }
}
