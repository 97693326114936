@import "../../../theme/css-variables/master-variables.css";

.menu-open {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    & .form-input {
        width: 183px;
    }

    & .dropdown-menu {
        width: 189px;
    }
}

.multi-select-container {
    border-radius: 4px;
    height: 45px;
    margin: 0;
    min-width: 49%;
    margin-right: 8px;

    &:hover {
        cursor: pointer;
    }

    & .multi-select-dropdown {
        width: 100%;
    }

    & .select-form-input-multi {
        padding: 3px;

        & .basic-multi-select {
            z-index: 1;
        }

        & .input-container {
            display: flex;
            flex-direction: row;

            & .expand-contract-arrow {
                margin: 4px 0;
                padding: 2px;
                flex-shrink: 0;
                width: 15px;
                height: 15px;
                border: none;
                cursor: pointer;
                min-width: 11px;
                float: right;

                & .default-svg-icon {
                    width: 15px;
                    height: 15px;
                }
            }

            & .clear-btn-icon {
                margin: 4px 0;
                padding: 2px;
                border: none;
                cursor: pointer;

                & .default-svg-icon {
                    width: 15px;
                    height: 15px;
                }
            }

            &:not(.focus):not(:hover) .clear-btn-icon {
                visibility: hidden;
            }

            & .input-placeholder {
                font-family: "open_sansitalic";
                font-size: 13px;
                font-weight: 300;
                line-height: 22px;
                flex-grow: 1;
                min-width: 20px;
                padding: 2px;
                height: 22px;
                border: none;
                outline: none;
                text-overflow: ellipsis;

                &:not(.required) {
                    text-indent: 5px;
                }
            }

            & .selected-string {
                border: none;
                flex-grow: 1;
                font-size: 13px;
                height: 22px;
                line-height: 17px;
                margin: 5px 5px 0 5px;
                min-width: 20px;
                outline: none;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: calc(100% - 35px);
            }
        }

        & .select-form-sub-header {
            font-weight: bold;
            cursor: default;
            height: 50%;
            display: flex;
            align-items: center;
            padding-top: 5px;
            margin-left: 5px;
        }

        & .input-label-container {
            font-size: 10px;
            line-height: 12px;
            min-height: 7px;
            text-indent: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            min-width: 10%;
            max-width: 100%;
        }

        & .red-star {
            font-size: 15px;
            line-height: 10px;
            padding-right: 5px;
        }

        & .value-selected {
            margin-top: 7px;
            vertical-align: top;
        }
    }

    & .dropdown-menu {
        margin-top: -3px;
        position: fixed;
        z-index: 10001;
    }

    & .select__control {
        &:not(.select__control--is-focused) {
            & .css-mohuvp-dummyInput-DummyInput {
                height: 0;
            }

            & .select__input-container {
                margin: 0;
                padding: 0;
            }
        }

        & .css-mohuvp-dummyInput-DummyInput {
            height: inherit;
        }

        & .select__placeholder {
            font-family: "open_sansitalic";

            & .default-svg-icon {
                height: 14px;
                width: 14px;
            }
        }
    }

    & .select__menu {
        font-size: 13px;
        margin-top: -3px;
        margin-bottom: 0;
        position: relative;
    }

    & .select__menu-list {
        max-height: 220px;
    }

    & .select__menu-notice {
        text-align: left;
    }

    & .select__multi-value {
        font-size: 15px;
        margin-top: 0;
        margin-bottom: 4px;
    }

    & .select__multi-value__label {
        padding-left: 2px;
        padding-right: 0;
    }

    & .select__multi-value__remove {
        padding-left: 1px;
        padding-right: 1px;
    }

    & .select__option {
        &:hover {
            cursor: pointer;
        }
    }

    & .select__value-container--has-value {
        visibility: hidden;
    }

    &:not(:hover) {
        & .select__clear-indicator {
            visibility: hidden;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .multi-select-container {
        background-color: var(--base-palette-3-$(val));
        color: var(--black-$(val));

        & .select-form-input-multi {
            border: 2px solid var(--base-palette-3-$(val));
        }

        & .red-star {
            color: var(--error-$(val));
        }

        & .default-svg-icon {
            fill: var(--black-$(val));
        }

        & .dropdown-menu {
            background-color: var(--base-palette-3-$(val));
            color: var(--black-$(val));
            border: 2px solid var(--highlight-$(val));
            border-radius: 0 0 4px 4px;
            border-top: unset;
            box-shadow: unset;
            border-top: 1px solid var(--base-palette-1-$(val));
        }

        & .multi-select-dropdown {
            height: 45px;
            position: relative;
        }

        & .input-label-container,
        & .selected-string {
            color: var(--black-$(val));
        }

        & .select__control {
            background-color: var(--base-palette-3-$(val));
            border-color: var(--base-palette-3-$(val));
            color: var(--black-$(val));
        }

        & .select__control--is-focused {
            border-color: var(--base-palette-3-$(val));
            box-shadow: unset;
            border-radius: unset;
        }

        & .select__menu {
            background-color: var(--base-palette-3-$(val));
            color: var(--black-$(val));
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: unset;
            box-shadow: unset;
        }

        & .select__menu-notice {
            border-top: var(--base-palette-4-$(val)) 1px solid;
            color: var(--black-$(val));
        }

        & .select__option {
            border-top: var(--base-palette-4-$(val)) 1px solid;

            &:hover {
                background-color: var(--clickable-$(val));
            }
        }

        & .select__option--is-focused {
            background-color: inherit;
        }

        & .select__option--is-selected {
            background-color: var(--base-palette-2-$(val));
        }

        & .select__placeholder {
            color: var(--black-$(val));
        }
    }

    .$(val) .menu-open {
        & .select-form-input-multi {
            border: 2px solid var(--highlight-$(val));
        }
    }
}
