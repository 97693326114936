.import-wizard {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    position: relative;
    height: 10px;

    & .loader-container {
        z-index: 10000;
    }
}
