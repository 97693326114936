.agvance-search-option {
    & .first-line {
        font-size: 13px;
        padding-left: 5px;
        height: 50%;

        & span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    & .second-line {
        padding-left: 5px;
        font-size: 11px;
        font-family: "open_sansitalic";

        & span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}
