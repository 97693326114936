@import "../../theme/css-variables/master-variables.css";

.header {
    min-height: 80px;
    display: flex;
    flex-direction: column;

    & .build-info {
        user-select: none;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        white-space: nowrap;
        font-family: "open_sansregular";
        font-size: 12px;
        font-weight: 500;
    }

    & .logo-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & .svg-container {
            margin-top: -10px;
            cursor: pointer;
        }

        & .logo {
            width: auto;
            height: auto;

            & .system-logo {
                width: 337px;
                height: 76px;
                padding-left: 7%;
                padding-top: 0;
                cursor: pointer;
                & svg,
                & img {
                    height: 100%;
                    width: auto;
                }
            }
        }

        & .nav-user-container {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;

            & .user-menu-container {
                display: block;
                margin: 10px 10px 0 0;
                height: 16px;
                top: 0;
                text-align: right;

                & .user-menu {
                    margin-left: 4px;
                    display: inline-block;
                }
            }

            & .quick-changer-nolink-container {
                text-align: right;
                margin-right: 12px;
                margin-top: 6px;
                font-size: small;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
    & .login-center {
        text-align: center;
    }
}

@each $val in var(--theme-array) {
    .$(val) .header {
        background-color: var(--base-palette-1-$(val));
        color: var(--white-black-toggle-$(val));

        & .quick-changer-nolink {
            color: var(--white-black-toggle-$(val));
        }
        & .quick-changer-nolink:link {
            color: var(--white-black-toggle-$(val));
        }
        & .quick-changer-nolink:visited {
            color: var(--white-black-toggle-$(val));
        }
    }
}
