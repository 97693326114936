@import "../../../theme/css-variables/master-variables.css";
.auto-search {
    & .select-form-input-container .select-form-input-options {
        & .select-form-input-option {
            & .first-line {
                font-size: 13px;
                padding-left: 21px;
                height: 50%;

                & span {
                    margin-left: 0px;
                }
            }

            & .second-line {
                padding-left: 21px;
                font-size: 11px;
                font-family: "open_sansitalic";

                & span {
                    margin-left: 0px;
                }
            }
        }
    }

    & .search-icon {
        width: 13px;
        height: 13px;
        padding-top: 4px;
    }
}

@each $val in var(--theme-array) {
    .$(val) .auto-search {
        & .search-icon {
            fill: var(--black-$(val));
        }
    }
}
