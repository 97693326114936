@import "../../../../theme/css-variables/master-variables.css";

.action-panel .field-module {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & .module-loader {
        width: 446px;
        height: 100%;
    }
}

@each $val in var(--theme-array) {
    .$(val) .action-panel .field-module {
        background-color: var(--base-palette-1-$(val));
    }
}
