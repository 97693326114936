@import "../../../theme/css-variables/master-variables.css";

.quick-summary-bar-container {
    min-height: 48px;
    z-index: 1000;

    & .quick-summary-bar {
        width: 100%;
        height: 30px;
        margin-top: 1px;

        & .quick-summary-bar-title {
            font-family: "open_sansregular";
            font-size: 15px;
            font-weight: 300;
            float: left;
            width: 154px;
            height: 100%;
            line-height: 27px;
            padding-left: 10px;
            padding-top: 1px;
        }

        & .quick-summary-stats-container {
            height: 100%;
            width: 275px;
            float: left;
            padding-top: 2px;

            & .quick-summary-stats {
                float: left;
                width: 19%;
                text-align: center;

                & div:first-child {
                    font-family: "open_sansregular";
                    font-size: 11px;
                    font-weight: 300;
                    height: 14px;
                }

                & div:last-child {
                    font-family: "open_sansitalic";
                    font-size: 11px;
                    font-weight: 300;
                }
            }

            & .quick-summary-stats-acres {
                width: 24%;
            }
        }
    }

    & .copyright-msg {
        font-family: "open_sansitalic";
        font-size: 9px;
        font-weight: 300;
        min-width: 100%;
        text-align: center;
        display: block;

        & .privacy-policy-link {
            padding-left: 3px;
        }
    }

    & .quick-summary-bar.three-totals-displayed {
        & .quick-summary-bar-title {
            width: 177px;
        }

        & .quick-summary-stats-container {
            width: 252px;
        }

        & .quick-summary-stats {
            width: 33%;
        }
    }

    & .quick-summary-bar.four-totals-displayed {
        & .quick-summary-bar-title {
            width: 166px;
        }

        & .quick-summary-stats-container {
            width: 263px;
        }

        & .quick-summary-stats {
            width: 25%;
        }
    }
}

.privacy-policy-body {
    display: flex;
    flex-direction: column;
}

@each $val in var(--theme-array) {
    .$(val) .quick-summary-bar-container {
        background-color: var(--base-palette-1-$(val));

        & .quick-summary-bar {
            background-color: var(--base-palette-1-$(val));
            color: var(--white-black-toggle-$(val));
            border-top: 1px solid var(--base-palette-3-$(val));
        }

        & .copyright-msg {
            color: var(--base-palette-2-$(val));
        }
    }

    & .dialog-container {
        & .privacy-policy-footer {
            border-top: 1px solid var(--shadow-$(val));
        }
    }
}
