@import "../../../../theme/css-variables/master-variables.css";

.event-module,
.layer-module,
.rec-module {
    & .field-accordion-item {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & .has-selected,
        & .has-no-selected {
            min-width: 5px;
            height: 38px;
            margin: 8px 0 8px 8px;
        }

        & .left-column {
            min-width: 25px;
            flex-grow: 1;
            margin: 9px 5px 5px 12px;
            display: flex;
            flex-direction: column;

            & .field-name-and-item-count {
                height: 20px;
                padding-top: 1px;
                padding-bottom: 1px;
                display: flex;
                flex-direction: row;

                & .field-name {
                    font-size: 15px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                & .selected-item-count {
                    margin-left: 10px;
                    padding: 2px 7px;
                    font-family: "open_sansregular";
                    font-size: 13px;
                    font-weight: 300;
                    border-radius: 5px 5px 5px 5px;
                }
            }

            & .cust-farm-size {
                font-size: 12px;
                max-width: calc(100% - 15px);
                padding-top: 5px;
                height: 18px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        & .right-columns {
            width: 100px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            & .rec-event-count {
                margin: 9px 20px 7px 0;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                font-size: 14px;
                width: 32px;

                & .count {
                    font-size: 11px;
                    font-family: "open_sansitalic";
                }
            }
        }

        & .context-menu-container {
            margin-right: 7px;
            padding-top: 7px;
            height: 100%;

            & .context-menu {
                height: calc(100% - 14px);
                width: 15px;
                margin: 0;
                line-height: 25px;
                padding-right: 1px;

                &:not(.expanded) .menu-dot {
                    visibility: hidden;
                }
            }
        }

        &:hover .context-menu-container .context-menu {
            & .menu-dot {
                visibility: visible;
            }
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .event-module,
    .$(val) .layer-module,
    .$(val) .rec-module {
        & .accordion-item {
            &:last-child .field-accordion-item,
            &.has-children .field-accordion-item {
                border-bottom: 1px solid var(--base-palette-3-$(val));
            }
            & .field-accordion-item {
                background-color: var(--base-palette-1-$(val));
                border-top: 1px solid var(--base-palette-3-$(val));

                & .has-selected {
                    background-color: var(--highlight-$(val));
                }

                & .has-no-selected {
                    background-color: var(--base-palette-1-$(val));
                }

                & .left-column .selected-item-count {
                    background-color: var(--attention-$(val));
                    color: var(--white-$(val));
                }

                &:hover .context-menu-container .context-menu {
                    & .menu-dot {
                        color: var(--highlight-$(val));
                    }

                    &:hover {
                        background-color: var(--base-palette-4-$(val));
                    }
                }
            }
        }
    }
}
