@import "../../../../../../../../theme/css-variables/master-variables.css";

.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl {
    left: 0;
    right: 0;
    top: 0;
    height: calc(100% - 30px);
    overflow-x: auto;
    overflow-y: auto;
    position: relative;

    & .sticky {
        position: absolute;
        top: auto;
    }

    & .header {
        min-height: 0;
        :not(.sticky) {
            visibility: hidden;
            z-index: 0;
        }
    }

    & .header,
    & .row {
        display: flex;
        flex-direction: row;
    }

    & .header-cell {
        font-weight: 400;
        padding-top: 16px;
        text-align: center;
        &.sticky-col,
        &.sticky {
            z-index: 9;
        }
    }

    & .body-cell {
        z-index: 0;
        &.sticky-col {
            z-index: 5;
        }
    }

    & .header-cell,
    & .header-sticky-spacer,
    & .body-cell {
        flex-grow: 1;
        height: 49px;
        min-width: 55px;
        box-sizing: border-box;

        &.prod-rating {
            min-width: 135px;
            overflow: visible;
            &.fixed-container {
                z-index: 10;
            }
        }

        & .body-text {
            text-align: center;
            padding-top: 18px;
        }
    }

    & .select-form-input-container {
        margin: 0;
        width: 135px;
        margin-left: auto;
        margin-right: auto;

        & .form-input {
            margin: 2px;
        }

        & .select-form-input-options {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .sample-point-tbl {
        background-color: var(--base-palette-4-$(val));
        & .header-cell {
            background-color: var(--base-palette-1-$(val));
        }
        & .header-cell,
        & .body-cell {
            background-color: var(--base-palette-1-$(val));
            border-bottom: 1px solid var(--base-palette-3-$(val));
            &.selected {
                background-color: var(--highlight-$(val));
            }
        }
        & .sticky-col,
        & .sticky-all {
            background-color: var(--shadow-$(val));
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) .sample-point-tbl .body-cell.selected {
        color: var(--white-$(val));
    }
}
